export default {
  US: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  BR: {
    currency: {
      style: 'currency',
      currency: 'BRL'
    }
  }
}
