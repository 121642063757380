<template>
  <div class="privacy_modal">
    <PKModal :close="close">
      <template v-slot:header>
        <div class="privacy_modal__header">
          {{title}}
        </div>
      </template>
      <template v-slot:body>
        <div class="privacy_modal__body">
          <div class="privacy_modal__body__content">
            <div class="privacy_modal__body__content__text">
              <p>{{text}}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="privacy_modal__footer">
          <i v-if="loading" class="fa fa-spinner fa-spin button--enabled__spinner"></i>
          <template v-else>
            <button v-show="showCancelButton" class="privacy_modal__footer__button privacy_modal__footer__button--cancel" @click="close">{{closeText}}</button>
            <button class="privacy_modal__footer__button privacy_modal__footer__button--confirm" @click="confirm">{{confirmText}}</button>
          </template>
        </div>
      </template>
    </PKModal>
  </div>
</template>

<script>
import PKModal from './PKModal.vue'
export default {
  components: { PKModal },
  name: 'PrivacyModal',
  props: {
    title: String,
    text: String,
    close: Function,
    confirm: Function,
    closeText: String,
    confirmText: String,
    showCancelButton: Boolean,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.privacy_modal {
  &__header {
    width: 450px;
    padding-bottom: 32px;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 18px;
      //border-bottom: 2px solid map-get($colors-custom, 'purple');
      //color: map-get($colors-custom, 'purple');
      font: {
        size: 1.3em;
        weight: 700
      }
      // text-transform: uppercase;
  }

  &__body {
    margin-left: 24px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__content {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &__text {
          font: {
            size: 1.1em;
          }
        }
      }
    }

  &__footer {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;

    &__button {
      width: 40%;
      border-radius: 8px;
      border: none;
      padding: 10px 0px;
        font: {
          weight: 700;
        }

      &--confirm {
        color: #ffffff;
        background-color: map-get($colors-custom, 'blue-dark');
      }

      &--cancel {
        color: map-get($colors-custom, 'blue-dark');
        background-color: transparent;
      }
    }
  }
}
</style>
