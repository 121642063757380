export default {
  description: 'Welcome to your virtual world! PK XD is all about fun: create your avatar, build your house, meet friends, and go on new adventures.',
  passwordReset: {
    titles: {
      default: 'New password',
      success: 'The new password was set successfully!',
      error: 'Something went wrong'
    },
    subtitles: {
      default: 'Please, enter a new password for your PK XD account.',
      success: 'The new password was set successfully!',
      error: 'It looks like some error has occurred. Try again please.'
    },
    placeholder: '(6 to 15 characters)',
    labels: {
      newPassword: 'New Password',
      confirmPassword: 'Confirm password'
    },
    buttons: {
      save: 'Save'
    },
    errors: {
      size: 'Please enter a valid password that’s 6 to 15 characters',
      mismatch: 'These passwords don\'t match. Try again please.'
    }
  },
  passwordForgot: {
    titles: {
      default: 'Forgot your password?',
      success: 'Request received',
      error: 'Something went wrong'
    },
    subtitles: {
      default: 'Enter your e-mail address or your username and we will send you instructions on how to change your password',
      success: 'Please, check your inbox and follow the instructions.',
      error: {
        nicknameNotFound: 'Username not found',
        emailNotFound: 'Email not found',
        noEmailAssociated: `This account doesn't have a registered email`,
        default: 'It looks like some error has occurred. Try again please.'
      }
    },
    buttons: {
      send: 'Send'
    },
    labels: {
      key: 'e-mail or username'
    },
    errors: {
      invalidKey: 'Please, enter a valid e-mail or username.'
    }
  },
  login: {
    username: 'Registered email',
    password: 'Password',
    email: 'Email or username',
    forgotPassword: 'Forgot password',
    backToLogin: 'back to login',
    didNotGetEmail: 'Did not get the email?',
    titles: {
      forgotPassword: 'Forgot password?'
    },
    subtitles: {
      enterPassword: `Enter your e-mail address or your username and we will send you instructions on how to change your password`,
      emailSent: 'An email has been sent. Please check it :)'
    },
    buttons: {
      login: 'Login',
      send: 'Send'
    }
  },
  store: {
    titleLine1: 'Get a great',
    titleLine2: 'deal here!',
    cards: {
      bonus: 'Bonus',
      gems: {
        title80: '80 Gems',
        title210: '210 Gems',
        title450: '450 Gems',
        title970: '970 Gems'
      },
      coins: {
        title5000: '5000 Coins',
        title13000: '13000 Coins',
        title27500: '27500 Coins',
        title60000: '60000 Coins'
      },
      starterBundle: {
        title: 'STARTER BUNDLE',
        subtitle: 'Single purchase',
        text: 'EXCLUSIVE HOUSE',
        rooms: 'Rooms'
      },
      treeBundle: {
        title: 'TREE HOUSE BUNDLE',
        subtitle: 'Single purchase',
        text: 'EXCLUSIVE HOUSE',
        rooms: 'Rooms'
      },
      rocketHouse: {
        title: 'SPACESHIP HOUSE',
        subtitle: 'Single purchase',
        text: 'exclusive house',
        rooms: 'Rooms'
      },
      rocketHouseBundle: {
        title: 'SPACE BUNDLE',
        subtitle: 'Single purchase',
        text: 'exclusive house',
        rooms: 'Rooms',
        offer: 'Best Price'
      }
    }
  },
  friendList: {
    title: 'Friend list',
    noFriendsOnline: 'No friends online',
    online: 'Online',
    offline: 'Offline'
  },
  checkout: {
    back: 'Back',
    title: 'Pay with credit card',
    subtitle: '+ Pay with another card',
    lastDigits: 'Last digits',
    buttons: {
      edit: 'Edit',
      delete: 'Delete',
      pay: 'Pay',
      close: 'Close',
      save: 'SAVE',
      saveMyInfo: 'Save my information for future purchases'
    },
    form: {
      title: 'Personal Data',
      name: 'Name',
      email: 'Email',
      card: 'Card data',
      fullName: 'Full name',
      identityNumber: 'Identity number',
      postalCode: 'Postal code',
      address: 'Address (Street, Avenue, Alley)',
      number: 'Number',
      complement: 'Complement',
      neighborhood: 'Neighborhood',
      city: 'City',
      state: 'State',
      errors: {
        name: 'Invalid name',
        email: 'Invalid email',
        card: 'Invalid card',
        fullName: 'Invalid full name',
        identityNumber: 'Invalid identity number',
        postalCode: 'Invalid postal code'
      }
    },
    modal: {
      edit: {
        title: 'EDIT CREDIT CARD',
        lastDigits: 'with last digits',
        credit: '(Credit)'
      }
    },
    errors: {
      title: 'PURCHASE NOT MADE',
      subtitle: 'Something went wrong, try again.',
      required: 'Required'
    },
    success: {
      title: 'Your Purchase was successful!',
      subtitle: 'Go to PKXD app now and enjoy!',
      buttons: {
        keepShopping: 'Or keep shopping!'
      }
    },
    collect: {
      title: 'Your Purchase was successful!',
      button: 'Collect'
    }
  },
  general: {
    success: 'Success',
    tryAgain: 'Try again'
  },
  navbar: {
    menu: {
      store: 'Store',
      editAvatar: 'Edit Avatar',
      privacy: 'Privacy Center',
      promoCode: 'Redeem PromoCode'
    },
    dropdown: {
      logout: 'Logout'
    },
    alert: {
      text: 'You have requested to revoke your consent; your account will be deleted within 14 days',
      cancel: 'CANCEL'
    }
  },
  inventory: {
    buttons: {
      save: 'SAVE'
    }
  },
  screenshot: {
    share: 'Share to Facebook'
  },

  privacy: {
    info: {
      title: 'Privacy Center',
      text: 'Personal data is data that can be used to identify a person. Our team at PK XD takes the privacy of your personal information very seriously; we comply with all legal requirements and have technical and organizational policies in place that ensure this information is protected.'
    },
    ads: {
      text: 'Receive personalized ads',
      error: 'Oops, looks like something went wrong, please try again'
    },
    faq: {
      title: 'FAQ',
      issues: [
        {
          id: 'treat_personal_data',
          title: 'Does PK XD use your personal data?',
          content: [
            'When you create an account at PK XD, you add your email and nickname to our database; when you play, your access data is saved, and billing information is stored when you make a purchase.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'get_personal_data',
          title: 'What personal data of yours does PK XD have?',
          content: [
            'At PK XD we collect your email address when you create an account and your access data (IP, date, and time) to improve your experience. At any time you can ask us to send you a file with all your personal data that we have collected. You will receive it via email within 15 days.',
            { type: 'action', value: 'Click here to download your personal data.' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'att_data',
          title: 'Do you know how to update your personal data with us?',
          content: [
            'In order to change your e-mail, click on the link below. You will receive an e-mail with instructions on how to proceed.',
            { type: 'action', value: 'Click here to change your email.' },
            'To update your data, click on the link below. You will receive an e-mail with instructions on how to proceed.',
            { type: 'link', value: 'Click here to update your personal data.', href: 'https://pkxd.zendesk.com/hc/pt-br/requests/new?ticket_form_id=360001242211' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'privacy_policy',
          title: 'Do you know with whom your personal data is shared?',
          content: [
            'In order to provide the best possible game, we rely on a network of partners that use some data to: optimize our servers, improve in-game experience, or present important safety information. We explain this in detail in our Privacy Policy.',
            { type: 'link', value: 'Privacy Policy', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'revoke',
          title: 'Can you withdraw your consent for the use of your personal data?',
          content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'action', value: 'Click here to withdraw your consent.' }
          ],
          email_sent_content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.' },
            { type: 'action', value: 'Resend e-mail' }
          ],
          email_confirmed_content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'You have requested to revoke your consent; your account will be deleted within 14 days' },
            { type: 'action', value: 'Cancel' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'delete',
          title: 'Do you want to delete your personal data from our database?',
          content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'action', value: 'Click here to delete your personal data.' }
          ],
          email_sent_content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.' },
            { type: 'action', value: 'Resend e-mail' }
          ],
          email_confirmed_content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'You have requested to revoke your consent; your account will be deleted within 14 days' },
            { type: 'action', value: 'Cancel' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'ads',
          title: 'Personalized Ads',
          content: [
            'In order for PK XD to always be an organization and team dedicated to innovation and improvement, part of game revenue comes from ads that are personalized and relevant to each player. You can choose to not receive these ads and instead receive only ads for the general public.',
            { type: 'ads-toggle' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'terms&privacy',
          title: 'Terms of Use and Privacy Policy',
          content: [
            'Our Terms of Use and Privacy policy are available for you to review at any time. You will receive notice of any changes to how your data is used.',
            { type: 'link', value: 'Terms of Use', href: 'https://policies.playpkxd.com/en/terms/2.0' },
            { type: 'link', value: 'Privacy Policy', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        }
      ]
    },
    modals: {
      closeText: 'CANCEL',
      confirmText: 'OK',
      delete: {
        title: 'Delete personal data',
        warning: 'Are you sure you want to delete your personal data? This action will delete your account, and you will lose all progress and in-game purchases.',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.',
        buttonText: 'DELETE'
      },
      revoke: {
        title: 'Revoke consent',
        warning: 'Are you sure you want to revoke your consent? This action will delete your account and you will lose all progress and in-game purchases.',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.',
        buttonText: 'REVOKE'
      },
      request: {
        title: 'Request personal data',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.'
      },
      error: {
        title: 'Oops',
        text: 'Looks like something went wrong, please try again'
      },
      changeEmail: {
        title: 'Request email change',
        placeholder: 'Your new email'
      },
      captcha: {
        text: 'Click the button below to make sure you are not a robot:',
        label: 'I\'m not a robot'
      }
    },
    confirmationViews: {
      delete: {
        title: 'Data deletion requested',
        text: 'Your data will be deleted within 14 days; this action will permanently delete your account. If you want to revert this action, go to app.playpkxd.com'
      },
      request: {
        title: 'Data requested',
        text: 'Your data has been requested; within 14 days, we will send you an e-mail with the data. If you have any questions, go to app.playpkxd.com'
      },
      changeEmail: {
        title: 'Email change success!',
        text: 'Your email change request was successfully completed. If you have any questions, go to app.playpkxd.com.'
      },
      error: {
        title: 'Oops, it looks like your request has expired',
        text: 'We were unable to complete the operation because your request has expired, you can request the operation again through the privacy center'
      },
      loading: {
        title: 'Processing request',
        text: 'We are processing your request, please wait'
      }
    }
  },
  checkoutMaintenance: {
    title: 'Desktop and website purchases are under maintenance. Please use PK XD on mobile to complete your transaction. Thank you for understanding.'
  },
  promoCode: {
    title: 'redeem your promocode',
    input: {
      placeholder: 'Insert the promocode here'
    },
    success: {
      title: 'success!',
      description: 'the items were added to your account!'
    },
    error: {
      invalid: 'We couldn\'t find this promocode :(',
      limitReached: 'The requested promocode redeem limit was already reached.',
      alreadyRedeemed: 'You have already redeemed this promocode.',
      expired: 'The requested promocode is expired.',
      unknown: 'Something gone wrong with your request.',
      cta: 'Ok'
    },
    cta: 'Redeem'
  }
}
