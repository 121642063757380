import UnityEmitter from './unityEmitter'
import WebEmitter from './webEmitter'

let emitter
if (localStorage.getItem('pkxd_platform') === 'unity') {
  emitter = new UnityEmitter()
} else {
  emitter = new WebEmitter()
}

export default emitter
