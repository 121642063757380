export default {
  description: 'Bem-vindo ao seu mundo virtual! PK XD é só diversão: crie seu avatar, construa sua casa, encontre amigos e viva novas aventuras.',
  passwordReset: {
    titles: {
      default: 'Nova senha',
      success: 'A nova senha foi cadastrada com sucesso!',
      error: 'Algo deu errado'
    },
    subtitles: {
      default: 'Por favor, insira uma nova senha para sua conta do PK XD.',
      success: 'A nova senha foi cadastrada com sucesso!',
      error: 'Parece que ocorreu algum erro. Por favor tente novamente.'
    },
    placeholder: '(de 6 a 15 caracteres)',
    labels: {
      newPassword: 'Nova senha',
      confirmPassword: 'Confirme nova senha'
    },
    buttons: {
      save: 'Salvar'
    },
    errors: {
      size: 'Por favor, insira uma senha válida de 6 a 15 caracteres',
      mismatch: 'As senhas não correspondem. Tente novamente por favor.'
    }
  },
  passwordForgot: {
    titles: {
      default: 'Esqueceu sua senha?',
      success: 'Pedido recebido',
      error: 'Algo deu errado'
    },
    subtitles: {
      default: 'Insira seu endereço de e-mail ou nome de usuário e nós enviaremos instruções para trocar sua senha.',
      success: 'Por favor, cheque a caixa de entrada de seu e-mail e siga as instruções enviadas.',
      error: {
        nicknameNotFound: 'Nome de usuário não encontrado',
        emailNotFound: 'Email não encontrado',
        noEmailAssociated: 'Essa conta não possui um email registrado',
        default: 'Parece que ocorreu algum erro. Por favor tente novamente.'
      }
    },
    buttons: {
      send: 'Enviar'
    },
    labels: {
      key: 'e-mail ou nome de usuário'
    },
    errors: {
      invalidKey: 'Por favor, preencha um e-mail ou nome de usuário válido.'
    }
  },
  login: {
    username: 'E-mail cadastrado',
    password: 'Senha',
    email: 'Email ou nome de usuário',
    forgotPassword: 'Esqueci minha senha',
    backToLogin: 'voltar para o login',
    didNotGetEmail: 'Não recebeu o email?',
    titles: {
      forgotPassword: 'Esqueceu sua senha?'
    },
    subtitles: {
      enterPassword: 'Insira seu email ou nome de usuário e nós iremos te enviar instruções de como trocar sua senha',
      emailSent: 'Um email foi enviado. Por favor, dê uma olhada :)'
    },
    buttons: {
      login: 'Entrar',
      send: 'Enviar'
    }
  },
  store: {
    titleLine1: 'Consiga uma ótima',
    titleLine2: 'oferta aqui!',
    cards: {
      bonus: 'Bônus',
      gems: {
        title80: '80 Gemas',
        title210: '210 Gemas',
        title450: '450 Gemas',
        title970: '970 Gemas'
      },
      coins: {
        title5000: '5000 Moedas',
        title13000: '13000 Moedas',
        title27500: '27500 Moedas',
        title60000: '60000 Moedas'
      },
      starterBundle: {
        title: 'Pacote Inicial',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Cômodos'
      },
      treeBundle: {
        title: 'Pacote Casa na Árvore',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Cômodos'
      },
      rocketHouse: {
        title: 'CASA FOGUETE',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Cômodos'
      },
      rocketHouseBundle: {
        title: 'PACOTE ESPACIAL',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Cômodos',
        offer: 'Melhor Preço'
      }
    }
  },
  friendList: {
    title: 'Lista de amigos',
    noFriendsOnline: 'Nenhum amigo online',
    online: 'Conectado',
    offline: 'Desconectado'
  },
  checkout: {
    back: 'Voltar',
    title: 'Pague com cartão de crédito',
    subtitle: '+ Pague com outro cartão',
    lastDigits: 'Últimos dígitos',
    buttons: {
      edit: 'Editar',
      delete: 'Remover',
      pay: 'Pagar',
      close: 'Fechar',
      save: 'SALVAR',
      saveMyInfo: 'Salvar minhas informações para futuras compras'
    },
    form: {
      title: 'Dados pessoais',
      name: 'Nome',
      email: 'Email',
      card: 'Dados do cartão',
      fullName: 'Nome completo',
      identityNumber: 'CPF',
      postalCode: 'CEP',
      address: 'Endereço (Rua, Avenida, Alameda)',
      number: 'Número',
      complement: 'Complemento',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'Estado',
      errors: {
        name: 'Nome inválido',
        email: 'Email inválido',
        card: 'Cartão inválido',
        fullName: 'Nome inválido',
        identityNumber: 'CPF inválido',
        postalCode: 'CEP inválido'
      }
    },
    modal: {
      edit: {
        title: 'Editar cartão de crédito',
        lastDigits: 'com últimos dígitos',
        credit: '(Crédito)'
      }
    },
    errors: {
      title: 'Compra não realizada',
      subtitle: 'Algo deu errado. Tente de novo.',
      required: 'Obrigatório'
    },
    success: {
      title: 'Sua compra foi bem sucedida!',
      subtitle: 'Acesse o aplicativo PKXD e divirta-se!',
      buttons: {
        keepShopping: 'Ou continue comprando'
      }
    },
    collect: {
      title: 'Sua compra foi realizada com sucesso!',
      button: 'Coletar'
    }
  },
  general: {
    success: 'Sucesso',
    tryAgain: 'Tente novamente'
  },
  navbar: {
    menu: {
      store: 'Loja',
      editAvatar: 'Editar Avatar',
      promoCode: 'Resgate de Promocode',
      privacy: 'Privacidade'
    },
    dropdown: {
      logout: 'Sair'
    },
    alert: {
      text: 'Você solicitou a revogação do consentimento, sua conta será deletada dentro de 14 dias',
      cancel: 'CANCELAR'
    }
  },
  inventory: {
    buttons: {
      save: 'SALVAR'
    }
  },
  screenshot: {
    share: 'Compartilhar no Facebook'
  },

  privacy: {
    info: {
      title: 'Central de privacidade',
      text: 'Dados pessoais são dados que permitem a identificação de uma pessoa. Nós da equipe do PK XD levamos muito a sério a proteção de seus dados pessoais, respeitando todas as exigências legais e aplicando todas as medidas técnicas e organizacionais para garantir a proteção desses dados.'
    },
    ads: {
      text: 'Receber anúncios personalizados',
      error: 'Oops, parece que algo deu errado, por favor tente novamente'
    },
    faq: {
      title: 'FAQ',
      issues: [
        {
          id: 'treat_personal_data',
          title: 'O PK XD trata seus dados pessoais?',
          content: [
            'Ao criar uma conta no PK XD, você fornece seu e-mail e nickname para nossa base de dados; ao jogar, seus dados de acesso ficam gravados e ao realizar uma compra, as informações de cobrança também são armazenadas.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'get_personal_data',
          title: 'Quais dados pessoais o PK XD possui sobre você?',
          content: [
            'Em PK XD coletamos seu e-mail para criação de conta e dados de acesso (IP, data e hora) para melhorarmos a experiência. A qualquer momento você pode pedir para enviarmos um arquivo com todos os seus dados pessoais que coletamos. Você vai receber no seu e-mail em até 15 dias.',
            { type: 'action', value: 'Clique aqui para baixar seus dados pessoais.' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'att_data',
          title: 'Você sabe como atualizar os dados pessoais que temos sobre você?',
          content: [
            'Para alterar seu email, clique no link abaixo. Você então receberá um email com as instruções seguintes.',
            { type: 'action', value: 'Clique aqui para alterar seu email.' },
            'Para atualizar outros dados, clique no link abaixo. Você receberá um e-mail com as instruções seguintes.',
            { type: 'link', value: 'Clique aqui para atualizar seus dados pessoais.', href: 'https://pkxd.zendesk.com/hc/pt-br/requests/new?ticket_form_id=360001242211' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'privacy_policy',
          title: 'Você sabe com quem os seus dados pessoais são compartilhados?',
          content: [
            'Para oferecermos um jogo cada vez melhor, contamos com uma rede de parceiros que utiliza alguns dados para: otimizar nossos servidores, melhorar a experiência dentro do jogo, apresentar anúncios seguros e relevantes. Explicamos tudo na nossa',
            { type: 'link', value: 'Política de Privacidade.', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'revoke',
          title: 'Você pode revogar seu consentimento para o tratamento de dados pessoais?',
          content: [
            'Alguns dados que coletamos são com base em seu consentimento no momento da criação da conta e acesso ao jogo. Você pode revogar este consentimento a qualquer momento. Atenção: ao revogar seu consentimento, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'action', value: 'Clique aqui para revogar seu consentimento.' }
          ],
          email_sent_content: [
            'Alguns dados que coletamos são com base em seu consentimento no momento da criação da conta e acesso ao jogo. Você pode revogar este consentimento a qualquer momento. Atenção: ao revogar seu consentimento, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'warn', value: 'Enviamos um e-mail com um link para confirmar a sua solicitação. Se não encontrar o e-mail, verifique a sua caixa de spam/lixo eletrônico ou clique abaixo para reenviá-lo.' },
            { type: 'action', value: 'Reenviar e-mail' }
          ],
          email_confirmed_content: [
            'Alguns dados que coletamos são com base em seu consentimento no momento da criação da conta e acesso ao jogo. Você pode revogar este consentimento a qualquer momento. Atenção: ao revogar seu consentimento, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'warn', value: 'Voce solicitou a revogação do seu consentimento, sua conta será deletada dentro de 14 dias' },
            { type: 'action', value: 'Cancelar ação' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'delete',
          title: 'Quer excluir seus dados pessoais da nossa base?',
          content: [
            'Você pode solicitar a exclusão de todos os seus dados pessoais de nossa base a qualquer momento. Atenção: ao eliminar seus dados da nossa base, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'action', value: 'Clique aqui para excluir seus dados pessoais' }
          ],
          email_sent_content: [
            'Você pode solicitar a exclusão de todos os seus dados pessoais de nossa base a qualquer momento. Atenção: ao eliminar seus dados da nossa base, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'warn', value: 'Enviamos um e-mail com um link para confirmar a sua solicitação. Se não encontrar o e-mail, verifique a sua caixa de spam/lixo eletrônico ou clique abaixo para reenviá-lo.' },
            { type: 'action', value: 'Reenviar e-mail' }
          ],
          email_confirmed_content: [
            'Você pode solicitar a exclusão de todos os seus dados pessoais de nossa base a qualquer momento. Atenção: ao eliminar seus dados da nossa base, sua conta será excluída permanentemente em 14 dias e você perde todos os itens adquiridos dentro do jogo sem possibilidade de recuperação.',
            { type: 'warn', value: 'Voce solicitou a revogação do seu consentimento, sua conta será deletada dentro de 14 dias' },
            { type: 'action', value: 'Cancelar ação' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'ads',
          title: 'Anúncios Personalizados',
          content: [
            'Para que o PK XD possa sempre ter uma estrutura e uma equipe dedicadas à novidades e melhorias, uma parte da receita do game vem de anúncios personalizados e relevantes para cada jogador. Você pode optar por não receber esses anúncios e receber somente anúncios para o público geral.',
            { type: 'ads-toggle' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'terms&privacy',
          title: 'Termos de Uso e Política de Privacidade',
          content: [
            'Sempre que quiser, nossos Termos de Uso e Políticas de Privacidade estão disponíveis para consulta. Você será informado sempre que houver alteração no tratamento de seus dados.',
            { type: 'link', value: 'Termos de Uso', href: 'https://policies.playpkxd.com/en/terms/2.0' },
            { type: 'link', value: 'Política de Privacidade', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        }
      ]
    },
    modals: {
      closeText: 'CANCELAR',
      confirmText: 'OK',
      delete: {
        title: 'Excluir dados pessoais',
        warning: 'Tem certeza de que deseja excluir seus dados pessoais? Essa ação irá deletar sua conta, e voce perderá todo o progresso e compras feitas dentro do jogo.',
        email: 'Enviamos um email com o link para confirmar a sua solicitação. Se não encontrar o e-mail, verifique a sua caixa de spam ou clique abaixo para reenviá-lo',
        buttonText: 'EXCLUIR'
      },
      revoke: {
        title: 'Revogar consentimeto',
        warning: 'Tem certeza de que deseja revogar o seu consentimento? Essa ação irá deletar sua conta, e voce perderá todo o progresso e compras feitas dentro do jogo.',
        email: 'Enviamos um email com o link para confirmar a sua solicitação. Se não encontrar o e-mail, verifique a sua caixa de spam ou clique abaixo para reenviá-lo',
        buttonText: 'REVOGAR'
      },
      request: {
        title: 'Solicitar dados pessoais',
        email: 'Enviamos um email com o link para confirmar a sua solicitação. Se não encontrar o e-mail, verifique a sua caixa de spam ou clique abaixo para reenviá-lo'
      },
      error: {
        title: 'Ops',
        text: 'Parece que algo deu errado, por favor tente novamente'
      },
      changeEmail: {
        title: 'Solicitar mudança de email',
        placeholder: 'Seu novo email'
      },
      captcha: {
        text: 'Clique no botão abaixo, para ter certeza que você não é um robo:',
        label: 'Não sou um robo'
      }
    },
    confirmationViews: {
      delete: {
        title: 'Exclusão de dados solicitada',
        text: 'Seus dados serão excluidos dentro de 14 dias, essa ação irá deletear sua conta permanentemente, caso queira reverter essa ação acesse app.playpkxd.com'
      },
      request: {
        title: 'Dados solicitados',
        text: 'Seus dados foram solicitados, dentro de 14 dias enviaremos um e-mal com os dados. Caso tenha alguma dúvida acesse app.playpkxd.com'
      },
      changeEmail: {
        title: 'Troca de email efetuada',
        text: 'Sua troca de email foi efetuada com sucesso.',
        placeholder: 'Your new email'
      },
      error: {
        title: 'Ops, parece que sua solicitação expirou',
        text: 'Não conseguimos concluir a operação porque sua solicitação expirou, você pode solicitar a operação novamente pela central de privacidade'
      },
      loading: {
        title: 'Processando solicitação',
        text: 'Estamos processando sua solicitação, por favor aguarde'
      }
    }
  },
  checkoutMaintenance: {
    title: 'As compras na versão desktop e via website estão em manutenção. Por favor, utilize o PK XD no celular para completar a sua transação. Agradecemos a compreensão.'
  },
  promoCode: {
    title: 'resgate seu promocode',
    input: {
      placeholder: 'Insira o Promo Code aqui'
    },
    success: {
      title: 'sucesso!',
      description: 'os itens foram adicionados à sua conta!'
    },
    error: {
      invalid: 'Não conseguimos encontrar este promocode :(',
      limitReached: 'O promocode requisitado já atingiu seu limite de resgates.',
      alreadyRedeemed: 'Você já resgatou esse promocode.',
      expired: 'O promocode requisitado já está expirado.',
      unknown: 'Algo deu errado com sua requisição.',
      cta: 'Ok'
    },
    cta: 'Resgatar'
  }
}
