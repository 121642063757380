import { uuid } from 'vue-uuid'
import { dispatchToUnity, IAP_CHECKOUT_EVENT, IAP_REQUEST_EVENT, IAP_COMPLETED_EVENT } from '../../../store/unityDispatcher'

export default class UnityEmitter {
  constructor() {
    this.requestId = null
  }

  signIn(success = true, failureReason = null) {}

  signOut() {}

  screen(name) { }

  iapCheckout(params) {
    this.requestId = uuid.v4()
    dispatchToUnity(IAP_CHECKOUT_EVENT, {
      requestId: this.requestId,
      ...params
    })
  }

  iapRequest(params) {
    dispatchToUnity(IAP_REQUEST_EVENT, {
      requestId: this.requestId,
      ...params
    })
  }

  iapCompleted(params) {
    dispatchToUnity(IAP_COMPLETED_EVENT, {
      requestId: this.requestId,
      ...params
    })
    this.requestId = null
  }
}
