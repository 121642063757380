<template>
  <div class="checkout-input-text">
    <div class="checkout-input-text__label">
      <label class="form__label form__label--checkout"
      :class="{'form__label--error': shouldValidate && !isValid}"
      v-t="labelName"/>
    </div>
    <the-mask v-if="mask"
      class="form__input form__input--checkout"
      :class="{'form__input--error form__input--error--checkout': shouldValidate && !isValid}"
      :value="value"
      :placeholder="$t(placeholder)"
      :type="type"
      :autofocus="autofocus"
      @input="sendEventToParent($event)"
      @blur.native="updateValidation"
      :mask="mask">
    </the-mask>
    <input v-else
      class="form__input form__input--checkout"
      :class="{'form__input--error form__input--error--checkout': shouldValidate && !isValid}"
      :value="value"
      :placeholder="$t(placeholder)"
      :type="type"
      :autofocus="autofocus"
      @input="sendEventToParent($event)"
      @blur="updateValidation"
    />
    <div class="form__validation-message form__validation-message--error form__validation-message--error--checkout"
      :style="{'visibility': shouldValidate && !isValid ? 'visible' : 'hidden'}"
      v-t="currValue === '' ? 'checkout.errors.required' : errorText" />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  name: 'checkoutInputText',
  components: {
    TheMask
  },
  props: {
    value: String,
    labelName: String,
    placeholder: String,
    type: {
      Type: String,
      default: 'text'
    },
    autofocus: Boolean,
    errorText: String,
    shouldValidate: {
      Type: Boolean,
      default: true
    },
    parentValidation: {
      Type: Boolean,
      default: null
    },
    mask: String
  },
  data() {
    return {
      isValid: true,
      currValue: this.value
    }
  },

  methods: {
    updateValidation() {
      if (this.shouldValidate) {
        if (this.parentValidation !== null) {
          this.isValid = this.parentValidation
        } else {
          this.isValid = this.currValue !== ''
        }
        this.$emit('validated')
      }
    },
    sendEventToParent(event) {
      if (this.mask) {
        this.currValue = event
      } else {
        this.currValue = event.target.value
      }
      this.$emit('input', this.currValue)
    }
  },

  watch: {
    currValue: function() {
      if (!this.isValid) {
        this.updateValidation()
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';

.checkout-input-text {
  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .form__input {
    margin-bottom: 5px;

    &::placeholder {
      color: map-get($colors-util, 'muted');
      -webkit-text-fill-color: map-get($colors-util, 'muted');
      font: {
        size: 0.9rem;
        family: $font-regular;
        weight: bold;
      }
    }

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
