<template>
  <div class="confirmation-card">
    <main>
      <img src="@/assets/privacy.png" class="banner" />
      <div class="text_area">
        <div class="text_area__item">
          <h1 class="text_area__title">{{title}}</h1>
        </div>
        <div class="text_area__item">
          <p class="text_area__text">{{text}}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationEmailCard',
  props: {
    title: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.confirmation-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
}

.banner {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.text_area {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1rem;

  &__item {
    margin-bottom: 1rem;
  }
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    color: #494262;
  }
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 24px;
    color: #494262;
  }
}
</style>
