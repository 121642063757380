<template>
  <div class="checkout-form" v-if="paymentProfiles.length > 0">
    <div class="checkout-form__header" v-t="'checkout.title'" />
    <div class="card-row" v-for="(profile, index) in paymentProfiles" :key="index">
      <div class="card-row__item card-row__item--radio">
        <input :id="index" type="radio" :value="index" v-model="selectedCard" name="cards">
      </div>
      <div class="card-row__item">
        <label :for="index"><img :src="cardBrandIcons[profile.cardBrand]" class="card-row__item--brand"></label>
      </div>
      <div class="card-row__item">
        <label :for="index"> {{ $t('checkout.lastDigits') }} {{ profile.cardSuffix }}</label>
      </div>
      <div class="card-row__item card-row__item--edit" @click="editCard(index)" v-if="location.country === 'BR'">
        <span v-t="'checkout.buttons.edit'" />
        <img src="@/assets/icon-edit.svg" class="card-row__item--edit__icon">
      </div>
      <div class="card-row__item card-row__item--edit" @click="deleteCard(index)">
        <span v-t="'checkout.buttons.delete'" />
        <img src="@/assets/icon-delete.svg" class="card-row__item--edit__icon card-row__item--edit__icon--delete">
      </div>
    </div>
    <div class="card-row card-row__footer">
      <div class="card-row card-row__footer__item" @click="$emit('open-form')" v-t="'checkout.subtitle'" />
    </div>
    <CheckoutModalEditCard
      :card="paymentProfiles[cardToEdit]"
      :billingPlatformId="billingPlatformId"
      :close="closeEditModal"
      v-show="isEditingCard" />
    <PurchaseButton :stripe="stripe" :country="location.country" :paymentProfile="paymentProfiles[cardToEdit]" :disabled="!isFormValid" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CheckoutModalEditCard from '@/components/CheckoutModalEditCard.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'
import cardBrandIconList from '@/utils/cardBrandIconList.js'

export default {
  name: 'checkoutSelectCard',
  components: {
    CheckoutModalEditCard,
    PurchaseButton
  },
  props: {
    location: Object
  },
  data() {
    return {
      selectedCard: -1,
      cardToEdit: 0,
      isEditingCard: false,
      cardBrandIcons: cardBrandIconList
    }
  },
  computed: {
    ...mapState({
      paymentProfiles: store => store.purchase.paymentProfiles,
      stripe: store => store.stripe.stripe,
      billingPlatformId: store => store.geo.billingPlatformId
    }),
    isFormValid() {
      return this.selectedCard !== -1
    }
  },
  methods: {
    ...mapActions({
      deletePaymentProfile: 'purchase/deletePaymentProfile'
    }),
    editCard(index) {
      this.cardToEdit = index
      this.isEditingCard = true
    },
    deleteCard(index) {
      this.deletePaymentProfile({
        billingPlatformId: this.billingPlatformId,
        paymentProfileId: this.paymentProfiles[index].key.id
      })
      this.paymentProfiles.splice(index, 1)
    },
    closeEditModal() {
      this.isEditingCard = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.card-row {
  height: 40px;
  margin-bottom: 10px;
  font-size: .75rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__item {
    margin-left: 16px;
    color: map-get($colors-custom, 'purple');
    font: {
      size: 1.5em;
      weight: 700;
    }

    &--brand {
      width: 60px;
      height: auto;
      display: block;
    }

    &--edit {
      margin-left: 22px;
      color: map-get($colors-custom, 'blue-dark');

      &:hover {
        cursor: pointer;
      }

      > span {
        display: none;
      }

      &__icon {
        height: .8em;
        margin-left: 3px;
        transform: translateY(2px);

        &--delete {
          transform: translateY(1px);
        }
      }
    }

    &--radio {
      margin-left: 0;
      width: 1em;
      height: 1em;
    }

    input[type='radio'] {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    margin-top: 10px;
    margin-bottom: 78px;
    color: map-get($colors-custom, 'blue-dark');
    font: {
      size: 2em;
      weight: 700;
    }

    &__item {
      &:hover {
        cursor: pointer;
      }
    }
  }

  @include breakpoint('small') {
    &__item {
      &--edit {
        > span {
          display: none;
        }
      }
    }
  }

  @include breakpoint('medium') {
    &__item {
      margin-left: 24px;

      &--edit {
        margin-left: 45px;
      }
    }
  }

  @include breakpoint('extra-large') {
    font-size: 1rem;
  }
}
</style>
