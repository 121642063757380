<template>
  <header class="header" :class="{'header--hide': isFromUnity}" >
    <div class="header__main">
      <div class="header__main__container">
        <img src="@/assets/logo.svg" class="header__logo">
        <div
          v-for="item in menuItems"
          :key="item.text"
          class="menu-item"
          :class="{'menu-item--active':
          item == menuItems[activeMenuItem]}"
        >
          <router-link :to="{ 'name': item.route.name}">
            <p v-t="item.text" />
          </router-link>
        </div>
      </div>
    </div>

    <div class="header__tray">
      <div class="header__tray__container">
        <UserProfile />
        <div class="focus-reset" @focusout="isDropdownActive = false" tabindex="0">
          <IconSettings class="icon-settings" :class="{'icon-settings--active': isDropdownActive}" @click="isDropdownActive = !isDropdownActive"/>
          <div v-show="isDropdownActive" class="dropdown-container">
            <Dropdown :items="dropdownItems" @click="dispacthAction"/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { LOGIN_ROUTE, PRIVACY_ROUTE, PROMO_CODE_ROUTE } from '@/router/router-types'
import UserProfile from '@/components/UserProfile.vue'
import Dropdown from '@/components/Dropdown.vue'
import eventEmitter from '@/faster/events/emitter'

export default {
  name: 'Navbar',
  components: {
    UserProfile,
    Dropdown
  },
  data() {
    return {
      menuItems: [
        {
          text: 'navbar.menu.promoCode',
          route: PROMO_CODE_ROUTE
        },
        {
          text: 'navbar.menu.privacy',
          route: PRIVACY_ROUTE
        }
      ],
      dropdownItems: [
        {
          text: 'navbar.dropdown.logout',
          icon: 'IconLogout',
          action: this.logout,
          fill: false,
          stroke: true
        }
      ],
      isDropdownActive: false
    }
  },
  computed: {
    ...mapGetters({ 'isFromUnity': 'platform/isFromUnity' }),
    activeMenuItem() {
      return this.menuItems.findIndex(item => this.$route.name === item.route.name)
    }
  },
  methods: {
    ...mapActions({
      signOut: 'authentication/signOut'
    }),
    logout() {
      this.signOut()
      eventEmitter.signOut()
      this.$router.push(LOGIN_ROUTE)
    },
    dispacthAction(event, index) {
      this.dropdownItems[index].action()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.header {
  &__logo {
    height: 40px;
    padding-right: 40px;

    @media (max-width: 420px) {
      height: 20px; // Adjust this value as needed for your design
      padding-right: 20px; // Adjust this value as needed for your design
    }
  }

  &--hide {
    visibility: hidden;
  }

  .focus-reset {
    position: relative;

    &:focus {
      outline: none;
    }
  }

  .icon-settings {
    transform: scale(0.8);
    cursor: pointer;

    &--active, &:hover {
      path {
        fill: map-get($colors-custom, 'purple-dark');
      }
    }
  }

  .menu-item {
  display: block;
  color: map-get($colors-custom, 'purple-lightest');
  font: {
    weight: 700;
    size: 1rem;
  }
  padding-right: 40px;
  text-transform: uppercase;

  &--active {
    color: map-get($colors-custom, 'purple-dark');
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

  .dropdown-container {
    position: absolute;
    top: 100%;
    right: 0px;
  }

  @include media(475px, 'min-width') {
    &__logo {
      height: 50px;
    }

    .menu-item {
      display: block;
    }
  }

  @include breakpoint('medium') {
    &__logo {
      height: 56px;
    }

    .menu-item {
      display: block;
      font-size: 1.2rem;
    }
  }

  @include breakpoint('large') {
    &__logo {
      padding-right: 60px
    }
  }
}
</style>
