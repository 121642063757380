export default {
  appVersion: '1.0.14',
  applicationId: 'playkids-xd-web-homolog',
  applicationKey: 'cdf27c82-90fb-4250-b43d-9c269fb25d16',
  applicationSecret: 'IIdiQ1qJjq69ae5TrjbFtiIo',
  apiHost: 'http://localhost:9980',
  privacyApiHost: 'http://localhost:9982',
  productId: 'playkids-xd',
  environment: 'TEST',
  authenticator: 'faster-account-authenticator',
  stripePublicKey: {
    br: 'pk_test_51KiHjHDhkIvAUuxSQwprNiyDmTUeiGy7dTjagbY8z7Ge2SpNPCG7x7YqCiyHZPEmHDMLjVJVAdHC3TLmlicUPfGj00ssZcvPXx',
    us: 'pk_test_51KaozWGPrkp9TXNgxidqDHlRgkCmQ248xRg0hfQgwmqE5thcv5ggH0HnltNgjTIRQm596bSgrKVrUsykHzqUlUL700AqmAOYdc'
  },
  billingPlatform: {
    br: '89668280-96b5-4eef-b0e4-4ebb302a84d8',
    us: '288211e7-1edf-401a-a944-a8d3b03624ab'
  },
  facebookAppId: '2733353720261129',
  sitekey: '6LfYsKwaAAAAAAHbSYb97Gy65vYO470058Y9i6Y7'
}
