<template>
  <div>
    <CheckoutButton
      :name="$t('checkout.buttons.pay') + ' ' + $n(getPrice(sku.skuId), 'currency', country)"
      :click="purchaseSku"
      :disabled="disabled"
      :loading="loading" />
    <CheckoutModalFailed :close="closeFailModal" v-show="purchaseFailed" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { CHECKOUT_SUCCESS_ROUTE, CHECKOUT_COLLECT_ROUTE } from '@/router/router-types'
import CheckoutButton from '@/components/CheckoutButton.vue'
import CheckoutModalFailed from '@/components/CheckoutModalFailed.vue'
import eventEmitter from '@/faster/events/emitter'

export default {
  name: 'purchaseButton',
  components: {
    CheckoutButton,
    CheckoutModalFailed
  },
  props: {
    country: String,
    disabled: Boolean,
    saveCard: {
      Type: Boolean,
      default: false
    },
    stripe: Object,
    email: String,
    personalData: Object,
    paymentProfile: Object
  },
  data() {
    return {
      sku: {},
      purchaseFailed: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      createdPaymentProfile: store => store.purchase.paymentProfile,
      purchase: store => store.purchase.purchase,
      userSession: store => store.authentication.userSession,
      billingPlatformId: store => store.geo.billingPlatformId
    }),
    ...mapGetters({
      getPrice: 'sku/getPrice',
      retrieveSku: 'sku/retrieveSku',
      isFromUnity: 'platform/isFromUnity'
    })
  },
  methods: {
    ...mapGetters({
      getUserSessionToken: 'authentication/getUserSessionToken',
      getCurrency: 'geo/getCurrency'
    }),
    ...mapActions({
      purchaseItem: 'purchase/purchase',
      createPaymentProfile: 'purchase/createPaymentProfile',
      confirm: 'purchase/confirm',
      fetchProfile: 'userProfile/fetchUserProfile'
    }),
    closeFailModal() {
      this.purchaseFailed = false
    },
    async purchaseSku() {
      let iapParams = {
        skuName: this.$route.query.skuId,
        value: this.getPrice(this.$route.query.skuId),
        currency: this.getCurrency(),
        billingPlatform: 'Stripe'
      }

      try {
        this.loading = true

        eventEmitter.iapRequest(iapParams)

        let paymentMethod
        let paymentMethodId

        if (this.paymentProfile) {
          paymentMethodId = this.paymentProfile.paymentMethod
        } else {
          paymentMethod = await this.createPaymentMethod()
          paymentMethodId = paymentMethod.id
        }

        const card = this.saveCard ? this.getCard(paymentMethod) : null
        await this.createPaymentProfile({
          billingPlatformId: this.billingPlatformId,
          email: this.email,
          card: card,
          personalData: this.country === 'BR' ? this.personalData : null
        })

        await this.purchaseItem({
          paymentProfileId: this.createdPaymentProfile.key.id,
          skuId: this.$route.query.skuId,
          quantity: 1,
          paymentMethodId: paymentMethodId,
          userId: this.userSession.userId
        })

        if (this.purchase.clientSecret != null) {
          const responseAction = await this.stripe.instance.handleCardAction(this.purchase.clientSecret)
          if (responseAction.error) {
            this.purchaseFailed = true
          } else {
            await this.confirm({
              purchaseId: this.purchase.purchase.key.id,
              paymentIntentId: this.purchase.paymentIntentId
            })
            this.goToSuccessPage()
          }
          this.loading = false
        } else {
          this.goToSuccessPage()
        }

        eventEmitter.iapCompleted({
          ...iapParams,
          success: true,
          failureReason: ''
        })
      } catch (e) {
        eventEmitter.iapCompleted({
          ...iapParams,
          success: false,
          failureReason: e.message
        })

        this.loading = false
        this.purchaseFailed = true
      }
    },
    async createPaymentMethod() {
      const response = await this.stripe.instance.createPaymentMethod({
        type: 'card',
        card: this.stripe.cardNumber
      })
      return response.paymentMethod
    },
    getCard(paymentMethod) {
      return {
        suffix: paymentMethod.card.last4,
        brand: paymentMethod.card.brand,
        paymentMethod: paymentMethod.id
      }
    },
    goToSuccessPage() {
      this.fetchProfile()
      this.loading = false
      let path

      if (this.isFromUnity) {
        path = CHECKOUT_COLLECT_ROUTE.path
      } else {
        path = CHECKOUT_SUCCESS_ROUTE.path
      }

      this.$router.push(`${path}?skuId=${this.$route.query.skuId}&type=${this.$route.query.type}`)
    }
  },
  beforeMount() {
    this.sku = this.retrieveSku(this.$route.query.skuId, this.$route.query.type)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.button {
  outline: 0;
  mix-blend-mode: normal;
  width: 100%;
  max-width: 470px;
  height: 60px;

  display: block;
  margin: auto;

  border-radius: 16px;
  font: {
    family: $font-regular;
    weight: bold;
    size: 1.4rem;
  }
  text: {
    align: center;
  }

  &--enabled {
    background: map-get($colors-custom, 'purple-dark');
    border: 3.7px solid map-get($colors-custom, 'blue-light');
    box-shadow: 0 6px 0 map-get($colors-custom, 'blue-light');
    color: map-get($colors-custom, 'blue-light');

    &--pink {
      border: 3.7px solid map-get($colors-custom, 'pink');
      box-shadow: 0 6px 0 map-get($colors-custom, 'pink');
      color: map-get($colors-custom, 'pink');
    }

    &:hover {
      background-color: map-get($colors-custom, 'purple-darker');
    }

    &__spinner {
      font-size: 2rem;
    }
  }

  &--disabled {
    background: map-get($colors-util, 'light');
    border: 5px solid map-get($colors-custom, 'purple-lighter');
    color: map-get($colors-custom, 'purple-lighter');
    cursor: not-allowed;
  }

  @include breakpoint('small') {
    height: 70px;
    font-size: 1.75rem;
  }
}
</style>
