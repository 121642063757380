<template>
  <div class="checkout-form">
    <div class="checkout-form">
      <div class="checkout-form__header" v-t="'checkout.title'" />
      <div class="checkout-form__row">
        <CheckoutInputText class="checkout-form__row__item"
          v-model="fields.cardDetails.cardName"
          :labelName="'checkout.form.name'"
          :errorText="'checkout.form.errors.name'"
          :parentValidation="isCardNameValid"/>
        <CheckoutInputText class="checkout-form__row__item"
          v-model="fields.cardDetails.email"
          :labelName="'checkout.form.email'"
          :errorText="'checkout.form.errors.email'"
          :parentValidation="isEmailValid"/>
      </div>
      <div class="checkout-form__row checkout-form__row--footer">
        <div class="checkout-form__row__item checkout-form__row__item--card-container">
          <label class="form__label form__label--checkout" v-t="'checkout.form.card'" />
          <div id="card-number"
            class="form__input form__input--checkout checkout-form__row__item--card-container__number"
            :class="{'form__input--error form__input--error--checkout': isCardInvalid}"
            />
          <div class="checkout-form__row__item--card-container__row">
            <div id="card-expiry"
              class="form__input form__input--checkout checkout-form__row__item--card-container__row__expiry"
              :class="{'form__input--error form__input--error--checkout': isCardInvalid}"
              />
            <div id="card-cvc"
              class="form__input form__input--checkout checkout-form checkout-form__row__item--card-container__row__cvc"
              :class="{'form__input--error form__input--error--checkout': isCardInvalid}"
              />
          </div>
          <div class="form__validation-message form__validation-message--error form__validation-message--error--checkout"
            :style="{'visibility': isCardInvalid ? 'visible' : 'hidden'}"
            v-t="'checkout.form.errors.card'" />
        </div>
        <div class="checkout-form__row__item">
          <div v-if="location.country !== 'BR'">
            <label class="form__label form__label--checkout" v-t="'checkout.form.postalCode'" />
            <div id="card-zipcode"
              class="form__input form__input--checkout"
              :class="{'form__input--error form__input--error--checkout': isCardInvalid}"
              />
          </div>
        </div>
      </div>
    </div>
    <CheckoutFormPersonal
      v-if="location.country === 'BR'"
      v-bind.sync="fields.personalDetails"
      ref="personalDataForm"
    />
    <div class="save-card">
      <input id="shouldSaveCard" type="checkbox" v-model="fields.shouldSaveCard">
      <label for="shouldSaveCard">{{ $t('checkout.buttons.saveMyInfo') }}</label>
    </div>
    <PurchaseButton :stripe="stripe"
      :country="location.country"
      :personalData="getPersonalData"
      :email="fields.cardDetails.email"
      :saveCard="fields.shouldSaveCard"
      :disabled="!isFormValid" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CheckoutInputText from '@/components/CheckoutInputText.vue'
import CheckoutFormPersonal from '@/components/CheckoutFormPersonal.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'

const noNumberRegex = /^[a-zA-Z\s]+$/
const emailRegex = /\S+@\S+\.\S+/

export default {
  name: 'checkoutForm',
  props: {
    country: String
  },
  components: {
    CheckoutInputText,
    CheckoutFormPersonal,
    PurchaseButton
  },
  data() {
    return {
      fields: {
        cardDetails: {
          cardName: '',
          email: '',
          country: ''
        },
        personalDetails: {
          fullName: '',
          identityNumber: '',
          zipCode: '',
          street: '',
          addressNumber: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      isFormValid: false
    }
  },
  computed: {
    ...mapState({ isCardInvalid: store => store.stripe.isCardInvalid }),
    ...mapState({ isCardCompleted: store => store.stripe.isCardCompleted }),
    ...mapState({ stripe: store => store.stripe.stripe }),
    ...mapState({ location: store => store.geo.location }),
    isCardNameValid() {
      return noNumberRegex.test(this.fields.cardDetails.cardName)
    },
    isEmailValid() {
      return emailRegex.test(this.fields.cardDetails.email)
    },
    isCardDetailsValid() {
      return this.isCardNameValid && this.isEmailValid && this.isCardCompleted
    },
    getPersonalData() {
      return {
        name: this.fields.personalDetails.fullName,
        identityNumber: this.fields.personalDetails.identityNumber,
        identityType: 'CPF',
        address: {
          zipCode: this.fields.personalDetails.zipCode,
          street: this.fields.personalDetails.street,
          number: this.fields.personalDetails.addressNumber,
          complement: this.fields.personalDetails.complement,
          neighborhood: this.fields.personalDetails.neighborhood,
          city: this.fields.personalDetails.city,
          state: this.fields.personalDetails.state
        }
      }
    }
  },
  methods: {
    ...mapActions({
      retrieveLocation: 'geo/retrieveLocation',
      startStripe: 'stripe/startStripeAndMount',
      resetStripeValidation: 'stripe/resetValidation'
    }),
    validateForms() {
      if (this.location.country === 'BR') {
        this.isFormValid = this.isCardDetailsValid && this.$refs.personalDataForm.isFormValid
      } else {
        this.isFormValid = this.isCardDetailsValid
      }
    }
  },
  async beforeMount() {
    if (this.location == null) {
      await this.retrieveLocation()
    }
    this.startStripe(this.location)
  },
  mounted() {
    this.$watch(
      '$refs.personalDataForm.isFormValid',
      (newValue, oldValue) => {
        this.validateForms()
      }
    )
    this.$watch(
      'isCardDetailsValid',
      (newValue, oldValue) => {
        this.validateForms()
      }
    )
  },
  beforeDestroy() {
    this.resetStripeValidation()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.save-card {
  margin: 30px auto;
  color: map-get($colors-custom, 'purple');
  font: {
    size: 1.5rem;
    weight: 700;
  }
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    margin-right: 7px;
  }
}
</style>
