import account from '@/faster/account'
import config from '@/config'
import client from '@/store/modules/clients/unauthenticatedHttpClient'

const actions = new function() {
  this.forgot = async (context, { key }) => {
    const response = await client.post('/v1/password', { applicationId: config.applicationId, key })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.change = async (context, { actionToken, newPassword }) => {
    try {
      await account.email.changePassword(actionToken, newPassword)
    } catch (err) {
      throw new Error(err.message)
    }
  }
}()

export default {
  namespaced: true,
  actions
}
