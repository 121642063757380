<template>
  <div>
    <svg width="100%" height="521" viewBox="0 0 496 521" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f)">
        <path d="M248 35L269.057 195.931L354.5 90.605L305.529 216.879L432.463 168.163L326.587 253.161L461 274.108L326.587 295.056L432.463 380.054L305.529 331.338L354.5 457.612L269.057 352.286L248 486L226.943 352.286L141.5 457.612L190.471 331.338L63.5366 380.054L169.413 295.056L35 274.108L169.413 253.161L63.5366 168.163L190.471 216.879L141.5 90.605L226.943 195.931L248 35Z" fill="#FFCFE7" fill-opacity="0.7"/>
      </g>
      <defs>
        <filter id="filter0_f" x="0.146736" y="0.146736" width="495.707" height="520.707" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="17.4266" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'checkoutSuccessStar'
}
</script>
