<template>
  <div>
    <div class="ads-consent-container">
      <p v-t="'privacy.ads.text'"></p>
      <i v-if="loading" class="fa fa-spinner fa-spin"></i>
      <toggle-button
        v-else
        :value="adsConsentState"
        color="#0082eb"
        :sync="true"
        @change="updateAdsConsent"
      />
    </div>
    <p v-if="error" v-t="'privacy.ads.error'" class="ads-consent-error"></p>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PersonalizedAdsToggle',
  components: { ToggleButton },
  data: () => ({
    loading: false,
    error: false
  }),
  methods: {
    ...mapActions({
      updateAdsConsentRequest: 'privacy/updateAdsConsent'
    }),

    async updateAdsConsent({ value }) {
      this.loading = true
      try {
        await this.updateAdsConsentRequest(value)
      } catch (err) {
        this.error = true
        setTimeout(() => {
          this.error = false
        }, 6000)
      }
      this.loading = false
    }
  },
  computed: {
    ...mapState('privacy', { adsConsentState: store => store.adsConsentState })
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.ads-consent-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: map-get($colors-util, 'lightest');
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 0 4px map-get($colors-util, 'dark');
}

.ads-consent-error {
  margin-top: 0.5rem;
  color: map-get($colors-custom, 'red-light');
}
</style>
