import gql from 'graphql-tag'
import { apolloClient } from '../apollo'

const state = {}

const getters = {}

const redeemPromoCode = gql`mutation ($promoCode: String!) {
  redeemPromoCode (promoCode: $promoCode) {
    status,
    failureReason
  }
}`

const actions = {
  async redeem(_, promoCode) {
    return apolloClient.mutate({ mutation: redeemPromoCode, variables: { promoCode } }).then(it => it.data.redeemPromoCode)
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
