<template>
  <div class="checkout layout-lite" v-if="!!location && loaded">
    <main class="layout-lite__body">
      <div>
        <pkbutton
          :click="close"
          class="checkout__close-button"
          v-show="isFromUnity">
            <img src="@/assets/store/close-icon.svg" class="checkout__close-button__img">
        </pkbutton>
      </div>
      <div
        class="layout-lite__body__container checkout__body"
        :class="{'checkout__body--colapse': isBundle}"
      >
        <CheckoutItem :name="sku.name" :price="getPrice(sku.skuId)" :country="location.country" :isBundle="isBundle" v-show="!(type =='hidden')">
          <component :is="sku.component" v-bind="sku" />
        </CheckoutItem>
      </div>
      <div class="checkout__container">
        <CheckoutContainer :location="location" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StoreCardBundleStarter from '@/components/StoreCardBundleStarter.vue'
import StoreCardBundleTree from '@/components/StoreCardBundleTree.vue'
import StoreCardCurrency from '@/components/StoreCardCurrency.vue'
import StoreCardRocketHouse from '@/components/StoreCardRocketHouse.vue'
import StoreCardBundleRocketHouse from '@/components/StoreCardBundleRocketHouse.vue'
import CheckoutItem from '@/components/CheckoutItem.vue'
import CheckoutContainer from '@/components/CheckoutContainer.vue'
import eventEmitter from '@/faster/events/emitter'
import Pkbutton from '@/components/PKButton'
import { dispatchToUnity, CLOSE_EVENT } from '@/store/unityDispatcher'

export default {
  name: 'Checkout',
  components: {
    StoreCardBundleStarter,
    StoreCardBundleTree,
    StoreCardCurrency,
    StoreCardRocketHouse,
    StoreCardBundleRocketHouse,
    CheckoutItem,
    CheckoutContainer,
    Pkbutton
  },
  data() {
    return {
      type: 'gems',
      sku: null
    }
  },
  computed: {
    ...mapState({
      location: store => store.geo.location,
      loaded: store => store.sku.loaded
    }),
    ...mapGetters({
      getPrice: 'sku/getPrice',
      retrieveSku: 'sku/retrieveSku',
      isFromUnity: 'platform/isFromUnity'
    }),
    isBundle() {
      return this.type === 'bundle'
    }
  },
  methods: {
    ...mapActions({
      retrieveLocation: 'geo/retrieveLocation',
      fetchSku: 'sku/fetchSku'
    }),
    ...mapGetters({ getCurrency: 'geo/getCurrency' }),
    close() {
      dispatchToUnity(CLOSE_EVENT, {})
    }
  },
  async beforeMount() {
    this.type = this.$route.query.type
    const skuId = this.$route.query.skuId

    await this.retrieveLocation()
    await this.fetchSku(skuId)
    this.sku = this.retrieveSku(skuId, this.type)

    eventEmitter.iapCheckout({
      skuName: skuId,
      value: this.getPrice(skuId),
      currency: this.getCurrency(),
      billingPlatform: 'Stripe',
      sequence: 1
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.checkout {
  &__close-button {
    width: 80px;
    height: 80px;
    border: 4px solid #00fff9;
    box-sizing: border-box;
    box-shadow: inset 0 0 10px #00FFF9;
    border-radius: 100px;
    margin-left: 30px;

    &__img {
      vertical-align: text-bottom;
      height: 30px;
    }
  }

  &__unity-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
  }

  &__unity-scrollbar::-webkit-scrollbar-thumb {
    border: solid 3px transparent;
    border-radius: 8px;
    box-shadow: inset 0 0 10px 10px #7623DF;
  }

  &__unity-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px #FAFAFA;
    border: solid 1px rgb(73, 66, 98, .3);
  }

  &__container {
    background-color: white;
    width: 100%;
    flex-grow: 1;
  }

  @include breakpoint('large') {
    &__body {
      min-width: 400px;
    }
  }

  @include breakpoint('extra-large') {
    &__body {
      min-width: 500px;
    }
  }
}
</style>
