export default {
  gems: {
    'com.movile.playkids.pkxd.gemspack1': {
      skuId: 'com.movile.playkids.pkxd.gemspack1',
      name: 'store.cards.gems.title80',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 50,
      bonus: 0,
      image: 'gem-pack-small.svg'
    },
    'com.movile.playkids.pkxd.gemspack2': {
      skuId: 'com.movile.playkids.pkxd.gemspack2',
      name: 'store.cards.gems.title210',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 180,
      bonus: 4,
      image: 'gem-pack-medium.svg'
    },
    'com.movile.playkids.pkxd.gemspack3': {
      skuId: 'com.movile.playkids.pkxd.gemspack3',
      name: 'store.cards.gems.title450',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 400,
      bonus: 12,
      image: 'gem-pack-large.svg'
    },
    'com.movile.playkids.pkxd.gemspack4': {
      skuId: 'com.movile.playkids.pkxd.gemspack4',
      name: 'store.cards.gems.title970',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 900,
      bonus: 20,
      image: 'gem-pack-giga.svg'
    },
    'com.movile.playkids.pkxd.gemspack5': {
      skuId: 'com.movile.playkids.pkxd.gemspack5',
      name: 'store.cards.gems.title970',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 2000,
      bonus: 20,
      image: 'gem-pack-giga.svg'
    },
    'com.movile.playkids.pkxd.gemspack6': {
      skuId: 'com.movile.playkids.pkxd.gemspack6',
      name: 'store.cards.gems.title970',
      component: 'StoreCardCurrency',
      type: 'gem',
      quantity: 6000,
      bonus: 20,
      image: 'gem-pack-giga.svg'
    }
  },
  coins: {
    'com.movile.playkids.pkxd.coinspack1': {
      skuId: 'com.movile.playkids.pkxd.coinspack1',
      name: 'store.cards.coins.title5000',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 3500,
      bonus: 0,
      image: 'coin-pack-small.svg'
    },
    'com.movile.playkids.pkxd.coinspack2': {
      skuId: 'com.movile.playkids.pkxd.coinspack2',
      name: 'store.cards.coins.title13000',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 12500,
      bonus: 3,
      image: 'coin-pack-medium.svg'
    },
    'com.movile.playkids.pkxd.coinspack3': {
      skuId: 'com.movile.playkids.pkxd.coinspack3',
      name: 'store.cards.coins.title27500',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 27000,
      bonus: 9,
      image: 'coin-pack-large.svg'
    },
    'com.movile.playkids.pkxd.coinspack4': {
      skuId: 'com.movile.playkids.pkxd.coinspack4',
      name: 'store.cards.coins.title60000',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 60000,
      bonus: 19,
      image: 'coin-pack-giga.svg'
    },
    'com.movile.playkids.pkxd.coinspack5': {
      skuId: 'com.movile.playkids.pkxd.coinspack5',
      name: 'store.cards.coins.title60000',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 150000,
      bonus: 19,
      image: 'coin-pack-giga.svg'
    },
    'com.movile.playkids.pkxd.coinspack6': {
      skuId: 'com.movile.playkids.pkxd.coinspack6',
      name: 'store.cards.coins.title60000',
      component: 'StoreCardCurrency',
      type: 'coin',
      quantity: 500000,
      bonus: 19,
      image: 'coin-pack-giga.svg'
    }
  },
  bundles: {
    'com.movile.playkids.pkxd.starterpack499': {
      skuId: 'com.movile.playkids.pkxd.starterpack499',
      id: 620,
      name: 'store.cards.starterBundle.title',
      component: 'StoreCardBundleStarter',
      quantity: {
        gems: 40,
        coins: 13000,
        rooms: 4
      }
    },
    'com.movile.playkids.pkxd.treehousepack': {
      skuId: 'com.movile.playkids.pkxd.treehousepack',
      name: 'store.cards.treeBundle.title',
      id: 1061,
      component: 'StoreCardBundleTree',
      quantity: {
        gems: 400,
        coins: 13000,
        rooms: 11
      }
    },
    'com.movile.playkids.pkxd.rockethouse': {
      skuId: 'com.movile.playkids.pkxd.rockethouse',
      id: 1313,
      name: 'store.cards.rocketHouse.title',
      component: 'StoreCardRocketHouse',
      quantity: {
        gems: 0,
        coins: 0,
        rooms: 11
      }
    },
    'com.movile.playkids.pkxd.rocketpack': {
      skuId: 'com.movile.playkids.pkxd.rocketpack',
      id: 1313,
      name: 'store.cards.rocketHouse.title',
      component: 'StoreCardBundleRocketHouse',
      quantity: {
        gems: 0,
        coins: 0,
        rooms: 11
      }
    }
  }
}
