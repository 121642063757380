<template>
  <div class="layout-default__body layout-default__body--store">
    <main class="layout-default__body__main">
      <div class="layout-default__body__main__container layout-default__body__main__container--store">
        <div class="checkout-success">
          <div class="checkout-success__header">
            <div class="checkout-success__header__title" v-t="'checkout.success.title'" />
            <div class="checkout-success__header__subtitle" v-t="'checkout.success.subtitle'" />
          </div>
          <div class="checkout-success__main">
            <CheckoutSuccessStar class="checkout-success__main__star"/>
            <div
              class="checkout-success__main__item"
              :class="{'checkout-success__main__item--bundle': isBundle}">
              <component :is="sku.component" v-bind="sku" />
            </div>
          </div>
          <CheckoutButton :name="'checkout.success.buttons.keepShopping'" :click="backToStore" :disabled="false" class="checkout-success__main__button"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CheckoutSuccessStar from '@/components/CheckoutSuccessStar.vue'
import StoreCardBundleStarter from '@/components/StoreCardBundleStarter.vue'
import StoreCardBundleTree from '@/components/StoreCardBundleTree.vue'
import StoreCardCurrency from '@/components/StoreCardCurrency.vue'
import CheckoutButton from '@/components/CheckoutButton.vue'
import { HOME_ROUTE } from '@/router/router-types'

export default {
  name: 'checkoutSuccess',
  components: {
    CheckoutSuccessStar,
    StoreCardBundleStarter,
    StoreCardBundleTree,
    StoreCardCurrency,
    CheckoutButton
  },
  data() {
    return {
      type: 'gems',
      sku: {
        price: {
          brl: 0,
          usd: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({ retrieveSku: 'sku/retrieveSku' }),
    isBundle() {
      return this.type === 'bundle'
    }
  },
  methods: {
    backToStore() {
      this.$router.push(HOME_ROUTE)
    }
  },
  beforeMount() {
    this.type = this.$route.query.type
    this.sku = this.retrieveSku(this.$route.query.skuId, this.type)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.checkout-success {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-top: 2 * $gap;
      color: #fff;
      font: {
        size: 3rem;
        weight: 700;
      }
    }

    &__subtitle {
      margin-top: $gap;
      color: #fff;
      font: {
        size: 2rem;
        weight: 700;
      }
    }
  }

  &__main {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      position: relative;
      z-index: 2;
      width: 200px;

      &--bundle {
        width: 350px;
      }
    }

    &__button {
      z-index: 1;
    }

    &__star {
      position: absolute;
      top: 250px;
      width: 100%;
      left: 0;
      z-index: 1;
    }
  }

  @include media('1500px') {
    &__header {
      &__title {
        margin-top: 0;
      }
    }

    &__main {
      height: 400px;

      &__star {
        top: 180px;
      }
    }
  }
}
</style>
