<template>
  <div class="layout-default__body layout-default__body--store">
    <main class="layout-default__body__main">
      <div class="layout-default__body__main__container layout-default__body__main__container--store">
        <div class="checkout-success">
          <div class="checkout-success__main">
            <div
              class="checkout-success__main__item"
              :class="{'checkout-success__main__item--bundle': isBundle}">
              <component :is="sku.component" v-bind="sku" />
            </div>
          </div>
          <p class="checkout-success__title">{{ $t('checkout.collect.title') }}</p>
          <CheckoutButton :name="'checkout.collect.button'" :click="collect" :disabled="false" class="checkout-success__main__button"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CheckoutSuccessStar from '@/components/CheckoutSuccessStar.vue'
import StoreCardBundleStarter from '@/components/StoreCardBundleStarter.vue'
import StoreCardBundleTree from '@/components/StoreCardBundleTree.vue'
import StoreCardCurrency from '@/components/StoreCardCurrency.vue'
import CheckoutButton from '@/components/CheckoutButton.vue'
import { dispatchToUnity, COLLECT_EVENT } from '@/store/unityDispatcher'

export default {
  name: 'checkoutSuccess',
  components: {
    CheckoutSuccessStar,
    StoreCardBundleStarter,
    StoreCardBundleTree,
    StoreCardCurrency,
    CheckoutButton
  },
  data() {
    return {
      type: 'gems',
      sku: {
        price: {
          brl: 0,
          usd: 0
        }
      }
    }
  },
  computed: {
    ...mapState({
      purchaseWallet: store => store.purchase.purchaseWallet
    }),
    ...mapGetters({
      retrieveSku: 'sku/retrieveSku',
      isFromUnity: 'platform/isFromUnity'
    }),
    isBundle() {
      return this.type === 'bundle'
    }
  },
  methods: {
    collect() {
      dispatchToUnity(COLLECT_EVENT, {
        ResponseCode: 0,
        Sku: this.$route.query.skuId,
        Wallet: this.purchaseWallet.wallet,
        Reward: this.purchaseWallet.added.reward,
        ItemMap: this.purchaseWallet.added.itemsList,
        QuantityMap: this.purchaseWallet.added.quantityList,
        Pets: this.purchaseWallet.added.petsToAdd
      })
    }
  },
  beforeMount() {
    this.type = this.$route.query.type
    this.sku = this.retrieveSku(this.$route.query.skuId, this.type)
  },
  mounted() {
    // Skip collect screen if we dont have a web card(hidden) and its from unity (checkout collect)
    if (this.isFromUnity && this.type === 'hidden' && this.purchaseWallet != null) {
      this.collect()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.checkout-success {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: bold;
    font-size: 28px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

  &__main {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__item {
      position: relative;
      z-index: 2;
      width: 200px;

      &--bundle {
        width: 350px;
      }
    }

    &__button {
      z-index: 1;
    }
  }

  @include media('1500px') {
    &__main {
      height: 400px;
    }
  }
}
</style>
