import { render, staticRenderFns } from "./CaptchaModal.vue?vue&type=template&id=f2ab2268&scoped=true&"
import script from "./CaptchaModal.vue?vue&type=script&lang=js&"
export * from "./CaptchaModal.vue?vue&type=script&lang=js&"
import style0 from "./CaptchaModal.vue?vue&type=style&index=0&id=f2ab2268&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2ab2268",
  null
  
)

export default component.exports