import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  HOME_ROUTE,
  CHECKOUT_ROUTE,
  CHECKOUT_SUCCESS_ROUTE,
  CHECKOUT_COLLECT_ROUTE,
  LOGIN_ROUTE,
  PASSWORD_FORGOT_ROUTE,
  PASSWORD_RESET_ROUTE,
  PRIVACY_ROUTE,
  PUBLIC_PRIVACY_ROUTE,
  CONFIRM_PRIVACY_ROUTE,
  PROMO_CODE_ROUTE
} from './router-types'
import store from '@/store'
import Home from '../views/Home.vue'
import Checkout from '../views/Checkout.vue'
import CheckoutSuccess from '../views/CheckoutSuccess.vue'
import CheckoutCollect from '../views/CheckoutCollect.vue'
import ConfirmationEmail from '../views/ConfirmationEmail.vue'
import Login from '../views/Login.vue'
import PasswordReset from '../views/PasswordReset.vue'
import PasswordForgot from '../views/PasswordForgot.vue'
import Privacy from '../views/Privacy.vue'
import PublicPrivacy from '../views/PublicPrivacy.vue'
import PromoCode from '../views/PromoCode.vue'
import eventEmitter from '@/faster/events/emitter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      ...HOME_ROUTE
    }
  },
  {
    ...HOME_ROUTE,
    path: '/home',
    redirect: PRIVACY_ROUTE,
    component: Home,
    beforeEnter: (to, from, next) => {
      store.dispatch('privacy/privacyState')
      next()
    },
    meta: {
      authenticationGuard: true
    },
    children: [
      {
        ...CHECKOUT_ROUTE,
        path: '/checkout',
        props: (route) => ({ query: { skuId: route.query.skuId, type: route.query.type } }),
        component: Checkout,
        beforeEnter: (to, from, next) => {
          store.dispatch('platform/fetchPlatform')
          next()
        },
        meta: {
          authenticationGuard: true
        }
      },
      {
        ...CHECKOUT_SUCCESS_ROUTE,
        props: (route) => ({ query: { skuId: route.query.skuId, type: route.query.type } }),
        component: CheckoutSuccess,
        meta: {
          authenticationGuard: true
        }
      },
      {
        ...CHECKOUT_COLLECT_ROUTE,
        props: (route) => ({ query: { skuId: route.query.skuId, type: route.query.type } }),
        component: CheckoutCollect,
        meta: {
          authenticationGuard: true
        }
      },
      {
        ...PRIVACY_ROUTE,
        path: '/privacy',
        component: Privacy,
        beforeEnter: (to, from, next) => {
          store.dispatch('privacy/adsConsent')
          next()
        },
        meta: {
          authenticationGuard: true
        }
      },
      {
        ...PUBLIC_PRIVACY_ROUTE,
        path: '/public/privacy',
        component: PublicPrivacy,
        meta: {
          authenticationGuard: false
        }
      },
      {
        ...PROMO_CODE_ROUTE,
        path: '/promo-code',
        component: PromoCode,
        meta: {
          authenticationGuard: true
        }
      }
    ]
  },
  {
    ...LOGIN_ROUTE,
    path: '/login',
    component: Login
  },
  {
    ...PASSWORD_FORGOT_ROUTE,
    path: '/forgot-password',
    component: PasswordForgot
  },
  {
    ...PASSWORD_RESET_ROUTE,
    path: '/password-reset',
    component: PasswordReset
  },
  {
    ...CONFIRM_PRIVACY_ROUTE,
    props: (route) => ({ query: { token: route.query.token } }),
    path: '/confirm/:operation',
    component: ConfirmationEmail
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  const position = savedPosition || { x: 0, y: 0 }

  return position
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

const authentication = {
  createUserSession: () => store.dispatch('authentication/createUserSession')
}

async function hasUserSession() {
  await authentication.createUserSession()
  return store.state.authentication.authenticated
}

router.beforeEach(async (to, from, next) => {
  eventEmitter.screen(to.name)

  if (to.meta.authenticationGuard) {
    if (!store.state.authentication.authenticated) {
      if (await hasUserSession()) {
        next()
      } else {
        next(LOGIN_ROUTE)
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
