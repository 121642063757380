import Vue from 'vue'
import IconAvatarBack from '@/assets/images/icons/avatar-back.svg?inline'
import IconAvatarFace from '@/assets/images/icons/avatar-face.svg?inline'
import IconAvatarFeet from '@/assets/images/icons/avatar-feet.svg?inline'
import IconAvatarGlasses from '@/assets/images/icons/avatar-glasses.svg?inline'
import IconAvatarGlove from '@/assets/images/icons/avatar-glove.svg?inline'
import IconAvatarHair from '@/assets/images/icons/avatar-hair.svg?inline'
import IconAvatarHat from '@/assets/images/icons/avatar-hat.svg?inline'
import IconAvatarLegs from '@/assets/images/icons/avatar-legs.svg?inline'
import IconAvatarShirt from '@/assets/images/icons/avatar-shirt.svg?inline'
import IconHomeBathroom from '@/assets/images/icons/home-bathroom.svg?inline'
import IconHomeColor from '@/assets/images/icons/home-color.svg?inline'
import IconHomeFloor from '@/assets/images/icons/home-floor.svg?inline'
import IconHomeGarden from '@/assets/images/icons/home-garden.svg?inline'
import IconHomeKitchen from '@/assets/images/icons/home-kitchen.svg?inline'
import IconHomeParty from '@/assets/images/icons/home-party.svg?inline'
import IconHomeRoom from '@/assets/images/icons/home-room.svg?inline'
import IconHomeSofa from '@/assets/images/icons/home-sofa.svg?inline'
import IconHomeWall from '@/assets/images/icons/home-wall.svg?inline'
import IconInventoryAvatar from '@/assets/images/icons/inventory-avatar.svg?inline'
import IconInventoryHome from '@/assets/images/icons/inventory-home.svg?inline'
import IconArrowNext from '@/assets/images/icons/arrow-next.svg?inline'
import IconLogout from '@/assets/images/icons/logout.svg?inline'
import IconAccount from '@/assets/images/icons/account.svg?inline'
import IconSettings from '@/assets/images/icons/settings.svg?inline'
import IconCamera from '@/assets/images/icons/camera.svg?inline'
import IconFacebook from '@/assets/images/icons/facebook.svg?inline'

const components = {
  IconAvatarBack,
  IconAvatarFace,
  IconAvatarFeet,
  IconAvatarGlasses,
  IconAvatarGlove,
  IconAvatarHair,
  IconAvatarHat,
  IconAvatarLegs,
  IconAvatarShirt,
  IconHomeBathroom,
  IconHomeColor,
  IconHomeFloor,
  IconHomeGarden,
  IconHomeKitchen,
  IconHomeParty,
  IconHomeRoom,
  IconHomeSofa,
  IconHomeWall,
  IconInventoryAvatar,
  IconInventoryHome,
  IconArrowNext,
  IconLogout,
  IconAccount,
  IconSettings,
  IconCamera,
  IconFacebook
}

Object.keys(components).forEach(item => {
  Vue.component(item, components[item])
})
