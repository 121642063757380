import client from '@/store/modules/clients/privacyHttpClient'
const state = {
  privacyState: 'DEFAULT',
  adsConsentState: false
}

const actions = new function() {
  this.requestEmailChange = async (context, { newEmail, oldEmail }) => {
    newEmail = newEmail.toLowerCase()
    const response = await client.post('/v1/privacy/data/email-change/request', {
      oldEmail,
      newEmail,
      application: 'PKXD'
    })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.scheduleDelete = async (context) => {
    const response = await client.post('/v1/privacy/delete/schedule/new', { application: 'PKXD' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.requestData = async (context) => {
    const response = await client.post('/v1/privacy/data/request', { application: 'PKXD' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.findSchedules = async (context) => {
    const response = await client.get('/v1/privacy/delete/schedule')
    if (response.ok) {
      return response.json()
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.cancelSchedule = async (context) => {
    const response = await client.post('/v1/privacy/delete/schedule/cancel', {})
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.privacyState = async ({ commit }) => {
    const schedules = await this.findSchedules()
    let privacyState = 'DEFAULT'
    if (schedules.length > 0) {
      privacyState = schedules[0].status
    }
    commit('SET_PRIVACY_STATE', privacyState)
  }

  this.adsConsent = async ({ commit }) => {
    const response = await client.get('/v1/privacy/personalized-ads-consent')
    if (response.ok) {
      const consent = await response.json()
      commit('SET_ADS_CONSENT', consent)
    }
  }

  this.updateAdsConsent = async (context, newConsent) => {
    const response = await client.patch('/v1/privacy/personalized-ads-consent', { consent: newConsent })
    if (response.ok) {
      await this.adsConsent(context)
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }
}()

const mutations = {
  SET_PRIVACY_STATE(state, privacyState) {
    state.privacyState = privacyState
  },
  SET_ADS_CONSENT(state, adsConsentState) {
    state.adsConsentState = adsConsentState
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
