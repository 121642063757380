import gql from 'graphql-tag'
import { apolloClient } from '../apollo'

const state = {
  name: null,
  coins: 0,
  gems: 0
}

const getters = {}

const query = gql`query {
  profile {
    name,
    coins,
    gems
  }
}
`

const actions = {
  async fetchUserProfile({ commit }) {
    var response = await apolloClient.query({ query: query, fetchPolicy: 'network-only' })
    commit('SET_USER_PROFILE', response.data.profile)
  }
}

const mutations = {
  SET_USER_PROFILE(state, userProfile) {
    state.name = userProfile.name || ''
    state.coins = userProfile.coins
    state.gems = userProfile.gems
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
