export default {
  description: 'Bienvenue dans votre monde virtuel! PK XD est synonyme d\'amusement : créez votre propre avatar, retrouvez-vous entre amis et partez à de nouvelles aventures.',
  passwordReset: {
    titles: {
      default: 'Nouveau mot de passe',
      success: 'Le nouveau mot de passe a été enregistré !',
      error: 'Une erreur est survenue'
    },
    subtitles: {
      default: 'Saisis un nouveau mot de passe pour ton compte PK XD.',
      success: 'Le nouveau mot de passe a été enregistré !',
      error: `Une erreur est survenue. Essaie à nouveau s'il-te-plaît.`
    },
    placeholder: '(6 à 15 caractères)',
    labels: {
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmer le mot de passe'
    },
    buttons: {
      save: 'ENREGISTRER'
    },
    errors: {
      size: 'Saisis un mot de passe comprenant entre 6 et 15 caractères.',
      mismatch: `Les mots de passe ne correspondent pas. Essaie à nouveau s'il-te-plaît.`
    }
  },
  passwordForgot: {
    titles: {
      default: 'Tu as oublié ton mot de passe ?',
      success: 'Ta demande a été envoyée',
      error: 'Une erreur est survenue'
    },
    subtitles: {
      default: `Saisis ton adresse e-mail et nous t'enverrons des instructions pour changer ton mot de passe.`,
      success: 'Si cette adresse e-mail est enregistrée sur PK XD, tu recevras des instructions pour changer ton mot de passe par e-mail. Vérifie ta boîte e-mail et suis les instructions.',
      error: {
        nicknameNotFound: `Nom d'utilisateur introuvable`,
        emailNotFound: 'Email introuvable',
        noEmailAssociated: `Ce compte n'a pas d'adresse email associée`,
        default: `Une erreur est survenue. Essaie à nouveau s'il-te-plaît.`
      }
    },
    buttons: {
      send: 'ENVOYER'
    },
    labels: {
      key: 'e-mail'
    },
    errors: {
      invalidKey: 'Saisis un e-mail valide.'
    }
  },
  login: {
    username: `Email enregistré`,
    password: 'Mot de passe',
    email: 'e-mail',
    forgotPassword: 'Mot de passe oublié',
    backToLogin: 'Retour à la connexion',
    didNotGetEmail: `Tu n'as pas reçu l'e-mail ?`,
    titles: {
      forgotPassword: 'Tu as oublié ton mot de passe ?'
    },
    subtitles: {
      enterPassword: `Saisis ton adresse e-mail et nous t'enverrons des instructions pour changer ton mot de passe.`,
      emailSent: `Nous t'avons envoyé un e-mail : vérifie ta boîte mail :)`
    },
    buttons: {
      login: 'CONNEXION',
      send: 'ENVOYER'
    }
  },
  store: {
    titleLine1: 'Fais une bonne',
    titleLine2: 'affaire ici !',
    cards: {
      bonus: 'Bonus',
      gems: {
        title80: '80 Gemmes',
        title210: '210 Gemmes',
        title450: '450 Gemmes',
        title970: '970 Gemmes'
      },
      coins: {
        title5000: '5000 Pièces',
        title13000: '13000 Pièces',
        title27500: '27500 Pièces',
        title60000: '60000 Pièces'
      },
      starterBundle: {
        title: 'LOT DE DÉMARRAGE',
        subtitle: 'Achat unique',
        text: 'Maison exclusive',
        rooms: 'Pièces'
      },
      treeBundle: {
        title: 'PACK CABANE DANS LES ARBRES',
        subtitle: 'Achat unique',
        text: 'Maison exclusive',
        rooms: 'Pièces'
      },
      rocketHouse: {
        title: 'MAISON VAISSEAU FUSÉE',
        subtitle: 'Achat unique',
        text: 'Maison exclusive',
        rooms: 'Pièces'
      },
      rocketHouseBundle: {
        title: 'PACK SPATIAL',
        subtitle: 'Achat unique',
        text: 'Maison exclusive',
        rooms: 'Pièces',
        offer: 'Meilleur prix'
      }
    }
  },
  friendList: {
    title: `Liste d'amis`,
    noFriendsOnline: 'Aucun ami en ligne',
    online: 'En ligne',
    offline: 'Hors-ligne'
  },
  checkout: {
    back: 'Retour',
    title: 'Payer avec une carte de crédit',
    subtitle: '+ Payer avec une autre carte',
    lastDigits: 'Derniers chiffres',
    buttons: {
      edit: 'Modifier',
      delete: 'Supprimer',
      pay: 'Payer',
      close: 'FERMER',
      save: 'ENREGISTRER',
      saveMyInfo: 'Enregistrer mes informations pour les prochains achats'
    },
    form: {
      title: 'Données personnelles',
      name: 'Nom',
      email: 'e-mail',
      card: 'Données de la carte',
      fullName: 'Nom et prénom',
      identityNumber: `Numéro de carte d'identité`,
      postalCode: 'Code postal',
      address: 'Adresse (rue, avenue, voie)',
      number: 'Numéro',
      complement: `Complément d'adresse`,
      neighborhood: 'Lotissement',
      city: 'Ville',
      state: 'Région',
      errors: {
        name: 'Nom non valide',
        email: 'E-mail non valide',
        card: 'Carte non valide',
        fullName: 'Nom non valide',
        identityNumber: `Numéro de carte d'identité non valide`,
        postalCode: 'Code postal non valide'
      }
    },
    modal: {
      edit: {
        title: 'MODIFIER LA CARTE DE CRÉDIT',
        lastDigits: 'avec les derniers chiffres',
        credit: '(Crédit)'
      }
    },
    errors: {
      title: 'ACHAT ÉCHOUÉ',
      subtitle: 'Une erreur est survenue, essaie à nouveau.',
      required: 'Requis'
    },
    success: {
      title: 'Ton achat a été réalisé avec succès !',
      subtitle: `Rends-toi maintenant sur l'appli PKXD et profites-en !`,
      buttons: {
        keepShopping: 'Ou continue tes achats !'
      }
    },
    collect: {
      title: 'Ton achat a été réalisé avec succès !',
      button: 'Collecter'
    }
  },
  general: {
    success: 'Succès',
    tryAgain: `Essaie à nouveau s'il-te-plaît.`
  },
  navbar: {
    menu: {
      store: 'Magasin',
      editAvatar: `Modifier l'avatar`
    },
    dropdown: {
      logout: 'Déconnexion'
    }
  },
  inventory: {
    buttons: {
      save: 'ENREGISTRER'
    }
  },
  screenshot: {
    share: 'Partager sur Facebook'
  }
}
