import devConfig from './development.config'

export default Object.assign(devConfig, {
  applicationId: 'playkids-xd-web',
  applicationKey: '3bf69048-0710-4d31-9fc6-9318e21bdb2a',
  applicationSecret: 'VVh1oZegK7UUriRlo0KvYASH',
  apiHost: 'https://pkxd-web.pkds.it',
  privacyApiHost: 'https://privacy.aftvrsys.com',
  productId: 'playkids-xd',
  environment: 'PRODUCTION',
  authenticator: 'faster-account-authenticator',
  stripePublicKey: {
    br: 'pk_live_51KiHjHDhkIvAUuxSwrnMv3tfbNVxRcP4RDBE4f3VbxRbNZoYqY2QuPXorBOQRLJVQfxlK7r1FuAGdTgtB2wdEQVe00FDoHQ1iE',
    us: 'pk_live_51KaozWGPrkp9TXNgbjLHyj3aL3n5SnsPz7pOVJzPyzRefGyyIEamobqCiBedItSDlPz2giItNp62uuC79GKua6Z6007ThwMDqZ'
  },
  billingPlatform: {
    br: 'cab2869d-dbe5-47e0-b0a6-2ae847d3e328',
    us: 'f7da8961-1b8d-4d2b-ae44-1f6d73807a42'
  },
  facebookAppId: '670124290103970'
})
