const state = {
  instance: null,
  loaded: false
}

const getters = {}

const actions = {
  instantiate({ commit, state }, src) {
    commit('SET_LOADED', false)
    window.addEventListener('unity-loaded', () => { commit('SET_LOADED', true) })
    if (typeof UnityLoader === 'undefined') {
      let error = 'The UnityLoader was not defined, please add the script tag ' +
        'to the base html and embed the UnityLoader.js file Unity exported or use "unityLoader" attribute for path to UnityLoader.js.'
      console.error(error)
      return
    }
    if (src === null) {
      let error = 'Please provice a path to a valid JSON in the "src" attribute.'
      console.error(error)
      return
    }
    let params = {}
    if (this.externalProgress) {
      params.onProgress = UnityProgress
    }
    if (this.module) {
      params.Module = this.module
    }
    const gameInstance = UnityLoader.instantiate('unity-container', src, params)
    commit('SET_INSTANCE', gameInstance)
  },
  message(context, { gameObject, method, param }) {
    if (param === null) {
      param = ''
    }
    if (state.instance !== null) {
      state.instance.SendMessage(gameObject, method, param)
    } else {
      console.warn('vue-unity-webgl: you\'ve sent a message to the Unity content, but it wasn\t instantiated yet.')
    }
  }
}

const mutations = {
  SET_INSTANCE(state, instance) {
    state.instance = instance
  },
  SET_LOADED(state, loaded) {
    state.loaded = loaded
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
