import gql from 'graphql-tag'
import { apolloClient } from '../apollo'

const state = {
  host: null,
  file: null
}

const getters = {}

const saveScreenshot = gql`mutation ($image: String!) {
  saveScreenshot (image: $image) {
    host,
    file
  }
}`

const deleteScreenshot = gql`mutation ($file: String!) {
  deleteScreenshot (file: $file)
}`

const actions = {
  async save({ commit }, image) {
    const response = await apolloClient.mutate({ mutation: saveScreenshot, variables: { image } })
    commit('SET_SCREENSHOT_HOST', response.data.saveScreenshot.host)
    commit('SET_SCREENSHOT_FILE', response.data.saveScreenshot.file)
  },
  async delete({ state, commit }) {
    if (!state.file) {
      return
    }

    await apolloClient.mutate({ mutation: deleteScreenshot, variables: { file: state.file } })
    commit('SET_SCREENSHOT_HOST', null)
    commit('SET_SCREENSHOT_FILE', null)
  }
}

const mutations = {
  SET_SCREENSHOT_HOST(state, host) {
    state.host = host
  },
  SET_SCREENSHOT_FILE(state, file) {
    state.file = file
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
