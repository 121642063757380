<template>
  <BaseCard class="rocker-house">
    <div class="clickable-overlay" @click="$emit('click', $event, index)" v-show="isClickable"/>
    <div class="rocker-house__content">
      <p class="rocker-house__content__title">{{ $t('store.cards.rocketHouse.title') }}</p>
      <p class="rocker-house__content__subtitle">{{ $t('store.cards.rocketHouse.subtitle') }}</p>
      <div class="rocker-house__content__details">
        <p class="rocker-house__content__details__title" :style="{'font-size': formatToRem(fontSizes.house.text)}">
          {{ $t('store.cards.rocketHouse.text') }}
        </p>
        <div class="rocker-house__content__details__rooms" :style="{'font-size': formatToRem(fontSizes.house.rooms)}">
          {{ $t('store.cards.rocketHouse.rooms') }}: {{ quantity.rooms }}
        </div>
      </div>
      <img src="@/assets/store/rocket-house.svg" class="rocker-house__content__img">
    </div>
    <div class="rocker-house__price" :style="{'font-size': formatToRem(fontSizes.price)}" >
      {{ $n(getPrice(skuId), 'currency', location.country) }}
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseCard from '@/components/card/BaseCard.vue'

const fontConstants = {
  widthReference: 395,
  fields: {
    label: {
      title: 1.25,
      subtitle: 1
    },
    coin: 1.25,
    gem: 1.25,
    house: {
      text: 1.2,
      rooms: 1
    },
    price: 2.5
  }
}

export default {
  name: 'StoreCardRocketHouse',
  components: {
    BaseCard
  },
  props: {
    skuId: String,
    name: String,
    component: String,
    price: Object,
    quantity: {
      coins: Number,
      gems: Number,
      rooms: Number
    },
    isClickable: {
      Type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fontSizes: {
        label: {
          title: fontConstants.fields.label.title,
          subtitle: fontConstants.fields.label.subtitle
        },
        coin: fontConstants.fields.coin,
        gem: fontConstants.fields.gem,
        house: {
          text: fontConstants.fields.house.text,
          rooms: fontConstants.fields.house.rooms
        },
        price: fontConstants.fields.price
      }
    }
  },
  methods: {
    formatToRem(value) {
      return value + 'rem'
    }
  },
  computed: {
    ...mapGetters({ getPrice: 'sku/getPrice' }),
    ...mapState({ location: store => store.geo.location })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.rocker-house {
  width: 100%;
  padding: 1.8%;
  border-radius: 20px;
  border: 4px solid map-get($colors-custom, 'green-dark');
  position: relative;
  background: linear-gradient(
    -11deg,
    map-get($colors-custom, 'green'),
    map-get($colors-custom, 'green') 25%,
    map-get($colors-custom, 'green-dark') 25%,
    map-get($colors-custom, 'green-dark') calc(25% + 21.5%),
    map-get($colors-custom, 'green') calc(25% + 21.5%),
    map-get($colors-custom, 'green') calc(25% + 21.5% + 7.15%),
    map-get($colors-custom, 'green-dark') calc(25% + 21.5% + 7.15%),
    map-get($colors-custom, 'green-dark') calc(25% + 21.5% + 7.15% + 7.15%),
    map-get($colors-custom, 'green') calc(25% + 21.5% + 7.15% + 7.15%)
  );
  box-shadow: 0 7px 0 map-get($colors-custom, 'green-darker');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__content {
    position: relative;

    &__img {
      width: 100%;
      border-radius: 10px;
      margin-top: -15%;
    }

    &__title {
      margin: 10px 0 0 10px;
      position: absolute;
      font-weight: bold;
      font-size: 22px;
      color: map-get($colors-custom, 'blue-light');
      text-shadow: rgb(41, 0, 73) 0 2px 0;
    }

    &__subtitle {
      margin: 35px 0 0 10px;
      position: absolute;
      font-weight: bold;
      font-size: 18px;
      color: map-get($colors-custom, 'yellow');
      text-shadow: 0 2px 0 rgba(0, 0, 0, 0.55);
    }

    &__details {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      text-align: center;

      &__title {
        text-transform: lowercase;
        font-weight: bold;
        text-shadow: 0 2px 2px map-get($colors-custom, 'purple-darkest');
        color: map-get($colors-custom, 'blue-light');
      }

      &__rooms {
        width: 50%;
        padding: 2.1% 0 2.8% 0;
        margin: 0 auto;
        margin-top: 2%;
        border-radius: 60px;
        background: {
          color: map-get($colors-custom, 'blue-light')
        };
        color: #000;
        font: {
          weight: 700;
          size: 1rem;
        }
        text-align: center;
      }
    }
  }

  &__price {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    color: map-get($colors-custom, 'purple-dark');
    font: {
      weight: 700;
      size: 2.5rem;
    }
  }
}
</style>
