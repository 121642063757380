export default {
  description: 'Добро пожаловать в ваш собственный виртуальный мир! PK XD — это настоящее развлечение: создайте собственный аватар и отправляйтесь в новые приключения.',
  passwordReset: {
    titles: {
      default: 'Новый пароль',
      success: 'Новый пароль успешно установлен.',
      error: 'Что-то пошло не так'
    },
    subtitles: {
      default: 'Введи новый пароль к аккаунту PK XD.',
      success: 'Новый пароль успешно установлен.',
      error: `Похоже, произошла ошибка. Повтори попытку.`
    },
    placeholder: '(от 6 до 15 символов)',
    labels: {
      newPassword: 'Новый пароль',
      confirmPassword: 'Подтверди пароль'
    },
    buttons: {
      save: 'СОХРАНИТЬ'
    },
    errors: {
      size: 'Введи действительный пароль от 6 до 15 символов',
      mismatch: 'Эти пароли не совпадают. Повтори попытку.'
    }
  },
  passwordForgot: {
    titles: {
      default: 'Забыл(-а) пароль?',
      success: 'Запрос получен',
      error: 'Что-то пошло не так'
    },
    subtitles: {
      default: `Введи свой адрес эл. почты ниже, и мы вышлем тебе эл. письмо с инструкциями по изменению пароля.`,
      success: 'Если твой предыдущий адрес эл. почты зарегистрирован в PK XD, ты получишь инструкции по смене пароля по эл. почте. Проверь входящие и выполняй приведенные ниже инструкции.',
      error: {
        nicknameNotFound: 'Имя пользователя не найдено',
        emailNotFound: 'Электронная почта не найдена',
        noEmailAssociated: 'У этой учетной записи нет зарегистрированного адреса электронной почты',
        default: 'Похоже, произошла ошибка. Повтори попытку.'
      }
    },
    buttons: {
      send: 'ОТПРАВИТЬ'
    },
    labels: {
      key: 'эл. почта'
    },
    errors: {
      invalidKey: 'Введи действительный адрес эл. почты'
    }
  },
  login: {
    username: `зарегистрированная электронная почта`,
    password: 'Пароль',
    email: 'эл. почта',
    forgotPassword: 'Забыл пароль',
    backToLogin: 'назад на страницу авторизации',
    didNotGetEmail: 'Письмо не пришло?',
    titles: {
      forgotPassword: 'Забыл(-а) пароль?'
    },
    subtitles: {
      enterPassword: `Введи свой адрес эл. почты ниже, и мы вышлем тебе эл. письмо с инструкциями по изменению пароля.`,
      emailSent: 'Письмо отправлено. Проверь почту :)'
    },
    buttons: {
      login: 'ВХОД',
      send: 'ОТПРАВИТЬ'
    }
  },
  store: {
    titleLine1: 'Отличные',
    titleLine2: 'скидки ждут!',
    cards: {
      bonus: 'Бонус',
      gems: {
        title80: '80 САМОЦВЕТЫ',
        title210: '210 САМОЦВЕТЫ',
        title450: '450 САМОЦВЕТЫ',
        title970: '970 САМОЦВЕТЫ'
      },
      coins: {
        title5000: '5000 монет(ы)',
        title13000: '13000 монет(ы)',
        title27500: '27500 монет(ы)',
        title60000: '60000 монет(ы)'
      },
      starterBundle: {
        title: 'НАЧАЛЬНЫЙ ПАКЕТ',
        subtitle: 'Одноразовая покупка',
        text: 'Эксклюзивный дом',
        rooms: 'Комнаты'
      },
      treeBundle: {
        title: 'КОМПЛЕКТ ДОМИКОВ НА ДЕРЕВЕ',
        subtitle: 'Одноразовая покупка',
        text: 'Эксклюзивный дом',
        rooms: 'Комнаты'
      },
      rocketHouse: {
        title: 'РАКЕТА-ДОМ',
        subtitle: 'Одноразовая покупка',
        text: 'Эксклюзивный дом',
        rooms: 'Комнаты'
      },
      rocketHouseBundle: {
        title: 'КОСМИЧЕСКИЙ ПАКЕТ',
        subtitle: 'Одноразовая покупка',
        text: 'Эксклюзивный дом',
        rooms: 'Комнаты',
        offer: 'Лучшая цена'
      }
    }
  },
  friendList: {
    title: `Список друзей`,
    noFriendsOnline: 'Нет друзей в сети',
    online: 'Онлайн',
    offline: 'Оффлайн'
  },
  checkout: {
    back: 'Назад',
    title: 'Оплатить кредитной картой',
    subtitle: '+ Оплатить другой картой',
    lastDigits: 'Последние цифры',
    buttons: {
      edit: 'Редактировать',
      delete: 'Удалить',
      pay: 'Оплатить',
      close: 'ЗАКРЫТЬ',
      save: 'СОХРАНИТЬ',
      saveMyInfo: 'Сохранить мою информацию для будущих покупок'
    },
    form: {
      title: 'Личная информация',
      name: 'имя',
      email: 'эл. почта',
      card: 'Данные карты',
      fullName: 'ФИО',
      identityNumber: `идентификационный номер`,
      postalCode: 'Почтовый индекс',
      address: 'Адрес (улица, проспект, аллея)',
      number: 'Дом',
      complement: `Квартира`,
      neighborhood: 'окрестности',
      city: 'Город',
      state: 'Штат',
      errors: {
        name: 'неверное имя',
        email: 'Неверный эл. почта',
        card: 'Неверная карта',
        fullName: 'неверное имя',
        identityNumber: `Неверный формат номер документа`,
        postalCode: 'Неверный почтовый индекс'
      }
    },
    modal: {
      edit: {
        title: 'РЕДАКТИРОВАТЬ КРЕДИТНУЮ КАРТУ',
        lastDigits: 'с последними цифрами',
        credit: '(кредитная карта)'
      }
    },
    errors: {
      title: 'ПОКУПКА НЕ СОВЕРШЕНА',
      subtitle: 'Что-то пошло не так, попробуйте еще раз.',
      required: 'Обязательное поле'
    },
    success: {
      title: 'Покупка успешна совершена!',
      subtitle: `Приятной игры в PKXD!`,
      buttons: {
        keepShopping: 'Либо вы можете остаться в магазине!'
      }
    },
    collect: {
      title: 'Покупка успешна совершена!',
      button: 'СОБРАТЬ'
    }
  },
  general: {
    success: 'Успех',
    tryAgain: `Повтори попытку.`
  },
  navbar: {
    menu: {
      store: 'Магазин',
      editAvatar: `Редактировать аватар`
    },
    dropdown: {
      logout: 'Выйти'
    }
  },
  inventory: {
    buttons: {
      save: 'СОХРАНИТЬ'
    }
  },
  screenshot: {
    share: 'Поделиться на Facebook'
  }
}
