<template>
  <section class="user-profile">
    <div class="user-profile__nickname">{{ userProfile.name }}</div>
    <div class="user-profile__items">
      <transition name="scale" mode="out-in">
        <div class="user-profile__items__item user-profile__items__item--coins" :key="display.coins.animation">
          {{ display.coins.value }}
        </div>
      </transition>
      <transition name="scale" mode="out-in">
        <div class="user-profile__items__item user-profile__items__item--gems" :key="display.gems.animation">
          {{ display.gems.value }}
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UserProfile',
  computed: {
    ...mapState({
      userProfile: store => store.userProfile
    }),
    coins() {
      return this.userProfile.coins
    },
    gems() {
      return this.userProfile.gems
    }
  },
  data() {
    return {
      display: {
        coins: {
          value: 0,
          counter: null,
          animation: 0
        },
        gems: {
          value: 0,
          counter: null,
          animation: 0
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProfile: 'userProfile/fetchUserProfile'
    }),
    countTo(obj, target, duration, updateStep = 5) {
      const delta = target - obj.value
      const direction = Math.sign(delta)
      const numSteps = duration / updateStep
      const step = delta / numSteps

      const counter = function() {
        obj.value = Math.ceil(obj.value + step)
        const currDirection = Math.sign(target - obj.value)
        if (direction * currDirection <= 0) {
          obj.value = target
        } else {
          obj.counter = setTimeout(counter, updateStep)
        }
      }
      obj.counter = setTimeout(counter, updateStep)
    }
  },
  watch: {
    coins: function() {
      this.display.coins.animation++
      clearTimeout(this.display.coins.counter)
      this.countTo(this.display.coins, this.coins, 1000)
    },
    gems: function() {
      this.display.gems.animation++
      clearTimeout(this.display.gems.counter)
      this.countTo(this.display.gems, this.gems, 1000)
    }
  },
  async created() {
    await this.fetchProfile()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.scale-enter-active, .scale-leave-active {
  transition: transform 0.2s ease-in-out;
}

.scale-enter, .scale-leave-to {
  transform: scale(1.2);
}

.user-profile {
  display: none;

  &__nickname {
    color: map-get($colors-custom, 'purple-muted');
    font: {
      weight: 700;
      size: 1.6rem;
    }
  }

  &__items {
    margin-top: 5px;

    &__item {
      display: inline-block;
      padding: 0 20px 0 35px;
      border-radius: 1em;
      background: {
        color: rgba(map-get($colors-custom, 'purple-darker'), 0.95);
        position: 3px 50%;
        repeat: no-repeat;
        size: auto 24px;
      };
      color: #fff;
      font: {
        weight: 700;
        size: 1rem;
      }
      line-height: 28px;

      &:nth-child(n + 2) {
        margin-left: 10px;
      }

      &--coins {
        background-image: url('~@/assets/icon-coin.svg');
      }

      &--gems {
        background-image: url('~@/assets/icon-gem.svg');
      }
    }
  }

  @include breakpoint('medium') {
    display: block;
  }
}
</style>
