const state = {
  platform: null
}

const getters = {
  isFromUnity() {
    return state.platform === 'unity'
  }
}

const actions = {
  fetchPlatform({ commit }) {
    let platform = localStorage.getItem('pkxd_platform')
    commit('SET_PLATFORM', platform)
  }
}

const mutations = {
  SET_PLATFORM(state, platform) {
    state.platform = platform
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
