import gql from 'graphql-tag'
import { apolloClient } from '../apollo'
import config from '../../config'
import skuConfig from './sku.config'

const { environment } = config

const state = {
  loaded: false,
  skus: [],
  sku: {
    skuId: null
  },
  bundles: skuConfig.bundles,
  gems: skuConfig.gems,
  coins: skuConfig.coins
}

const querySkus = gql`query FetchSkus($environment: String!, $billingPlatformId: UUID!, $ids: [String!]!) {
  skus(environment: $environment, billingPlatformId: $billingPlatformId, ids: $ids) {
      skuId,
      price
  }
}
`

const getters = {

  getPrice: (state) => skuId => {
    if (skuId === state.sku.skuId) {
      return state.sku.price / 100
    }
    return state.skus.filter(b => b.skuId === skuId)[0].price / 100
  },

  retrieveSku: () => (skuId, type) => {
    switch (type) {
      case 'bundle':
        return skuConfig.bundles[skuId]
      case 'gems':
        return skuConfig.gems[skuId]
      case 'coins':
        return skuConfig.coins[skuId]
      case 'hidden':
        return {
          skuId: skuId
        }
      default:
        return null
    }
  }
}

const actions = new function() {
  this.fetchSkus = async ({ commit, rootState }) => {
    const gems = Object.keys(skuConfig.gems)
    const coins = Object.keys(skuConfig.coins)
    const bundles = Object.keys(skuConfig.bundles)

    const ids = gems.concat(coins).concat(bundles)

    const response = await apolloClient.query({
      query: querySkus,
      variables: {
        environment: environment,
        billingPlatformId: rootState.geo.billingPlatformId,
        ids: ids
      },
      fetchPolicy: 'network-only'
    })
    commit('SET_SKUS', response.data.skus)
  }

  this.fetchSku = async ({ commit, rootState }, skuId) => {
    const response = await apolloClient.query({
      query: querySkus,
      variables: {
        environment: environment,
        billingPlatformId: rootState.geo.billingPlatformId,
        ids: skuId
      },
      fetchPolicy: 'network-only'
    })
    commit('SET_SKU', response.data.skus[0])
  }
}()

const mutations = {
  SET_SKUS(state, skus) {
    state.skus = skus
    state.loaded = true
  },
  SET_SKU(state, sku) {
    state.sku = sku
    state.loaded = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
