const IAP_CHECKOUT_EVENT = { type: 'iap-checkout-event', signature: ['requestId', 'skuName', 'value', 'currency', 'billingPlatform', 'sequence'] }
const IAP_REQUEST_EVENT = { type: 'iap-request-event', signature: ['requestId', 'skuName', 'value', 'currency', 'billingPlatform'] }
const IAP_COMPLETED_EVENT = { type: 'iap-completed-event', signature: ['requestId', 'skuName', 'value', 'currency', 'billingPlatform', 'success', 'failureReason'] }
const CLOSE_EVENT = { type: 'close-event', signature: [] }
const COLLECT_EVENT = { type: 'collect-event', signature: ['ResponseCode', 'Sku', 'Wallet', 'Reward', 'ItemMap', 'QuantityMap', 'Pets'] }

const unityReference = window.vuplex

function dispatchToUnity(event, obj) {
  if (unityReference) {
    const message = Object.fromEntries(
      Object.entries(obj).filter(([key]) => event.signature.includes(key))
    )

    unityReference.postMessage({ type: event.type, message: JSON.stringify(message) })
  }
}

export {
  dispatchToUnity,
  IAP_CHECKOUT_EVENT,
  IAP_REQUEST_EVENT,
  IAP_COMPLETED_EVENT,
  CLOSE_EVENT,
  COLLECT_EVENT
}
