<template>
  <div class="layout-default__body layout-default__body--promo-code layout-default__body--promo-code--form" v-if="!success">
    <h1 v-t="'promoCode.title'" />
    <div>
      <PKInputText
        v-model="promoCode"
        :placeholder="'promoCode.input.placeholder'"
        :parentValidation="isInputValid"
      />
      <PKButton name="promoCode.cta" :loading="loading" :click="redeem" :disabled="!isInputValid || loading" />

      <PKModal :close="() => isError = false" v-if="isError">
        <template v-slot:body>
          <p>{{ failureMessage }}</p>
        </template>
        <template v-slot:footer>
          <PKButton name="promoCode.error.cta" :loading="loading" :click="() => isError = false" />
        </template>
      </PKModal>
    </div>
  </div>
  <div class="layout-default__body layout-default__body--promo-code layout-default__body--promo-code--success" v-else>
    <h1 v-t="'promoCode.success.title'" />
    <h2 v-t="'promoCode.success.description'" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PKButton from '../components/PKButton.vue'
import PKInputText from '../components/PKInputText.vue'
import PKModal from '../components/PKModal.vue'

export default {
  components: { PKButton, PKInputText, PKModal },
  name: 'Privacy',
  data: () => ({
    promoCode: '',
    success: false,
    failureReason: null,
    loading: false,
    isError: false
  }),
  methods: {
    ...mapActions({
      redeemPromoCode: 'promoCode/redeem'
    }),

    async redeem() {
      this.loading = true
      try {
        const result = await this.redeemPromoCode(this.promoCode)
          .finally(() => { this.loading = false })

        if (result.status === 'SUCCESS') {
          this.success = true
          return
        }

        this.failureReason = result.failureReason
        this.isError = true
      } catch (e) {
        this.failureReason = ''
        this.isError = true
      }
    }

  },
  computed: {
    ...mapState('privacy', { privacyState: store => store.privacyState }),

    isInputValid() {
      return /[a-zA-Z0-9]{10,20}/.test(this.promoCode)
    },

    failureMessage() {
      switch (this.failureReason) {
        case 'INVALID_PROMO_CODE': return this.$t('promoCode.error.invalid')
        case 'LIMIT_REACHED': return this.$t('promoCode.error.limitReached')
        case 'ALREADY_REDEEMED': return this.$t('promoCode.error.alreadyRedeemed')
        case 'EXPIRED': return this.$t('promoCode.error.expired')
      }

      return this.$t('promoCode.error.unknown')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.layout-default__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px;

  &--promo-code--form {
    background-image: url('~@/assets/images/promo-code-character.png');
    background-repeat: no-repeat;
    background-size: 150px auto;
    background-position: 30px bottom;
  }

  &--promo-code--success {
    background-image: url('~@/assets/promo-code-success-bg.svg');
    background-repeat: no-repeat;
    background-size: 500px auto;
    background-position: center center;
  }

  > h1, > h2 {
    font-weight: bold;
    font-size: 2rem;

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    text-shadow: 0px 0px 7px #B914FF;
  }

  > h2 {
    font-size: 1.4rem;
    margin-top: 24px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 32px;

    > .pk-input-text {
      margin: 0;
      min-width: 120px;
    }

    > .button {
      margin: 0;
      height: 42px;
      width: 120px;
      font-size: 1rem;
      margin-left: 18px;
    }
  }

  @include media(420px, 'min-width') {
    &--promo-code--form {
      background-size: 200px auto;
    }
  }

  @include breakpoint('large') {
    &--promo-code--form {
      background-size: 300px auto;
      background-position: 50px bottom;
    }

    > h1, > h2 {
      font-size: 2.5rem;
      white-space: nowrap;
    }

    > h2 {
      font-size: 1.8rem;
      margin-top: 32px;
    }

    > div {
      margin-top: 42px;

      > .pk-input-text {
        margin: 0;
        min-width: 500px;
      }

      > .button {
        height: 56px;
        width: 220px;
        font-size: 1.2rem;
        padding: 0 30px;
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.layout-default__body--promo-code {
  .pk-input-text > input {
    font-size: 1rem;
    height: 46px;
  }

  .modal {
    padding: 20px;
    width: 500px;
    max-width: 90vw;

    p {
      font-family: 'Play', sans-serif;
      font-size: 1.2rem;
      text-align: center;
    }

    .button {
      font-size: 1rem;
      width: auto;
      min-width: 200px;
    }
  }

  @include breakpoint('large') {
    .pk-input-text > input {
      font-size: 1.4rem;
      height: 60px;
    }
  }
}
</style>
