import Vue from 'vue'
import VueI18n from 'vue-i18n'

import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import ko from './ko'
import pt from './pt'
import ru from './ru'

import currency from './currency'

Vue.use(VueI18n)

export default new VueI18n({
  locale: navigator.language.substring(0, 2),
  fallbackLocale: 'en',
  messages: { de, en, es, fr, ko, pt, ru },
  numberFormats: { ...currency }
})
