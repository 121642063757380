import billing from '@/faster/billing'
import gql from 'graphql-tag'
import { apolloClient } from '../apollo'
import CryptoJS from 'crypto-js'
import store from '../index'

let authentication = {
  createUserSession: () => store.dispatch('authentication/createUserSession'),
  getUserSessionToken: () => store.getters['authentication/getUserSessionToken']
}

const purchaseMutation = gql`mutation (
  $transactionId: String!,
  $sku: String!,
  $signature: String!,
  $userId: UUID!
) {
  purchase (input: {
        transactionId: $transactionId,
        sku: $sku,
        signature: $signature,
        userId: $userId
      }
  ) {
    wallet {
      coins,
      gems
    },
    added {
      reward {
        coins,
        gems
      },
      itemsList,
      quantityList,
      petsToAdd
    }
  }
}
`

const state = {
  paymentProfile: null,
  paymentProfiles: [],
  purchase: null,
  purchaseWallet: null
}

let setToken = async() => {
  let userSessionToken = authentication.getUserSessionToken()
  if (!userSessionToken) {
    await authentication.createUserSession()
    userSessionToken = authentication.getUserSessionToken()
  }
  await billing.authentication.setAuthenticationToken(userSessionToken)
  return userSessionToken
}

const actions = {
  async createPaymentProfile({ commit }, { billingPlatformId, email, card, personalData }) {
    try {
      await setToken()
      const paymentProfile = await billing.integration.stripe.createPaymentProfile(billingPlatformId, email, card, personalData)
      commit('SET_PAYMENT_PROFILE', paymentProfile)
    } catch (err) {
      throw new Error(`Could not Create Payment Profile: ${err.message}`)
    }
  },

  async updatePaymentProfile({ commit }, { billingPlatformId, paymentProfileId, personalData }) {
    try {
      await setToken()
      await billing.integration.stripe.updatePaymentProfile(billingPlatformId, paymentProfileId, personalData)
    } catch (err) {
      throw new Error(`Could not Update Payment Profile: ${err.message}`)
    }
  },

  async retrievePaymentProfiles({ commit }, billingPlatformId) {
    try {
      await setToken()
      const profiles = await billing.integration.stripe.retrievePaymentProfiles(billingPlatformId)
      commit('SET_PAYMENT_PROFILES', profiles)
    } catch (err) {
      throw new Error(`Could not Retrieve Payment Profiles: ${err.message}`)
    }
  },

  async deletePaymentProfile({ commit }, { billingPlatformId, paymentProfileId }) {
    try {
      await setToken()
      await billing.integration.stripe.deletePaymentProfile(billingPlatformId, paymentProfileId)
      this.retrievePaymentProfiles(billingPlatformId)
    } catch (err) {
      throw new Error(`Could not Delete Payment Profile: ${err.message}`)
    }
  },

  async purchase({ commit }, { paymentProfileId, skuId, quantity, paymentMethodId, userId }) {
    try {
      await setToken()
      const purchase = await billing.integration.stripe.purchase(paymentProfileId, skuId, quantity, paymentMethodId, {}, skuId)
      const purchaseId = purchase.purchase.key.id

      let signature = CryptoJS.HmacSHA256(skuId, purchaseId)
      signature = CryptoJS.HmacSHA256('in-app-transaction', signature)

      var response = await apolloClient.mutate({
        mutation: purchaseMutation,
        variables: {
          transactionId: purchaseId,
          sku: skuId,
          signature: signature.toString(CryptoJS.enc.Base64),
          userId: userId
        }
      })

      commit('SET_PURCHASE_WALLET', response.data.purchase)
      commit('SET_PURCHASE', purchase)
    } catch (err) {
      throw new Error(`Could not Create Purchase: ${err.message}`)
    }
  },

  async confirm({ commit }, { purchaseId, paymentIntentId }) {
    try {
      await setToken()
      const purchase = await billing.integration.stripe.confirm(purchaseId, paymentIntentId)
      commit('SET_PURCHASE', purchase)
    } catch (err) {
      throw new Error(`Could not Confirm Purchase: ${err.message}`)
    }
  }
}

const mutations = {
  SET_PAYMENT_PROFILE(state, paymentProfile) {
    state.paymentProfile = paymentProfile
  },
  SET_PAYMENT_PROFILES(state, paymentProfiles) {
    state.paymentProfiles = paymentProfiles
  },
  SET_PURCHASE(state, purchase) {
    state.purchase = purchase
  },
  SET_PURCHASE_WALLET(state, purchaseWallet) {
    state.purchaseWallet = purchaseWallet
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
