<template>
      <li class="stacked-list__item" :class="{ 'stacked-list__item--expanded': item.isExpanded }">
        <a :href="`#issue-${index}-answer`" class="stacked-list__item__title" @click.prevent="itemToggle($event)">
          <span>{{ item.title }}</span>
        </a>

        <div class="stacked-list__item__content" :id="`issue-${index}-answer`">
          <p v-for="(item, index) in item.content" :key="index">
            <span v-if="typeof item !== 'object'" v-html="item" />
            <span v-else-if="item.type === 'warn'" v-html="item.value" class="stacked-list__item__content__warn"/>
            <a v-else-if="item.type === 'link'" v-html="item.value" class="stacked-list__item__content__link" :href="item.href"/>
            <span v-else-if="item.type === 'action'" v-html="item.value" class="stacked-list__item__content__link" @click="action"/>
            <personalized-ads-toggle v-else-if="item.type === 'ads-toggle'"/>
          </p>
        </div>
      </li>
</template>

<script>
import PersonalizedAdsToggle from '../components/PersonalizedAdsToggle.vue'

export default {
  name: 'StackedList',

  components: { PersonalizedAdsToggle },

  props: {
    item: Object,
    index: Number,
    action: Function
  },

  data () {
    return {
      searchInput: ''
    }
  },

  computed: {
    searchResult () {
      const query = this.searchInput.toLowerCase().trim()
      const items = this.items

      if (!query) {
        return items
      }

      return items.filter(item => {
        return Object.values(item)
          .filter(item => typeof item !== 'boolean')
          .flat()
          .join(' ')
          .toLowerCase()
          .includes(query)
      })
    }
  },

  methods: {
    itemToggle (event) {
      const parent = event.target.closest('.stacked-list__item')
      const expandedClass = 'stacked-list__item--expanded'

      parent.classList.toggle(expandedClass)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '../styles/core/tools';

.stacked-list {
  $parent: &;

  &__item {
    text-align: left;

    #{$parent}--cozy & {
      &:nth-child(n + 2) {
        a {
          border-top: 1px solid map-get($colors-util, 'lighter');
        }
      }

      &__title {
        padding: 30px 0;
      }
    }

    #{$parent}--cozy &--expanded & {
      &__content {
        padding-bottom: 20px;
      }
    }

    &--expanded & {
      &__title {
        &::before {
          transform: rotate(-45deg);
        }
      }

      &__content {
        display: block;
        margin-bottom: 1em;
      }
    }

    &__title {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px 0;
      cursor: pointer;
      transition: color 0.1s;
      text-decoration: none;
      color: #494262;
      font: {
        weight: 700;
        size: 1.1rem;
      }
      line-height: 140%;

      &:hover {
        &::before {
          background-color: lighten(map-get($colors-custom, 'blue-dark'), 10%);
        }
      }

      &::before {
        $size: 20px;

        content: '';
        flex: $size 0 0;
        height: $size;
        margin-right: 10px;
        border-radius: 100%;
        transition: all 0.1s;
        background: {
          color: map-get($colors-custom, 'blue-dark');
          image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIGNsYXNzPSIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTQyNi42NjcsMTcwLjY2N2gtODUuMzMzVjg1LjMzM0MzNDEuMzMzLDM4LjI3MiwzMDMuMDYxLDAsMjU2LDBzLTg1LjMzMywzOC4yNzItODUuMzMzLDg1LjMzM3Y4NS4zMzNIODUuMzMzICAgIEMzOC4yNzIsMTcwLjY2NywwLDIwOC45MzksMCwyNTZzMzguMjcyLDg1LjMzMyw4NS4zMzMsODUuMzMzaDg1LjMzM3Y4NS4zMzNDMTcwLjY2Nyw0NzMuNzI4LDIwOC45MzksNTEyLDI1Niw1MTIgICAgczg1LjMzMy0zOC4yNzIsODUuMzMzLTg1LjMzM3YtODUuMzMzaDg1LjMzM0M0NzMuNzI4LDM0MS4zMzMsNTEyLDMwMy4wNjEsNTEyLDI1NlM0NzMuNzI4LDE3MC42NjcsNDI2LjY2NywxNzAuNjY3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=);
          position: 50%;
          repeat: no-repeat;
          size: 10px;
        }
      }
    }

    &__content {
      display: none;
      padding-left: 35px;

      p {
        margin-bottom: 0.5em;
        font-size: 1.1rem;
        line-height: 140%;
        color: #494262;
      }

      ul {
        li {
          list-style: disc;
          margin: 0 0 0.5em 20px;
        }
      }

      &__link {
        text-decoration: underline;
        color: map-get($colors-custom, 'blue');
        cursor: pointer;
      }
      &__warn {
        text-decoration: none;
        color: map-get($colors-custom, 'red-light');
      }
    }
  }

  // Responsiveness
  @include media(map-get($breakpoints, 'medium')) {
    &__item {
      &__title {
        font-size: 1rem;
        line-height: 120%;
      }

      &__content {
        p {
          font-size: 0.9rem;
          line-height: 130%;
        }
      }
    }
  }
}
</style>
