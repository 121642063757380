import analytics from '@/faster/analytics'
import config from '../../config'

const { billingPlatform } = config

const defaultCountry = 'US'

const state = {
  location: null,
  billingPlatformId: null
}

const getters = {
  getCurrency() {
    if (state.location.country === 'BR') {
      return 'BRL'
    } else {
      return 'USD'
    }
  }
}

const actions = {

  async setupLocation({ commit, rootGetters }) {
    try {
      const shouldUseUnityLocation = rootGetters['platform/isFromUnity'] && localStorage.getItem('geo_location') != null
      var location = { country: defaultCountry }
      if (shouldUseUnityLocation) {
        location.country = localStorage.getItem('geo_location')
      } else {
        location = await analytics.geo.getLocation()
      }

      commit('SET_LOCATION', location)
    } catch (err) {
      const language = navigator.language.split('_')[1]
      if (language === 'BR') {
        commit('SET_LOCATION', {
          country: language
        })
      } else {
        commit('SET_LOCATION', {
          country: defaultCountry
        })
      }
      throw new Error(`Could not Retrieve Location: ${err.description}`)
    }
  },

  async retrieveLocation({ commit, rootGetters }) {
    try {
      const shouldUseUnityLocation = rootGetters['platform/isFromUnity'] && localStorage.getItem('geo_location') != null
      var location = { country: defaultCountry }
      if (shouldUseUnityLocation) {
        location.country = localStorage.getItem('geo_location')
      } else {
        location = await analytics.geo.getLocation()
      }

      commit('SET_LOCATION', location)
    } catch (err) {
      const language = navigator.language.substring(3, 5)
      if (language === 'BR') {
        commit('SET_LOCATION', {
          country: language
        })
      } else {
        commit('SET_LOCATION', {
          country: defaultCountry
        })
      }
      throw new Error(`Could not Retrieve Location: ${err.description}`)
    }
  }
}

const mutations = {
  SET_LOCATION(state, location) {
    state.location = location
    state.billingPlatformId = location.country === 'BR' ? billingPlatform.br : billingPlatform.us
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
