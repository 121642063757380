<template>
  <div class="checkout-form">
    <div class="checkout-form__header" v-show="showHeader" v-t="'checkout.form.title'" />
    <div class="checkout-form__row">
      <CheckoutInputText class="checkout-form__row__item"
        :value="fullName"
        @input="$emit('update:fullName', $event)"
        :labelName="'checkout.form.fullName'"
        :errorText="'checkout.form.errors.fullName'"
        :parentValidation="isNameValid"
      />
      <CheckoutInputText class="checkout-form__row__item"
        :value="identityNumber"
        @input="$emit('update:identityNumber', $event)"
        :labelName="'checkout.form.identityNumber'"
        :type="'tel'"
        :errorText="'checkout.form.errors.identityNumber'"
        :parentValidation="isIdentityNumberValid"
        :mask="'###.###.###-##'"
      />
    </div>
    <div class="checkout-form__row">
      <CheckoutInputText class="checkout-form__row__item checkout-form__row__item--minor"
        :value="zipCode"
        @input="$emit('update:zipCode', $event)"
        :labelName="'checkout.form.postalCode'"
        :type="'tel'"
        :errorText="'checkout.form.errors.postalCode'"
        :parentValidation="isZipCodeValid"
        :mask="'#####-###'"
      />
      <CheckoutInputText class="checkout-form__row__item"
        :value="street"
        @input="$emit('update:street', $event)"
        :labelName="'checkout.form.address'"
      />
    </div>
    <div class="checkout-form__row">
      <CheckoutInputText class="checkout-form__row__item checkout-form__row__item--minor"
        :value="addressNumber"
        @input="$emit('update:addressNumber', $event)"
        :labelName="'checkout.form.number'"
        :type="'number'"
      />
      <CheckoutInputText class="checkout-form__row__item"
        :value="complement"
        @input="$emit('update:complement', $event)"
        :labelName="'checkout.form.complement'"
        :shouldValidate="false"
      />
      <CheckoutInputText class="checkout-form__row__item"
        :value="neighborhood"
        @input="$emit('update:neighborhood', $event)"
        :labelName="'checkout.form.neighborhood'"
      />
    </div>
    <div class="checkout-form__row checkout-form__row--footer">
      <CheckoutInputText class="checkout-form__row__item"
        :value="city"
        @input="$emit('update:city', $event)"
        :labelName="'checkout.form.city'"
      />
      <div class="checkout-form__row__item">
        <div class="state-field">
          <PKInputDropdown :value="state" @input="(value) => $emit('update:state',value)" :labelName="'checkout.form.state'" :options="brazilStates"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import brazilStateList from '@/utils/brazilStateList.js'
import CheckoutInputText from '@/components/CheckoutInputText.vue'
import PKInputDropdown from '@/components/PKInputDropdown.vue'

const noNumberRegex = /^[a-zA-Z\s]+$/
const notEmptyRegex = /.*\S.*/

export default {
  name: 'checkoutFormPersonal',
  components: {
    CheckoutInputText,
    PKInputDropdown
  },
  props: {
    fullName: String,
    identityNumber: {
      Type: String,
      default: ''
    },
    zipCode: {
      Type: String,
      default: ''
    },
    street: String,
    addressNumber: String,
    complement: String,
    neighborhood: String,
    city: String,
    state: String,
    showHeader: {
      Type: Boolean,
      default: true
    }
  },
  computed: {
    brazilStates() {
      return brazilStateList
    },
    isNameValid() {
      return noNumberRegex.test(this.fullName)
    },
    isIdentityNumberValid() {
      if (this.identityNumber.length !== 11) {
        return false
      }

      let sum = 0
      for (let index = 0; index < 9; index++) {
        sum += parseInt(this.identityNumber[index]) * (10 - index)
      }
      let remainder = sum * 10 % 11
      if (parseInt(this.identityNumber[9]) !== (remainder % 10)) {
        return false
      }

      sum = 0
      for (let index = 0; index < 10; index++) {
        sum += parseInt(this.identityNumber[index]) * (11 - index)
      }
      remainder = sum * 10 % 11
      if (parseInt(this.identityNumber[10]) !== (remainder % 10)) {
        return false
      }

      return true
    },
    isZipCodeValid() {
      return this.zipCode.toString().length === 8
    },
    isStreetValid() {
      return notEmptyRegex.test(this.street)
    },
    isAddressNumberValid() {
      return notEmptyRegex.test(this.addressNumber)
    },
    isNeighborhoodValid() {
      return notEmptyRegex.test(this.neighborhood)
    },
    isCityValid() {
      return notEmptyRegex.test(this.city)
    },
    isStateValid() {
      return notEmptyRegex.test(this.state)
    },
    isFormValid() {
      return this.isNameValid &&
             this.isIdentityNumberValid &&
             this.isZipCodeValid &&
             this.isStreetValid &&
             this.isAddressNumberValid &&
             this.isNeighborhoodValid &&
             this.isCityValid &&
             this.isStateValid
    }
  }
}
</script>

<style lang="scss" scoped>
.state-field {
  width: 100px;
}
</style>
