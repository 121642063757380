import visa from 'payment-icons/min/single/visa.svg'
import mastercard from 'payment-icons/min/single/mastercard.svg'
import discover from 'payment-icons/min/single/discover.svg'
import amex from 'payment-icons/min/single/amex.svg'
import diners from 'payment-icons/min/single/diners.svg'
import jcb from 'payment-icons/min/single/jcb.svg'
import unionpay from 'payment-icons/min/single/unionpay.svg'

export default {
  visa: visa,
  mastercard: mastercard,
  discover: discover,
  amex: amex,
  diners: diners,
  jcb: jcb,
  unionpay: unionpay
}
