<template>
  <div class="privacy_modal">
    <PKModal :close="close">
      <template v-slot:header>
        <div class="privacy_modal__header">
          {{title}}
        </div>
      </template>
      <template v-slot:body>
        <div class="privacy_modal__body">
          <div class="privacy_modal__body__content">
            <div class="privacy_modal__body__content__text">
              <p v-t="'privacy.modals.captcha.text'" ></p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="privacy_modal__footer">
          <i v-if="loading" class="fa fa-spinner fa-spin button--enabled__spinner"></i>
          <template v-else>
            <vue-recaptcha
              ref="recaptcha"
              @verify="verifyCaptcha"
              @expired="captchaExpired"
              :sitekey="sitekey"
              :loadRecaptchaScript="true"
            >
              <button class="privacy_modal__footer__button privacy_modal__footer__button--confirm">{{$t('privacy.modals.captcha.label')}}</button>
            </vue-recaptcha>
          </template>
        </div>
      </template>
    </PKModal>
  </div>
</template>

<script>
import PKModal from './PKModal.vue'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
import config from '@/config'
export default {
  components: { PKModal, VueRecaptcha },
  name: 'CaptchaModal',
  data: () => ({
    sitekey: config.sitekey,
    loading: false
  }),
  props: {
    title: String,
    close: Function
  },
  methods: {
    ...mapActions({
      verifyCaptchaRequest: 'captcha/verify'
    }),

    async verifyCaptcha(token) {
      this.loading = true
      try {
        await this.verifyCaptchaRequest(token)
        this.$emit('success')
      } catch (err) {
        this.$emit('error')
      }
      this.loading = false
    },

    captchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/styles/core/settings';

  .captcha-checkbox {
    margin-right: 0.5rem;
  }

  .privacy_modal {
    &__header {
      width: 450px;
      padding-bottom: 32px;
      margin-left: 24px;
      margin-right: 24px;
      margin-top: 18px;
      font: {
        size: 1.3em;
        weight: 700
      }
    }

    &__body {
      margin-left: 24px;
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__content {
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &__text {
          font: {
            size: 1.1em;
          }
        }

        &__captcha {
            margin-top: 1rem;
            width: 50%;
            border-radius: 8px;
            border: none;
            padding: 10px 30px;
            color: #ffffff;
            background-color: map-get($colors-custom, 'blue-dark');

            &__button {
              background-color: transparent;
              border: none;
              font: {
                weight: 700;
              }
            }
        }
      }
    }

    &__footer {
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-left: 24px;
      margin-right: 24px;

      &__button {
        border-radius: 8px;
        border: none;
        padding: 10px 30px;
        font: {
          weight: 700;
        }

        &--confirm {
          color: #ffffff;
          background-color: map-get($colors-custom, 'blue-dark');
        }

        &--cancel {
          color: map-get($colors-custom, 'blue-dark');
          background-color: transparent;
        }
      }
    }
  }
</style>
