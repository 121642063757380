<template>
  <div
    class="checkout-item-container"
    :class="{'checkout-item-container--currency': !isBundle, 'checkout-item-container--bundle': isBundle}">
    <div class="checkout-item-container__header">
      <div class="checkout-item-container__header__name" :style="{'font-size': formatToRem(fontSizes.name)}" v-t="name" />
      <div class="checkout-item-container__header__price" :style="{'font-size': formatToRem(fontSizes.price)}" >
        {{ $n(price, 'currency', country) }}
      </div>
    </div>
    <div class="checkout-item-container__main">
      <slot>
      </slot>
    </div>
    <div class="checkout-item-container__footer">
    </div>
  </div>
</template>

<script>
const fontConstants = {
  widthReference: 200,
  fields: {
    name: 1,
    price: 2
  }
}

export default {
  name: 'checkoutItem',
  props: {
    name: String,
    price: Number,
    country: String,
    isBundle: Boolean
  },
  data() {
    return {
      fontSizes: {
        name: fontConstants.fields.name,
        price: fontConstants.fields.price
      }
    }
  },
  methods: {
    interpolate(targetWidth, baseValue) {
      return baseValue * targetWidth / fontConstants.widthReference
    },
    updateFontSizes() {
      let newWidth = this.$el.clientWidth
      this.fontSizes.name = this.interpolate(newWidth, fontConstants.fields.name)
      this.fontSizes.price = this.interpolate(newWidth, fontConstants.fields.price)
    },
    formatToRem(value) {
      return value + 'rem'
    }
  },
  mounted() {
    this.updateFontSizes()
    window.addEventListener('resize', this.updateFontSizes)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.checkout-item-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  min-width: 200px;
  max-width: 300px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    &__name {
      margin-bottom: 10px;
      font: {
        weight: 700;
        size: 1.6rem;
      }
    }

    &__price {
      font: {
        weight: 700;
        size: 3rem;
      }
    }
  }

  &__main {
    width: 100%;
    margin-top: 25px;
    display: none;
    flex-direction: row;
    align-items: center;
  }

  &__footer {
    color: map-get($colors-custom, 'blue-light');
    font: {
      weight: 700;
      size: 1.25rem;
    }
  }

  @include breakpoint('large') {
    align-items: flex-start;

    &__header {
      align-items: flex-start;
    }

    &__main {
      display: flex;
    }

    &--bundle {
      width: 300px;
    }
  }
}
</style>
