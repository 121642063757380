export default {
  description: '¡Bienvenido a tu mundo virtual! PK XD es pura diversión: crea tu propio avatar, construye una casa, reúnete con tus amigos y vive nuevas aventuras.',
  passwordReset: {
    titles: {
      default: 'Contraseña nueva',
      success: '¡La nueva contraseña se configuró correctamente!',
      error: 'Algo salió mal'
    },
    subtitles: {
      default: 'Ingresa una contraseña nueva para tu cuenta de PK XD.',
      success: '¡La nueva contraseña se configuró correctamente!',
      error: 'Parece que se ha producido un error. Inténtalo de nuevo.'
    },
    placeholder: '(De 6 a 15 caracteres)',
    labels: {
      newPassword: 'Contraseña nueva',
      confirmPassword: 'Confirmar contraseña'
    },
    buttons: {
      save: 'GUARDAR'
    },
    errors: {
      size: 'Ingresa una contraseña válida que tenga de 6 a 15 caracteres',
      mismatch: 'Estas contraseñas no coinciden. Inténtalo de nuevo.'
    }
  },
  passwordForgot: {
    titles: {
      default: '¿Olvidaste tu contraseña?',
      success: 'Recibimos tu solicitud',
      error: 'Algo salió mal'
    },
    subtitles: {
      default: 'Ingresa tu dirección de correo electrónico y te enviaremos instrucciones sobre cómo cambiar tu contraseña.',
      success: 'Si el correo electrónico anterior está registrado en PK XD, recibirás instrucciones sobre cómo cambiar tu contraseña por correo electrónico. Revisa tu bandeja de entrada y sigue las instrucciones.',
      error: {
        nicknameNotFound: 'No se encontró el nombre de usuario',
        emailNotFound: 'No se encontró el correo electrónico',
        noEmailAssociated: 'Esta cuenta no tiene un correo electrónico registrado',
        default: 'Parece que se ha producido un error. Inténtalo de nuevo.'
      }
    },
    buttons: {
      send: 'ENVIAR'
    },
    labels: {
      key: 'correo electrónico'
    },
    errors: {
      invalidKey: 'Ingresa una dirección de correo electrónico válida.'
    }
  },
  login: {
    username: 'Email registrado',
    password: 'Contraseña',
    email: 'Email',
    forgotPassword: 'Olvide mi contraseña',
    backToLogin: 'Volver a inicio de sesión',
    didNotGetEmail: '¿No recibiste el correo?',
    titles: {
      forgotPassword: '¿Olvidaste tu contraseña?'
    },
    subtitles: {
      enterPassword: 'Ingresa tu dirección de correo electrónico y te enviaremos instrucciones sobre cómo cambiar tu contraseña.',
      emailSent: 'Te enviamos un correo electrónico. Revísalo :)'
    },
    buttons: {
      login: 'Login',
      send: 'ENVIAR'
    }
  },
  store: {
    titleLine1: '¡Obtenga una gran',
    titleLine2: 'oferta aquí!',
    cards: {
      bonus: 'Bonus',
      gems: {
        title80: '80 Gemas',
        title210: '210 Gemas',
        title450: '450 Gemas',
        title970: '970 Gemas'
      },
      coins: {
        title5000: '5000 Monedas',
        title13000: '13000 Monedas',
        title27500: '27500 Monedas',
        title60000: '60000 Monedas'
      },
      starterBundle: {
        title: 'PAQUETE DE INICIANTE',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Habitaciones'
      },
      treeBundle: {
        title: 'PAQUETE CASA DEL ÁRBOL',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Habitaciones'
      },
      rocketHouse: {
        title: 'CASA NAVE ESPACIAL',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Habitaciones'
      },
      rocketHouseBundle: {
        title: 'PAQUETE ESPACIAL',
        subtitle: 'Compra única',
        text: 'Casa exclusiva',
        rooms: 'Habitaciones',
        offer: 'Mejor precio'
      }
    }
  },
  friendList: {
    title: 'Lista de amigos',
    noFriendsOnline: 'No hay amigos conectados',
    online: 'Conectado',
    offline: 'Desconectado'
  },
  checkout: {
    back: 'Volver',
    title: 'Pagar con tarjeta de crédito',
    subtitle: '+ Pagar con otra tarjeta',
    lastDigits: 'Últimos dígitos',
    buttons: {
      edit: 'Editar',
      delete: 'Borrar',
      pay: 'Pagar',
      close: 'CERRAR',
      save: 'GUARDAR',
      saveMyInfo: 'Guardar mi información para compras futuras'
    },
    form: {
      title: 'Datos personales',
      name: 'Nomber',
      email: 'Email',
      card: 'Datos de la tarjeta',
      fullName: 'Nombre completo',
      identityNumber: 'Número de identificación',
      postalCode: 'Código postal',
      address: 'Dirección (calle, avenida, pasaje)',
      number: 'Número',
      complement: 'Complemento',
      neighborhood: 'Barrio',
      city: 'Ciudad',
      state: 'Estado',
      errors: {
        name: 'Nombre inválido',
        email: 'Email inválido',
        card: 'Tarjeta inválido',
        fullName: 'Nombre inválido',
        identityNumber: 'Número de identificación inválido',
        postalCode: 'Código postal inválido'
      }
    },
    modal: {
      edit: {
        title: 'EDITAR TARJETA DE CRÉDITO',
        lastDigits: 'con últimos dígitos',
        credit: '(Crédito)'
      }
    },
    errors: {
      title: 'COMPRA NO REALIZADA',
      subtitle: 'Algo salió mal. Inténtelo de nuevo.',
      required: 'Obligatorio'
    },
    success: {
      title: '¡Su compra fue exitosa!',
      subtitle: '¡Vaya a la aplicación PKXD y disfrute!',
      buttons: {
        keepShopping: '¡O siga comprando!'
      }
    },
    collect: {
      title: '¡Su compra fue exitosa!',
      button: 'Recibir'
    }
  },
  general: {
    success: 'Satisfactorio',
    tryAgain: 'Inténtalo de nuevo'
  },
  navbar: {
    menu: {
      store: 'Tienda',
      editAvatar: 'Editar avatar',
      privacy: 'Centro de privacidad'
    },
    dropdown: {
      logout: 'Logout'
    },
    alert: {
      text: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada dentro de 14 días',
      cancel: 'CANCELAR'
    }
  },
  inventory: {
    buttons: {
      save: 'GUARDAR'
    }
  },
  screenshot: {
    share: 'Compartir en Facebook'
  },

  privacy: {
    info: {
      title: 'Centro de privacidad',
      text: 'La información personal es información permite identificar a una persona. En PK XD, nos tomamos muy en serio la protección de tu información personal. Respetamos todas las exigencias legales y tomamos todas las medidas técnicas y organizativas necesarias para garantizar la protección de tu información.'
    },
    ads: {
      text: 'Recibir anuncios personalizados',
      error: 'Oops, parece que algo salió mal. Vuelve a intentarlo.'
    },
    faq: {
      title: 'FAQ',
      issues: [
        {
          id: 'treat_personal_data',
          title: '¿PK XD utiliza mi información personal?',
          content: [
            'Cuando creas una cuenta en PK XD, proporcionas tu correo electrónico y nombre de usuario a nuestra base de datos. Cuando juegas, tus datos de acceso quedan almacenados y, cuando realizas una compra, tus datos de facturación también.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'get_personal_data',
          title: '¿Qué información personal sobre mí posee PK XD?',
          content: [
            'En PK XD recopilamos el correo con el que creaste tu cuenta y tus datos de acceso (IP, fecha y hora) para mejorar tu experiencia. Cuando quieras, puedes pedirnos que te enviemos un archivo con toda la información personal que almacenamos sobre ti. Lo recibirás en tu correo en un plazo de 15 días.',
            { type: 'action', value: 'Haz clic aquí para descargar tu información personal' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'att_data',
          title: '¿Cómo actualizo mi información personal?',
          content: [
            'Para cambiar tu email, haz clic en el enlace que sigue. Recibirás un correo con las instrucciones.',
            { type: 'action', value: 'Haz clic aquí para cambiar tu email.' },
            'Para actualizar tu información personal, haz clic en el enlace que sigue. Recibirás un correo con las instrucciones.',
            { type: 'link', value: 'Haz clic aquí para actualizar tu información personal.', href: 'https://pkxd.zendesk.com/hc/pt-br/requests/new?ticket_form_id=360001242211' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'privacy_policy',
          title: '¿Con quién comparten mi información personal?',
          content: [
            'Para ofrecerte un juego cada vez mejor, contamos con una red de socios, que utilizan algunos de tus datos para optimizar nuestros servidores, mejorar tu experiencia dentro del juego y mostrar anuncios seguros y relevantes. Todo esto está explicado en nuestra política de privacidad.',
            { type: 'link', value: 'política de privacidad.', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'revoke',
          title: '¿Puedo revocar mi consentimiento para el tratamiento de información personal?',
          content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'action', value: 'Haz clic aquí para revocar tu consentimiento.' }
          ],
          email_sent_content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.' },
            { type: 'action', value: 'Reenviar correo' }
          ],
          email_confirmed_content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada dentro de 14 días' },
            { type: 'action', value: 'Cancelar' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'delete',
          title: '¿Cómo elimino mi información personal de su base de datos?',
          content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'action', value: 'Clique aqui para excluir seus información personal' }
          ],
          email_sent_content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.' },
            { type: 'action', value: 'Reenviar correo' }
          ],
          email_confirmed_content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada dentro de 14 días' },
            { type: 'action', value: 'Cancelar' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'ads',
          title: 'Anuncios personalizados',
          content: [
            'Para que en PK XD podamos tener una infraestructura y un equipo dedicados a las novedades y mejoras, parte de las ganancias del juego provienen de anuncios personalizados y relevantes para cada jugador. Puedes optar por no recibir estos anuncios y recibir solo anuncios para el público en general.',
            { type: 'ads-toggle' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'terms&privacy',
          title: 'Términos de uso y política de privacidad',
          content: [
            'Nuestros términos de uso y nuestra política de privacidad están disponibles para que los consultes cuando quieras. Te informaremos si hay alguna alteración en el tratamiento de tu información.',
            { type: 'link', value: 'Términos de uso', href: 'https://policies.playpkxd.com/en/terms/2.0' },
            { type: 'link', value: 'Política de privacidad', href: 'https://policies.playpkxd.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        }
      ]
    },
    modals: {
      closeText: 'CANCELAR',
      confirmText: 'OK',
      delete: {
        title: 'Eliminar información personal',
        warning: '¿Estás seguro de que quieres eliminar tu información personal? Si lo haces, tu cuenta será eliminada y perderás todo el progreso y todas las compras que hayas hecho dentro del juego.',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.',
        buttonText: 'ELIMINAR'
      },
      revoke: {
        title: 'Revocar consentimiento',
        warning: '¿Estás seguro de que quieres revocar tu consentimiento? Si lo haces, tu cuenta será eliminada y perderás todo el progreso y todas las compras que hayas hecho dentro del juego.',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.',
        buttonText: 'REVOCAR'
      },
      request: {
        title: 'Solicitar información personal',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.'
      },
      error: {
        title: 'Oops',
        text: 'Parece que algo salió mal. Vuelve a intentarlo.'
      },
      changeEmail: {
        title: 'Solicitar cambio de correo electrónico',
        placeholder: 'Tu nuevo correo electrónico'
      },
      captcha: {
        text: 'Haga clic en el botón de abajo para asegurarse de que no es un robot:',
        label: 'No soy un robot'
      }
    },
    confirmationViews: {
      delete: {
        title: 'Eliminación de datos solicitada',
        text: 'Tu información personal será eliminada en 14 días. Cuando eso suceda, tu cuenta será eliminada de forma permanente. Haz clic aquí para revertirlo app.playpkxd.com'
      },
      request: {
        title: 'Dados solicitados',
        text: 'Solicitaste tu información personal, dentro de los próximos 14 días te enviaremos un correo con tus datos. Si tienes alguna duda, haz clic aquí app.playpkxd.com'
      },
      changeEmail: {
        title: 'Cambio de correo electrónico realizado',
        text: 'Su cambio de correo electrónico se ha realizado con éxito.',
        placeholder: 'Tu nuevo correo electrónico'
      },
      error: {
        title: 'Oops, parece que su solicitud ha caducado',
        text: 'No pudimos completar la operación porque su solicitud ha caducado, puede solicitar la operación nuevamente a través del centro de privacidad'
      },
      loading: {
        title: 'Solicitud de procesamiento',
        text: 'Estamos procesando su solicitud, por favor espere'
      }
    }
  }
}
