import config from '../../config'

const { stripePublicKey } = config

const styles = {
  style: {
    base: {
      color: '#7623df',
      fontSize: '24px',
      fontWeight: '700',
      '::placeholder': {
        color: '#bb95f0'
      }
    },
    invalid: {
      color: '#ff0081'
    }
  }
}

const getDefaultValidation = () => {
  return {
    cardNumber: {
      error: false,
      completed: false
    },
    cardExpiry: {
      error: false,
      completed: false
    },
    cardCvc: {
      error: false,
      completed: false
    },
    postalCode: {
      error: false,
      completed: false
    }
  }
}

const cardValidation = getDefaultValidation()

const state = {
  stripe: {
    instance: null,
    elements: null,
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    postalCode: null
  },
  cardValidation: cardValidation,
  isCardInvalid: false,
  isCardCompleted: false
}

const actions = {

  startStripe({ commit }, location) {
    const stripeScript = document.createElement('SCRIPT')
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3')
    document.body.appendChild(stripeScript)
    stripeScript.onload = () => {
      var stripe = {}
      if (location.country === 'BR') {
        state.cardValidation.postalCode.completed = true
        stripe.instance = Stripe(stripePublicKey.br)
      } else {
        stripe.instance = Stripe(stripePublicKey.us)
      }

      commit('SET_STRIPE', stripe)
    }
  },

  startStripeAndMount({ commit }, location) {
    const stripeScript = document.createElement('SCRIPT')
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3')
    document.body.appendChild(stripeScript)
    stripeScript.onload = () => {
      var stripe = {}
      if (location.country === 'BR') {
        state.cardValidation.postalCode.completed = true
        stripe.instance = Stripe(stripePublicKey.br)
      } else {
        stripe.instance = Stripe(stripePublicKey.us)
      }
      stripe.elements = stripe.instance.elements()

      stripe.cardNumber = stripe.elements.create('cardNumber', styles)
      stripe.cardNumber.mount('#card-number')
      stripe.cardNumber.addEventListener('change', ({ error, complete }) => {
        cardValidation.cardNumber.error = !!error
        cardValidation.cardNumber.completed = complete
        commit('SET_VALIDATION', cardValidation)
      })

      stripe.cardExpiry = stripe.elements.create('cardExpiry', styles)
      stripe.cardExpiry.mount('#card-expiry')
      stripe.cardExpiry.addEventListener('change', ({ error, complete }) => {
        cardValidation.cardExpiry.error = !!error
        cardValidation.cardExpiry.completed = complete
        commit('SET_VALIDATION', cardValidation)
      })

      stripe.cardCvc = stripe.elements.create('cardCvc', styles)
      stripe.cardCvc.mount('#card-cvc')
      stripe.cardCvc.addEventListener('change', ({ error, complete }) => {
        cardValidation.cardCvc.error = !!error
        cardValidation.cardCvc.completed = complete
        commit('SET_VALIDATION', cardValidation)
      })

      if (location.country !== 'BR') {
        stripe.postalCode = stripe.elements.create('postalCode', styles)
        stripe.postalCode.mount('#card-zipcode')
        stripe.postalCode.addEventListener('change', ({ error, complete }) => {
          cardValidation.postalCode.error = !!error
          cardValidation.postalCode.completed = complete
          commit('SET_VALIDATION', cardValidation)
        })
      }
      commit('SET_STRIPE', stripe)
    }
  },
  resetValidation({ commit }) {
    Object.assign(cardValidation, getDefaultValidation())
    commit('SET_VALIDATION', cardValidation)
  }
}

const mutations = {
  SET_STRIPE(state, stripe) {
    state.stripe = stripe
  },
  SET_VALIDATION(state, cardValidation) {
    const isCardCompleted = state.cardValidation.cardNumber.completed &&
      state.cardValidation.cardExpiry.completed &&
      state.cardValidation.cardCvc.completed &&
      state.cardValidation.postalCode.completed

    const isCardInvalid = cardValidation.cardNumber.error || cardValidation.cardExpiry.error || cardValidation.cardCvc.error
    state.cardValidation = cardValidation
    state.isCardInvalid = isCardInvalid
    state.isCardCompleted = isCardCompleted
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
