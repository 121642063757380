export default {
  description: 'Willkommen in deiner virtuellen Welt! Bei PK XD dreht sich alles um Spaß: Erstelle deinen eigenen Avatar, triff Freunde und unternimm neue Abenteuer.',
  passwordReset: {
    titles: {
      default: 'Neues Passwort',
      success: 'Das neue Passwort wurde erfolgreich eingerichtet!',
      error: 'Scheinbar ist ein Fehler aufgetreten'
    },
    subtitles: {
      default: 'Bitte gib ein neues Passwort für dein PK XD-Konto ein.',
      success: 'Das neue Passwort wurde erfolgreich eingerichtet!',
      error: `Scheinbar ist ein Fehler aufgetreten. Bitte versuche es noch einmal.`
    },
    placeholder: '(6 bis 15 Zeichen)',
    labels: {
      newPassword: 'Neues Passwort',
      confirmPassword: 'Passwort bestätigen'
    },
    buttons: {
      save: 'SPEICHERN'
    },
    errors: {
      size: 'Saisis un mot de passe comprenant entre 6 et 15 caractères.',
      mismatch: 'Die Passwörter stimmen nicht überein. Bitte versuche es noch einmal.'
    }
  },
  passwordForgot: {
    titles: {
      default: 'Du hast dein Passwort vergessen?',
      success: 'Wir haben deine Anfrage erhalten.',
      error: 'Scheinbar ist ein Fehler aufgetreten'
    },
    subtitles: {
      default: `Gib deine E-Mail-Adresse ein und wir senden dir Anweisungen, um dein Passwort zu ändern.`,
      success: 'Wenn deine E-Mail-Adresse bei PK XD registriert ist, schicken wir dir per E-Mail Anweisungen, wie du dein Passwort ändern kannst. Bitte überprüfe deinen Posteingang und folge den Anweisungen.',
      error: {
        nicknameNotFound: 'Benutzername nicht gefunden',
        emailNotFound: 'E-Mail nicht gefunden',
        noEmailAssociated: 'Für dieses Konto gibt es keine registrierte E-Mail-Adresse',
        default: 'Scheinbar ist ein Fehler aufgetreten. Bitte versuche es noch einmal.'
      }
    },
    buttons: {
      send: 'SENDEN'
    },
    labels: {
      key: 'E-Mail'
    },
    errors: {
      invalidKey: 'Bitte gib eine gültige E-Mail-Adresse ein.'
    }
  },
  login: {
    username: `Registrierte Email`,
    password: 'Passwort',
    email: 'E-Mail',
    forgotPassword: 'Passwort vergessen',
    backToLogin: 'Zurück zur Anmeldung',
    didNotGetEmail: 'Du hast die E-Mail nicht erhalten?',
    titles: {
      forgotPassword: 'Du hast dein Passwort vergessen?'
    },
    subtitles: {
      enterPassword: `Gib deine E-Mail-Adresse ein und wir senden dir Anweisungen, um dein Passwort zu ändern.`,
      emailSent: 'Wir haben dir eine E-Mail geschickt. Bitte sieh gleich nach. :)'
    },
    buttons: {
      login: 'Anmelden',
      send: 'SENDEN'
    }
  },
  store: {
    titleLine1: 'Nutze das',
    titleLine2: 'tolle Angebot!',
    cards: {
      bonus: 'Bonus',
      gems: {
        title80: '80 Edelsteine',
        title210: '210 Edelsteine',
        title450: '450 Edelsteine',
        title970: '970 Edelsteine'
      },
      coins: {
        title5000: '5000 Münzen',
        title13000: '13000 Münzen',
        title27500: '27500 Münzen',
        title60000: '60000 Münzen'
      },
      starterBundle: {
        title: 'STARTERPAKET',
        subtitle: 'Einmaliger Kauf',
        text: 'Exklusives Haus',
        rooms: 'Räume'
      },
      treeBundle: {
        title: 'BAUMHAUS-PAKET',
        subtitle: 'Einmaliger Kauf',
        text: 'Exklusives Haus',
        rooms: 'Räume'
      },
      rocketHouse: {
        title: 'RAUMSCHIFFHAUS',
        subtitle: 'Einmaliger Kauf',
        text: 'Exklusives Haus',
        rooms: 'Räume'
      },
      rocketHouseBundle: {
        title: 'WELTRAUMPAKET',
        subtitle: 'Einmaliger Kauf',
        text: 'Exklusives Haus',
        rooms: 'Räume',
        offer: 'Bester Preis'
      }
    }
  },
  friendList: {
    title: `Freundesliste`,
    noFriendsOnline: 'Keine Freunde online',
    online: 'Online',
    offline: 'Offline'
  },
  checkout: {
    back: 'Zurück',
    title: 'Mit Kreditkarte bezahlen',
    subtitle: '+ Mit einer anderen Karte bezahlen',
    lastDigits: 'Letzte Ziffern',
    buttons: {
      edit: 'Bearbeiten',
      delete: 'Löschen',
      pay: 'Zahlen',
      close: 'SCHLIESSEN',
      save: 'SPEICHERN',
      saveMyInfo: 'Meine Informationen für zukünftige Einkäufe speichern.'
    },
    form: {
      title: 'Persönliche Informationen',
      name: 'Name',
      email: 'E-Mail',
      card: 'Kartendaten',
      fullName: 'Vollständiger Name',
      identityNumber: `Identifikationsnummer`,
      postalCode: 'Postleitzahl',
      address: 'Adresse (Straße, Avenue, Gasse)',
      number: 'Hausnummer',
      complement: `Zusatz`,
      neighborhood: 'Nachbarschaft',
      city: 'Stadt',
      state: 'Bundesland',
      errors: {
        name: 'Ungültiger Name',
        email: 'Ungültiger E-Mail',
        card: 'Ungültige Karte',
        fullName: 'Ungültiger Name',
        identityNumber: `Ungültige Identifikationsnummer`,
        postalCode: 'Ungültige Postleitzahl'
      }
    },
    modal: {
      edit: {
        title: 'KREDITKARTE BEARBEITEN',
        lastDigits: 'mit den letzten Ziffern',
        credit: '(Kredit-)'
      }
    },
    errors: {
      title: 'KAUF NICHT ERFOLGT',
      subtitle: 'Etwas ist schiefgelaufen. Versuche es erneut.',
      required: 'Erforderlich'
    },
    success: {
      title: 'Dein Kauf war erfolgreich!',
      subtitle: `Gehe jetzt zur App von PKXD, viel Spaß!`,
      buttons: {
        keepShopping: 'Oder kaufe weiter ein!'
      }
    },
    collect: {
      title: 'Dein Kauf war erfolgreich!',
      button: 'Einsammeln'
    }
  },
  general: {
    success: 'Erfolgreich',
    tryAgain: `Bitte versuche es noch einmal.`
  },
  navbar: {
    menu: {
      store: 'Store',
      editAvatar: `Avatar bearbeiten`
    },
    dropdown: {
      logout: 'Abmelden'
    }
  },
  inventory: {
    buttons: {
      save: 'SPEICHERN'
    }
  },
  screenshot: {
    share: 'Auf Facebook teilen'
  }
}
