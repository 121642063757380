<template>
  <div class="layout-default" :class="{'layout-default--hide': isFromUnity}">
    <Navbar />
    <NavbarAlert v-if="privacyState === 'CONFIRMED'"/>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import NavbarAlert from '@/components/NavbarAlert.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    NavbarAlert
  },
  computed: {
    ...mapGetters({ 'isFromUnity': 'platform/isFromUnity' }),
    ...mapState('privacy', { privacyState: store => store.privacyState }),
    ...mapState('authentication', { authenticated: store => store.authenticated })
  }
}
</script>
