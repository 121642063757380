<template>
  <div class="checkout-container">
    <div class="checkout-container__header">
      <div
        class="checkout-container__header__return"
        :class="{'checkout-container__header__return--hide': isFromUnity && !showForm}"
        @click="back()"
      >
        <IconArrowNext class="checkout-container__header__return__arrow" /> {{ $t('checkout.back') }}
      </div>
    </div>
    <div v-if="showContent">
      <CheckoutForm :country="location.country" v-if="paymentProfiles.length === 0 || showForm"/>
      <CheckoutSelectCard @open-form="showForm = true" :location="location" v-else/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CheckoutForm from '@/components/CheckoutForm.vue'
import CheckoutSelectCard from '@/components/CheckoutSelectCard.vue'

export default {
  name: 'checkoutContainer',
  props: {
    location: Object
  },
  components: {
    CheckoutForm,
    CheckoutSelectCard
  },
  data() {
    return {
      showContent: false,
      showForm: false
    }
  },
  computed: {
    ...mapGetters({
      isFromUnity: 'platform/isFromUnity'
    }),
    ...mapState({
      paymentProfiles: store => store.purchase.paymentProfiles,
      billingPlatformId: store => store.geo.billingPlatformId
    })
  },
  methods: {
    ...mapActions({
      retrievePaymentProfiles: 'purchase/retrievePaymentProfiles',
      startStripe: 'stripe/startStripe'
    }),
    back() {
      if (this.showForm && this.paymentProfiles.length !== 0) {
        this.showForm = false
      } else {
        this.$router.go(-1)
      }
    }
  },
  async beforeMount() {
    try {
      await this.retrievePaymentProfiles(this.billingPlatformId)
    } catch {}
    this.showContent = true
    if (this.paymentProfiles.length > 0) {
      this.startStripe(this.location)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.checkout-container {
  padding: 30px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;

  &__header {
    &__return {
      &--hide {
        visibility: hidden;
      }

      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: map-get($colors-custom, 'blue-light');
      font: {
        weight: 700;
        size: 1.25rem;
      }

      &:hover {
        cursor: pointer;
      }

      &__arrow {
        display: inline-block;
        transform: scale(0.5) rotate(180deg);
      }
    }
  }
}
</style>
