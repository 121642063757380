<template>
  <BaseCard class="store-card">
    <div class="clickable-overlay" @click="$emit('click', $event, index)" v-show="isClickable"/>
    <div class="store-card__content">
      <div class="store-card__content__main" :class="{'store-card__content__main--coin': type === 'coin'}">
        <div
          class="store-card__content__main__quantity"
          :class="{'store-card__content__main__quantity--coin': type === 'coin'}"
          :style="{'font-size': formatToRem(fontSizes.quantity)}">
          {{ quantity }}
        </div>
        <div class="store-card__content__main__box">
          <img :src="getImage" class="store-card__content__main__box__icon">
        </div>
      </div>
      <div class="store-card__content__bonus" :style="{'font-size': formatToRem(fontSizes.bonus)}" v-show="bonus > 0">
        {{ bonus }}% {{ $t('store.cards.bonus') }}
      </div>
    </div>
    <div class="store-card__price" :style="{'font-size': formatToRem(fontSizes.price)}" >
      {{ $n(getPrice(skuId), 'currency', location.country) }}
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseCard from '@/components/card/BaseCard.vue'

const fontConstants = {
  widthReference: 195,
  fields: {
    quantity: 2.3,
    bonus: 1.25,
    price: 2
  }
}

export default {
  name: 'StoreCardCurrency',
  components: {
    BaseCard
  },
  props: {
    name: String,
    component: String,
    type: String,
    quantity: Number,
    bonus: Number,
    skuId: String,
    isClickable: {
      Type: Boolean,
      default: false
    },
    image: {
      Type: String,
      default: 'gem-stacked.svg'
    }
  },
  data() {
    return {
      price: 0,
      fontSizes: {
        quantity: fontConstants.fields.quantity,
        bonus: fontConstants.fields.bonus,
        price: fontConstants.fields.price
      }
    }
  },
  methods: {
    interpolate(targetWidth, baseValue) {
      return baseValue * targetWidth / fontConstants.widthReference
    },
    updateFontSizes() {
      let newWidth = this.$el.clientWidth
      this.fontSizes.quantity = this.interpolate(newWidth, fontConstants.fields.quantity)
      this.fontSizes.bonus = this.interpolate(newWidth, fontConstants.fields.bonus)
      this.fontSizes.price = this.interpolate(newWidth, fontConstants.fields.price)
    },
    formatToRem(value) {
      return value + 'rem'
    }
  },
  computed: {
    ...mapGetters({ getPrice: 'sku/getPrice' }),
    ...mapState({ location: store => store.geo.location }),
    getImage() {
      return require('@/assets/store/' + this.image)
    }
  },
  mounted() {
    this.updateFontSizes()
    window.addEventListener('resize', this.updateFontSizes)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.store-card {
  width: 100%;
  padding: 3%;
  padding-bottom: 0;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0 6px 0 map-get($colors-custom, 'purple-lightest');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;

  &__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    &__main {
      width: 100%;
      border-radius: 14px;
      background: linear-gradient(
        -15deg,
        map-get($colors-custom, 'purple-dark'),
        map-get($colors-custom, 'purple-dark') 32.5%,
        map-get($colors-custom, 'purple') 32.5%,
        map-get($colors-custom, 'purple') calc(32.5% + 28%),
        map-get($colors-custom, 'purple-dark') calc(32.5% + 28%),
      );
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--coin {
        background: linear-gradient(
          -15deg,
          map-get($colors-custom, 'blue-dark'),
          map-get($colors-custom, 'blue-dark') 32.5%,
          map-get($colors-custom, 'blue') 32.5%,
          map-get($colors-custom, 'blue') calc(32.5% + 28%),
          map-get($colors-custom, 'blue-dark') calc(32.5% + 28%),
        );
      }

      &__quantity {
        padding-top: 4.5%;
        padding-bottom: 5%;
        color: map-get($colors-custom, 'green');
        font: {
          weight: 700;
          size: 2.3rem;
        }

        &--coin {
          color: map-get($colors-custom, 'yellow');
        }
      }

      &__box {
        width: 100%;
        margin-bottom: 20%;
        position: relative;
        display: flex;

        &__icon {
          margin: 0 auto;
          position: relative;
          width: 57%;
          z-index: 3;
        }
      }
    }

    &__bonus {
      padding: 4.5%;
      border-radius: 7px;
      background: map-get($colors-theme, 'danger');
      position: absolute;
      bottom: -5%;
      color: #fff;
      font: {
        weight: 900;
        size: 1.25rem;
      }
      text-transform: uppercase;
      text-shadow: 0 2px 0 map-get($colors-custom, 'purple-darkest');
      z-index: 3;
    }
  }

  &__price {
    padding-top: 5%;
    padding-bottom: 5%;
    color: map-get($colors-custom, 'purple-muted');
    font: {
      weight: 700;
      size: 2rem;
    }
    z-index: 2;
  }
}
</style>
