<template>
  <div class="store">
    <app-store class="store__logo" />
    <google-play class="store__logo" />
  </div>
</template>

<script>
import AppStore from '@/components/store/AppStore'
import GooglePlay from '@/components/store/GooglePlay'

export default {
  name: 'store-buttons',
  components: {
    AppStore,
    GooglePlay
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.store {
  display: flex;
  margin: 30px auto 0 auto;
  max-width: 300px;

  &__logo {
    margin-right: 10px;
  }
}
</style>
