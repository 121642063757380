import { IdentityProvider, EmailCredentialType } from 'faster-account-sdk-js'
import account from '@/faster/account'
import { apolloClient } from '../apollo'

const state = {
  authenticated: false,
  userSession: null,
  email: null
}

const getters = {
  getUserSessionToken() {
    let userSession = state.userSession
    if (userSession == null || userSession.expiresAt < Date.now()) {
      return null
    }

    return userSession.token
  },
  getEmail() {
    let userSession = state.userSession
    if (userSession == null || userSession.expiresAt < Date.now()) {
      return null
    }
    return state.email
  }
}

const actions = new function() {
  this.createUserSession = async ({ commit }, email) => {
    try {
      const userSession = await account.userSession.create()
      commit('SET_USER_SESSION', { ...userSession, email })
    } catch (err) {
      commit('CLEAR_USER_SESSION')
    }

    return state.userSession
  }

  this.signIn = async (context, { email, password }) => {
    try {
      await account.authSession.create(IdentityProvider.FASTER, EmailCredentialType.PASSWORD, email, password)
      return await this.createUserSession(context, email)
    } catch (err) {
      throw new Error(err.message)
    }
  }

  this.signOut = async ({ commit }) => {
    try {
      await account.auth.signOut()
      await apolloClient.cache.reset()
      commit('CLEAR_USER_SESSION')
    } catch (err) {
      throw new Error(`Could not Sign Out: ${err.message}`)
    }
  }
}()

const mutations = {
  CLEAR_USER_SESSION(state) {
    state.userSession = null
    state.authenticated = false
    state.email = null
  },
  SET_USER_SESSION(state, { userId, userToken, expiresAt, email }) {
    state.userSession = { userId: userId, token: userToken, expiresAt }
    state.authenticated = true
    state.email = email
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
