<template>
  <div class="login">
    <div class="content">
      <boxGeneric class="box-form--login content-position">
        <template v-slot:default>
          <logo />
          <div class="box-form--login__content">
            <transition name="flip">
              <div class="box-form--login__content-transition" v-if="isLogin" key="a">
                <form @submit.prevent="signin">
                  <input
                    id="username"
                    :placeholder="$t('login.username')"
                    type="email"
                    v-model="login.username"
                    class="form__input form__input--login"
                    :class="{'form__input--error': !!message.error}" />
                  <input
                    id="password"
                    :placeholder="$t('login.password')"
                    type="password"
                    v-model="login.password"
                    class="form__input form__input--login"
                    autocomplete="on"
                    :class="{'form__input--error': !!message.error}" />
                  <div>
                    <p class="form__validation-message form__validation-message--error">{{ message.error }}</p>
                    <a href="#" @click.prevent="changeToForgotPasswordFlow" class="link forgot-password-button" v-t="'login.forgotPassword'"/>
                  </div>
                  <pkbutton :name="'login.buttons.login'" :loading="loading" :disabled="isLoginDisabled"/>
                </form>
              </div>
              <div class="box-form--login__content-transition" v-else key="b">
                <div>
                  <h1 class="box-form__title" align="center" v-t="'login.titles.forgotPassword'" />
                  <p class="box-form__subtitle" :class="{'box-form__subtitle--success': forgotPassword.isSuccess}" v-t="forgotPassword.subtitle" />
                  <transition name="fade" mode="out-in">
                    <div v-if="!forgotPassword.isSuccess" key="2">
                      <form @submit.prevent="sendEmail">
                        <input
                          id="email"
                          :placeholder="$t('login.email')"
                          type="text"
                          v-model="forgotPassword.key"
                          class="form__input form__input--login"
                          :class="{'form__input--error': !!message.error}" />
                        <p class="form__validation-message form__validation-message--error">{{ message.error }}</p>
                        <pkbutton :name="'login.buttons.send'" :disabled="isSendEmailDisabled" />
                        <a href="#" @click.prevent="backToLogin" class="link link__login" v-t="'login.backToLogin'" />
                      </form>
                    </div>
                    <div v-else key="1">
                      <success-stamp class="stamp__icon--small"/>
                      <div class="try-again try-again__block">
                        <p class="try-again try-again__label">{{ $t('login.didNotGetEmail') }}</p>
                        <a href="#" @click.prevent="tryAgain" class="link" v-t="'general.tryAgain'" />
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </boxGeneric>
      <store-buttons class="content-position" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BoxGeneric from '@/components/BoxGeneric'
import Logo from '@/components/Logo'
import Pkbutton from '@/components/PKButton'
import StoreButtons from '@/components/store/StoreButtons'
import SuccessStamp from '@/components/SuccessStamp'
import { HOME_ROUTE } from '@/router/router-types'
import eventEmitter from '@/faster/events/emitter'

const messages = {
  enterPassword: 'login.subtitles.enterPassword',
  emailSent: 'login.subtitles.emailSent'
}

const errors = {
  'NICKNAME_NOT_FOUND': 'passwordForgot.subtitles.error.nicknameNotFound',
  'EMAIL_NOT_FOUND': 'passwordForgot.subtitles.error.emailNotFound',
  'NO_EMAIL_ASSOCIATED': 'passwordForgot.subtitles.error.noEmailAssociated',
  default: 'passwordForgot.subtitles.error.default'
}

export default {
  name: 'login',
  components: {
    BoxGeneric,
    Logo,
    Pkbutton,
    StoreButtons,
    SuccessStamp
  },
  data: () => ({
    isLogin: true,
    loading: false,
    login: {
      username: '',
      password: ''
    },
    forgotPassword: {
      key: '',
      isSuccess: false,
      subtitle: messages.enterPassword
    },
    message: {
      error: ''
    }
  }),
  methods: {
    ...mapActions({
      signIn: 'authentication/signIn',
      resetPassword: 'password/forgot'
    }),
    changeToForgotPasswordFlow() {
      this.isLogin = false
      this.message.error = ''
    },
    tryAgain() {
      this.forgotPassword.isSuccess = !this.forgotPassword.isSuccess
      this.forgotPassword.subtitle = messages.enterPassword
    },
    backToLogin() {
      this.isLogin = true
      this.message.error = ''
    },
    async sendEmail() {
      try {
        await this.resetPassword({ key: this.forgotPassword.key.toLowerCase() })
        this.forgotPassword.isSuccess = !this.forgotPassword.isSuccess
        this.forgotPassword.subtitle = messages.emailSent
      } catch (err) {
        this.message.error = this.$t(errors[err.message] || errors['default'])
      }
    },
    async signin() {
      try {
        this.loading = true
        await this.signIn({
          email: this.login.username.toLowerCase(),
          password: this.login.password
        })
        eventEmitter.signIn()
        this.$router.push(HOME_ROUTE)
      } catch (err) {
        this.loading = false
        this.message.error = err.message
        eventEmitter.signIn(false, err.message)
      }
    }
  },
  computed: {
    ...mapState('authentication', { authenticated: store => store.authenticated }),
    isSendEmailDisabled: function() {
      return !this.forgotPassword.key
    },
    isLoginDisabled: function() {
      return !this.login.username || !this.login.password
    }
  }
}
</script>

<style lang="scss">
@import '../styles/core/settings';
@import '../styles/core/tools';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: {
    image: url("~@/assets/bg-login.jpg");
    size: cover;
  }

  > .content {
    margin: 0 32px;
    max-width: 400px;

    .box-form {
      &--login {
        &__content {
          position: relative;
          min-height: 260px;
        }

        &__content-transition {
          position: absolute;
          width: 100%;
        }
      }

      &__subtitle {
        height: 40px;
        margin-top: 10px;
      }
    }
  }

  .stamp {
    &__icon {
      margin-top: 0;
    }
  }

  .button {
    margin-top: $gap / 2;
  }

  .link {
    &__login {
      margin-top: $gap * 2;
      text-align: center;
      display: block;
    }
  }

  .forgot-password-button {
    float: right;
    margin-bottom: 30px;
    text-align: end;
  }

  .try-again {
    font-weight: bold;
    text-align: center;

    &__block {
      margin-top: 70px;
    }

    &__label {
      color: #fff;
    }
  }

  .logo {
    height: 100px;
    margin-bottom: 24px;
  }

  // Transitions
  .fade-enter-active {
    transition: opacity 1.2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .flip-enter-active {
    transition: transform .6s;
    transform-style: preserve-3d;
  }

  .flip-leave-active {
    transition: transform .6s;
  }

  .flip-leave-to {
    opacity: 0;
  }

  .flip-enter, .flip-leave-to {
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  // Responsiveness
  @include media(map-get($breakpoints, 'medium'), 'max-height') {
    .box-form {
      &--login {
        margin-top: 80px;
      }
    }

    .logo {
      height: 80px;
      margin-bottom: 20px;
    }
  }

  @include media(map-get($breakpoints, 'small'), 'max-height') {
    .box-form {
      &--login {
        margin-top: 30px;
        padding-bottom: 30px;
        border: 3px solid map-get($colors-custom, 'blue-light');
      }
    }

    .logo {
      display: none;
    }
  }

  @include breakpoint('small') {
    .box-form {
      padding: 60px 30px 60px 30px;
    }

    .logo {
      height: 130px;
      margin-bottom: 50px;
    }
  }

  @include breakpoint('extra-large') {
    justify-content: flex-end;

    .content {
      margin-right: 15vw;
    }
  }
}
</style>
