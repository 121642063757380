<template>
  <button
    :disabled="disabled"
    :class="[ disabled ? 'button--disabled' : 'button--enabled', pinkVariation ? 'button--enabled--pink' : '' ]"
    class="button">
      <div class="clickable-overlay" @click="click" v-if="!disabled"/>
      <i v-if="loading" class="fa fa-spinner fa-spin button--enabled__spinner"></i>
      <p v-else v-t="name" />
  </button>
</template>

<script>
export default {
  name: 'checkoutButton',
  props: {
    name: String,
    disabled: Boolean,
    loading: Boolean,
    click: {
      type: Function,
      default: function () {}
    },
    pinkVariation: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.button {
  outline: 0;
  mix-blend-mode: normal;
  width: 100%;
  max-width: 470px;
  height: 70px;
  position: relative;

  display: block;
  margin: auto;

  border-radius: 16px;
  font: {
    family: $font-regular;
    weight: bold;
    size: 1.75rem;
  }
  text: {
    align: center;
  }

  &--enabled {
    background: map-get($colors-custom, 'purple-dark');
    border: 3.7px solid map-get($colors-custom, 'blue-light');
    box-shadow: 0 6px 0 map-get($colors-custom, 'blue-light');
    color: map-get($colors-custom, 'blue-light');

    &--pink {
      border: 3.7px solid map-get($colors-custom, 'pink');
      box-shadow: 0 6px 0 map-get($colors-custom, 'pink');
      color: map-get($colors-custom, 'pink');
    }

    &:hover {
      background-color: map-get($colors-custom, 'purple-darker');
    }

    &__spinner {
      font-size: 2rem;
    }
  }

  &--disabled {
    background: map-get($colors-util, 'light');
    border: 5px solid map-get($colors-custom, 'purple-lighter');
    color: map-get($colors-custom, 'purple-lighter');
    cursor: not-allowed;
  }
}
</style>
