<template>
  <div class="edit-card">
    <PKModal :close="close">
      <template v-slot:header>
        <div class="edit-card__header" v-t="'checkout.modal.edit.title'" />
      </template>
      <template v-slot:body>
        <div class="edit-card__body">
          <div class="edit-card__body__card">
            <div class="edit-card__body__card__item">
              <img :src="cardBrandIcons[card.cardBrand]" class="edit-card__body__card__item__icon">
              {{ $t('checkout.modal.edit.credit') }} {{ normalize(card.cardBrand) }} {{ $t('checkout.modal.edit.lastDigits') }} {{ card.cardSuffix }}
            </div>
          </div>
          <CheckoutFormPersonal
            class="edit-card__body__form"
            v-bind.sync="personalDetails"
            :showHeader="false"
            ref="personalDataForm"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="edit-card__footer">
          <div class="edit-card__footer__button">
            <CheckoutButton :name="'checkout.buttons.save'" :loading="loading" :disabled="!isFormValid" :click="updateCard"/>
          </div>
          <div class="edit-card__footer__button edit-card__footer__button--close">
            <CheckoutButton :name="'checkout.buttons.close'" :disabled="false" :click="close" :pinkVariation="true" />
          </div>
        </div>
      </template>
    </PKModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PKModal from '@/components/PKModal.vue'
import CheckoutFormPersonal from '@/components/CheckoutFormPersonal.vue'
import CheckoutButton from '@/components/CheckoutButton.vue'
import cardBrandIconList from '@/utils/cardBrandIconList.js'

export default {
  name: 'checkoutModalEditCard',
  components: {
    PKModal,
    CheckoutFormPersonal,
    CheckoutButton
  },
  props: {
    card: Object,
    billingPlatformId: String,
    close: Function
  },
  data() {
    return {
      loading: false,
      isFormValid: false,
      personalDetails: null,
      cardBrandIcons: cardBrandIconList
    }
  },
  methods: {
    ...mapActions({ updatePaymentProfile: 'purchase/updatePaymentProfile' }),
    validateForm() {
      this.isFormValid = this.$refs.personalDataForm.isFormValid
    },
    normalize: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    async updateCard() {
      this.loading = true
      await this.updatePaymentProfile({
        billingPlatformId: this.billingPlatformId,
        paymentProfileId: this.card.key.id,
        personalData: {
          name: this.personalDetails.fullName,
          identityNumber: this.personalDetails.identityNumber,
          identityType: this.card.personalData.identityType,
          address: {
            street: this.personalDetails.street,
            number: this.personalDetails.addressNumber,
            complement: this.personalDetails.complement,
            neighborhood: this.personalDetails.neighborhood,
            city: this.personalDetails.city,
            state: this.personalDetails.state,
            zipCode: this.personalDetails.zipCode
          }
        }
      })
      this.loading = false
      this.close()
    },
    updateCardPersonalDetails() {
      this.personalDetails = {
        fullName: this.card.personalData.name,
        identityNumber: this.card.personalData.identityNumber,
        zipCode: this.card.personalData.address.zipCode,
        street: this.card.personalData.address.street,
        addressNumber: this.card.personalData.address.number,
        complement: this.card.personalData.address.complement,
        neighborhood: this.card.personalData.address.neighborhood,
        city: this.card.personalData.address.city,
        state: this.card.personalData.address.state
      }
    }
  },
  mounted() {
    this.updateCardPersonalDetails()
    this.$watch('card', (newValue, oldValue) => this.updateCardPersonalDetails())
    this.$watch(
      '$refs.personalDataForm.isFormValid',
      (newValue, oldValue) => {
        this.validateForm()
      }
    )
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.edit-card {
  &__header {
    width: 800px;
    padding-bottom: 16px;
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 32px;
    border-bottom: 2px solid map-get($colors-custom, 'purple');
    color: map-get($colors-custom, 'purple');
    font: {
      size: 1.75em;
      weight: 700
    }
    text-transform: uppercase;
  }

  &__body {
    width: 800px;
    margin-left: 48px;
    margin-right: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__card {
      margin-top: 24px;
      color: map-get($colors-custom, 'purple');
      font: {
        size: 1.5em;
        weight: 700
      }
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &__item {
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__icon {
          margin-right: 10px;
          height: 37.7px;
          width: 60px;
          display: inline-block;
        }
      }
    }

    &__form {
      margin-top: 52px;
    }
  }

  &__footer {
    margin-top: 40px;
    margin-bottom: 30px;
    margin-right: 24px;
    display: flex;
    flex-direction: row-reverse;

    &__button {
      width: 320px;
      margin-left: 16px;

      &--close {
        width: 240px;
      }
    }
  }
}
</style>
