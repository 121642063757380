export default class HttpClient {
  constructor(basePath) {
    this._basePath = basePath
    this._headerTransformers = []
  }

  addTransformer(transformer) {
    this._headerTransformers.push(transformer)
  }

  async get(path, includeCredentials) {
    let options = {
      method: 'GET',
      headers: await this._createHeaders()
    }

    if (includeCredentials) {
      options.credentials = 'include'
    }

    return fetch(this._basePath + path, options)
  }

  async post(path, body, includeCredentials) {
    let options = {
      method: 'POST',
      headers: await this._createHeaders(),
      body: JSON.stringify(body)
    }

    if (includeCredentials) {
      options.credentials = 'include'
    }

    return fetch(this._basePath + path, options)
  }

  async patch(path, body, includeCredentials) {
    let options = {
      method: 'PATCH',
      headers: await this._createHeaders(),
      body: JSON.stringify(body)
    }

    if (includeCredentials) {
      options.credentials = 'include'
    }

    return fetch(this._basePath + path, options)
  }

  async delete(path, includeCredentials) {
    let options = {
      method: 'DELETE',
      headers: await this._createHeaders()
    }

    if (includeCredentials) {
      options.credentials = 'include'
    }

    return fetch(this._basePath + path, options)
  }

  async _createHeaders() {
    let headers = new Headers({
      'Content-Type': 'application/json'
    })

    for (let transformer of this._headerTransformers) {
      headers = await transformer.transform(headers)
    }

    return headers
  }
}
