import { IdentityProvider } from 'faster-account-sdk-js'
import account from '@/faster/account'

const actions = {
  async linkEmail(context, { email, password }) {
    try {
      await account.credentials.linkEmail(IdentityProvider.FASTER, email, password)
    } catch (err) {
      throw new Error(`Could not Link Email: ${err.description}`)
    }
  }
}

export default {
  namespaced: true,
  actions
}
