<template>
  <div class="checkout-failed">
      <PKModal :close="close">
        <template v-slot:header>
          <div class="checkout-failed__header" v-t="'checkout.errors.title'" />
        </template>
        <template v-slot:body>
          <div class="checkout-failed__body">
            <div class="checkout-failed__body__content">
              <div class="checkout-failed__body__content__text" v-t="'checkout.errors.subtitle'" />
            </div>
            <div class="checkout-failed__body__button">
              <CheckoutButton :name="'checkout.buttons.close'" :disabled="false" :click="close"/>
            </div>
          </div>
        </template>
    </PKModal>
  </div>
</template>

<script>
import PKModal from '@/components/PKModal.vue'
import CheckoutButton from '@/components/CheckoutButton.vue'

export default {
  name: 'checkoutModalFailed',
  components: {
    PKModal,
    CheckoutButton
  },
  props: {
    close: Function
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.checkout-failed {
  &__header {
    width: 785px;
    padding-bottom: 16px;
    margin-left: 48px;
    margin-right: 48px;
    margin-top: 32px;
    border-bottom: 2px solid map-get($colors-custom, 'purple');
    color: map-get($colors-custom, 'purple');
    font: {
      size: 1.75em;
      weight: 700
    }
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__content {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__text {
        color: map-get($colors-custom, 'purple');
        font: {
          size: 1.5em;
          weight: 700
        }
      }
    }

    &__button {
      width: 320px;
      margin-bottom: 100px;
    }
  }
}
</style>
