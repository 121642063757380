import client from '@/store/modules/clients/unauthenticatedPrivacyHttpClient'

const actions = new function() {
  this.confirmDelete = async (context, token) => {
    const response = await client.post('/v1/privacy/delete/confirm', { token: token, application: 'PKXD' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.statusCode)
  }

  this.confirmDataRequest = async (context, token) => {
    const response = await client.post('/v1/privacy/data/confirm', { token: token, application: 'PKXD' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.statusCode)
  }

  this.confirmChangeEmail = async (context, token) => {
    const response = await client.post('/v1/privacy/data/email-change/confirm', { actionToken: token, application: 'PKXD' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.statusCode)
  }
}()

export default {
  namespaced: true,
  actions
}
