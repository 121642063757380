<template>
  <div v-if="!!location && loaded">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseCard',
  computed: {
    ...mapState({
      location: store => store.geo.location,
      loaded: store => store.sku.loaded
    })
  }
}
</script>
