<template>
  <button
    @click="click"
    :disabled="disabled"
    :class="[ disabled ? 'button--disabled' : 'button--enabled' ]"
    class="button">
      <slot></slot>
      <i v-if="loading" class="fa fa-spinner fa-spin button--enabled__spinner"></i>
      <p v-else v-t="name" />
  </button>
</template>

<script>
export default {
  name: 'pkbutton',
  props: {
    name: String,
    disabled: Boolean,
    loading: Boolean,
    click: {
      type: Function,
      default: function () {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.button {
  outline: 0;
  mix-blend-mode: normal;
  width: 100%;
  height: 48px;

  display: block;
  margin: auto;
  margin-top: 24px;

  line-height: 1.2;
  border-radius: 8px;
  font: {
    style: normal;
    weight: bold;
    size: 1rem;
  }
  text: {
    align: center;
    transform: uppercase;
  }

  &--enabled {
    background: map-get($colors-custom, 'purple-dark');
    border: 2px solid map-get($colors-custom, 'blue-light');
    box-shadow: 0 6px 0 map-get($colors-custom, 'blue-light');
    color: map-get($colors-custom, 'blue-light');

    &:hover {
      background-color: map-get($colors-custom, 'purple-darker');
    }

    &__spinner {
      font-size: 2rem;
    }
  }

  &--disabled {
    background:  map-get($colors-custom, 'purple-light');
    border: 2px solid map-get($colors-custom, 'purple-lighter');;
    box-shadow: 0 6px 0 map-get($colors-custom, 'purple-lighter');;
    color: #fff;
  }

  @include breakpoint('small') {
    height: 60px;
    margin-top: 30px;
    font-size: 1.2rem;
  }
}
</style>
