<template>
  <BaseCard class="rocker-house">
    <div class="clickable-overlay" @click="$emit('click', $event, index)" v-show="isClickable"/>
    <div class="rocker-house__label">
      <div class="rocker-house__label__highlight"></div>
      <div class="rocker-house__label__notch">
        <div class="rocker-house__label__notch__shadow"></div>
      </div>
      <p class="rocker-house__label__text--main" :style="{'font-size': formatToRem(fontSizes.label.title)}">{{ $t('store.cards.rocketHouseBundle.title') }}</p>
      <p class="rocker-house__label__text--subtitle" :style="{'font-size': formatToRem(fontSizes.label.subtitle)}">{{ $t('store.cards.rocketHouseBundle.subtitle') }}</p>
    </div>
    <div class="rocker-house__flag">
      <img src="@/assets/store/rocket-flag.svg" class="rocker-house__flag__icon">
      <img src="@/assets/store/icon-hot.svg" class="rocker-house__flag__icon-hot">
      <p class="rocker-house__flag__text">{{ $t('store.cards.rocketHouseBundle.offer') }}</p>
    </div>
    <div class="rocker-house__gravity">
      <img src="@/assets/store/antigravity-icon.svg" class="rocker-house__gravity__icon">
    </div>
    <div class="rocker-house__content">
      <div class="rocker-house__content__details">
        <p class="rocker-house__content__details__title" :style="{'font-size': formatToRem(fontSizes.house.text)}">
          {{ $t('store.cards.rocketHouseBundle.text') }}
        </p>
        <div class="rocker-house__content__details__rooms" :style="{'font-size': formatToRem(fontSizes.house.rooms)}">
          {{ $t('store.cards.rocketHouseBundle.rooms') }}: {{ quantity.rooms }}
        </div>
      </div>
      <img src="@/assets/store/rocket-house-space.svg" class="rocker-house__content__house__icon">
    </div>
    <div class="rocker-house__price" :style="{'font-size': formatToRem(fontSizes.price)}" >
      {{ $n(getPrice(skuId), 'currency', location.country) }}
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseCard from '@/components/card/BaseCard.vue'

const fontConstants = {
  widthReference: 395,
  fields: {
    label: {
      title: 1.25,
      subtitle: 1
    },
    coin: 1.25,
    gem: 1.25,
    house: {
      text: 0.8,
      rooms: 0.6
    },
    price: 2.5
  }
}

export default {
  name: 'StoreCardBundleRocketHouse',
  components: {
    BaseCard
  },
  props: {
    skuId: String,
    name: String,
    component: String,
    price: Object,
    quantity: {
      coins: Number,
      gems: Number,
      rooms: Number
    },
    isClickable: {
      Type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fontSizes: {
        label: {
          title: fontConstants.fields.label.title,
          subtitle: fontConstants.fields.label.subtitle
        },
        coin: fontConstants.fields.coin,
        gem: fontConstants.fields.gem,
        house: {
          text: fontConstants.fields.house.text,
          rooms: fontConstants.fields.house.rooms
        },
        price: fontConstants.fields.price
      }
    }
  },
  methods: {
    formatToRem(value) {
      return value + 'rem'
    }
  },
  computed: {
    ...mapGetters({ getPrice: 'sku/getPrice' }),
    ...mapState({ location: store => store.geo.location })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.rocker-house {
  width: 100%;
  padding: 1.8%;
  border-radius: 20px;
  border: 4px solid map-get($colors-custom, 'blue-lightest');
  position: relative;
  background: linear-gradient(
    -11deg,
    map-get($colors-custom, 'blue-light'),
    map-get($colors-custom, 'blue-light') 25%,
    map-get($colors-custom, 'blue-lightest') 25%,
    map-get($colors-custom, 'blue-lightest') calc(25% + 21.5%),
    map-get($colors-custom, 'blue-light') calc(25% + 21.5%),
    map-get($colors-custom, 'blue-light') calc(25% + 21.5% + 7.15%),
    map-get($colors-custom, 'blue-lightest') calc(25% + 21.5% + 7.15%),
    map-get($colors-custom, 'blue-lightest') calc(25% + 21.5% + 7.15% + 7.15%),
    map-get($colors-custom, 'blue-light') calc(25% + 21.5% + 7.15% + 7.15%)
  );
  box-shadow: 0 7px 0 map-get($colors-custom, 'blue');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__gravity {
    position: absolute;
    z-index: 3;
    top: 45%;
    left: 5%;

    &__icon {
      width: 30%;
    }
  }

  &__flag {
    padding-left: 4%;
    position: absolute;
    top: 32%;
    left: -8.3%;
    z-index: 3;

    &__text {
      position: absolute;
      top: 20%;
      left: 12%;
      color: #FFFF00;
      font-weight: bold;
      font-size: 0.8rem;
    }

    &__icon {
      width: 40%;
    }

    &__icon-hot {
      width: 4%;
      position: absolute;
      top: 17%;
      left: 7%;
    }
  }

  &__label {
    padding: 2.5% 2.5% 2.5% 4%;
    border-radius: 8px 6px 6px 0;
    position: absolute;
    top: 7%;
    left: -6.3%;
    background: linear-gradient(
      -64deg,
      map-get($colors-custom, 'yellow-dark'),
      map-get($colors-custom, 'yellow-dark') 20.5%,
      map-get($colors-custom, 'yellow') 20.5%,
      map-get($colors-custom, 'yellow') calc(20.5% + 15%),
      map-get($colors-custom, 'yellow-dark') calc(20.5% + 15%)
    );
    z-index: 3;

    &__highlight {
      width: 2.7%;
      height: calc(100% - 18.5%);
      position: absolute;
      top: 9.25%;
      left: 2%;
      border-radius: 6.5px;
      background: {
        color: map-get($colors-custom, 'yellow');
      }
    }

    &__notch {
      width: 9%;
      height: 14%;
      border-radius: 0 0 0 8px;
      overflow: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      background: {
        color: map-get($colors-custom, 'yellow-dark');
      }

      &__shadow {
        width: 100%;
        height: 75%;
        margin-left: 15%;
        border-radius: 6.5px;
        background: {
          color: map-get($colors-custom, 'yellow-darker');
        }
      }
    }

    &__text{
      &--main {
        padding: 2% 0 1% 0;
        color: map-get($colors-custom, 'purple-dark');
        font: {
          weight: 900;
          size: 1.25em;
        }
        white-space: nowrap;
        text-transform: uppercase;
      }

      &--subtitle {
        padding-bottom: 2%;
        padding-left: 2px;
        color: #000;
        font: {
          weight: 700;
          size: 1rem;
        }
      }
    }
  }

  &__content {
    width: 100%;
    border-radius: 10px;
    position: relative;
    background: {
      color: map-get($colors-custom, 'purple-darkest' );
    };
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__details {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 10px;
      text-align: end;

      &__title {
        text-transform: lowercase;
        font-weight: bold;
        text-shadow: 0 2px 2px map-get($colors-custom, 'purple-darkest');
        color: map-get($colors-custom, 'blue-light');
      }

      &__rooms {
        width: 30%;
        padding: 2.1% 0 2.8% 0;
        margin-left: 70%;
        margin-top: 2%;
        border-radius: 60px;
        background: {
          color: map-get($colors-custom, 'blue-light')
        };
        color: #000;
        font: {
          weight: 700;
          size: 1rem;
        }
        text-align: center;
      }
    }

    &__house {
      margin-left: 4.5%;

      &__icon {
        width: 100%;
        border-radius: 10px;
      }

      &__text {
        color: map-get($colors-custom, 'blue-light');
        font: {
          weight: 700;
          size: 1rem;
        }
        text-transform: uppercase;
      }

      &__rooms {
        width: 100%;
        padding: 2.1% 0 2.8% 0;
        margin-top: 3.5%;
        border-radius: 60px;
        background: {
          color: map-get($colors-custom, 'blue-light')
        };
        color: #000;
        font: {
          weight: 700;
          size: 1rem;
        }
        text-align: center;
      }
    }

    &__currency {
      width: 34.2%;
      margin-left: 4.5%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__coin {
        min-width: 14.2%;
        padding-top: 21.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;

        &__icon {
          width: 45%;
          position: absolute;
          transform: translateY(calc(-50% + 10%)) scale(0.88);
        }

        &__quantity {
          margin-top: 62%;
          color: map-get($colors-custom, 'yellow');
          font: {
            weight: 700;
            size: 1.25rem;
          }
        }
      }

      &__gem {
        width: 48%;
        padding-top: 11.2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;

        &__icon {
          width: 100%;
          transform: scale(0.88);
        }

        &__quantity {
          margin-top: 0;
          color: map-get($colors-custom, 'green');
          font: {
            weight: 700;
            size: 1.25rem;
          }
        }
      }
    }
  }

  &__price {
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    color: map-get($colors-custom, 'purple-dark');
    font: {
      weight: 700;
      size: 2.5rem;
    }
  }
}
</style>
