export default {
  description: '당신의 가상 세계에 오신 것을 환영합니다! PK XD는 재미로 가득합니다. 나만의 아바타를 만들고, 집을 짓고, 친구를 만나고, 새로운 모험을 떠나보세요.',
  passwordReset: {
    titles: {
      default: '새로운 비밀번호',
      success: '새로운 비밀 번호가 성공적으로 설정되었습니다!',
      error: '뭔가 잘못되었습니다.'
    },
    subtitles: {
      default: '귀하의 PK XD 계정에 새로운 비밀번호를 입력하시기 바랍니다.',
      success: '새로운 비밀 번호가 성공적으로 설정되었습니다!',
      error: `일부 오류가 발생한 것 같습니다. 다시 시도해 주시기 바랍니다.`
    },
    placeholder: '(6 -15 캐릭터)',
    labels: {
      newPassword: '새로운 비밀번호',
      confirmPassword: '비밀번호 확인'
    },
    buttons: {
      save: '저장'
    },
    errors: {
      size: '6-15 캐릭터의 유효한 비밀번호를 입력하기 바랍니다.',
      mismatch: '이 비밀번호는 일치하지 않습니다. 다시 시도해 주세요.'
    }
  },
  passwordForgot: {
    titles: {
      default: '비밀번호를 잊어버리셨나요?',
      success: '요청이 수신되었습니다.',
      error: '뭔가 잘못되었습니다.'
    },
    subtitles: {
      default: `귀하의 이메일을 입력하시면 귀하의 비밀번호를 변경할 수 있는 안내를 전송해드릴 것입니다.`,
      success: '만일 이전에 이메일이 PK XD에 등록되었다면, 이메일을 통하여 비밀 번호를 변경할 수 있는 안내를 받게될 것입니다. 수신함을 확인하시고 안내를 따라주십시오.',
      error: {
        nicknameNotFound: '사용자 이름을 찾을 수 없습니다',
        emailNotFound: '이메일을 찾을 수 없습니다',
        noEmailAssociated: '이 계정은 등록된 이메일이 없습니다',
        default: '일부 오류가 발생한 것 같습니다. 다시 시도해 주시기 바랍니다.'
      }
    },
    buttons: {
      send: '전송'
    },
    labels: {
      key: '이메일'
    },
    errors: {
      invalidKey: '유효한 이메일을 입력하십시오.'
    }
  },
  login: {
    username: `등록 된 이메일`,
    password: '비밀번호',
    email: '이메일',
    forgotPassword: '비밀번호를 잊어 버렸습니다.',
    backToLogin: '다시 로그인',
    didNotGetEmail: '이메일을 받지 못하셨나요?',
    titles: {
      forgotPassword: '비밀번호를 잊어버리셨나요?'
    },
    subtitles: {
      enterPassword: `귀하의 이메일을 입력하시면 귀하의 비밀번호를 변경할 수 있는 안내를 전송해드릴 것입니다.`,
      emailSent: '이메일을 보냈습니다. 확인해 보세요 :)'
    },
    buttons: {
      login: '로그인',
      send: '전송'
    }
  },
  store: {
    titleLine1: '여기서',
    titleLine2: '혜택을 받으세요!',
    cards: {
      bonus: '보너스',
      gems: {
        title80: '80 보석',
        title210: '210 보석',
        title450: '450 보석',
        title970: '970 보석'
      },
      coins: {
        title5000: '5000 코인',
        title13000: '13000 코인',
        title27500: '27500 코인',
        title60000: '60000 코인'
      },
      starterBundle: {
        title: '초보자 번들',
        subtitle: '단일 구매',
        text: '고급 하우스',
        rooms: '방'
      },
      treeBundle: {
        title: '세개의 하우스 번들',
        subtitle: '단일 구매',
        text: '고급 하우스',
        rooms: '방'
      },
      rocketHouse: {
        title: '로켓 하우스',
        subtitle: '단일 구매',
        text: '고급 하우스',
        rooms: '방'
      },
      rocketHouseBundle: {
        title: '스페이스 번들',
        subtitle: '단일 구매',
        text: '고급 하우스',
        rooms: '방',
        offer: '최적의 가격'
      }
    }
  },
  friendList: {
    title: `친구 목록`,
    noFriendsOnline: '접속한 친구가 없습니다',
    online: '온라인',
    offline: '오프라인'
  },
  checkout: {
    back: '뒤로',
    title: '신용카드로 결제',
    subtitle: '+ 다른 카드로 결제',
    lastDigits: '마지막 숫자',
    buttons: {
      edit: '수정',
      delete: '삭제',
      pay: '결제',
      close: '닫기',
      save: '저장',
      saveMyInfo: '차후 구매를 위해 정보 저장'
    },
    form: {
      title: '개인 정보',
      name: '이름',
      email: '이메일',
      card: '카드 정보',
      fullName: '성명',
      identityNumber: `신원 번호`,
      postalCode: '우편 번호',
      address: '상세 주소(구, 동, 도로명)',
      number: '번호',
      complement: `보완`,
      neighborhood: '지역',
      city: '도시',
      state: '주, 도',
      errors: {
        name: '잘못된 이름',
        email: '잘못된 이메일',
        card: '잘못된 카드',
        fullName: '잘못된 이름',
        identityNumber: `잘못된 신원 번호`,
        postalCode: '잘못된 우편 번호'
      }
    },
    modal: {
      edit: {
        title: '신용카드 수정',
        lastDigits: '마지막 숫자가',
        credit: '(신용카드)'
      }
    },
    errors: {
      title: '구매 진행 안 됨',
      subtitle: '문제가 생겼습니다. 다시 시도해 보세요.',
      required: '필수'
    },
    success: {
      title: '구매에 성공했습니다!',
      subtitle: `이제 PKXD 앱에서 즐기세요!`,
      buttons: {
        keepShopping: '또는 계속 쇼핑하세요!'
      }
    },
    collect: {
      title: '구매에 성공했습니다!',
      button: '받기'
    }
  },
  general: {
    success: '성공',
    tryAgain: `다시 시도해 주세요.`
  },
  navbar: {
    menu: {
      store: '상점',
      editAvatar: `아바타 수정`
    },
    dropdown: {
      logout: '로그아웃'
    }
  },
  inventory: {
    buttons: {
      save: '저장'
    }
  },
  screenshot: {
    share: 'Facebook에 공유'
  }
}
