import Vue from 'vue'
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import store from './index'
import config from '@/config'

const cache = new InMemoryCache({
  addTypename: false
})

let authentication = {
  createUserSession: () => store.dispatch('authentication/createUserSession'),
  getUserSessionToken: () => store.getters['authentication/getUserSessionToken']
}

let token = async() => {
  let userSessionToken = authentication.getUserSessionToken()
  if (!userSessionToken) {
    await authentication.createUserSession()
    userSessionToken = authentication.getUserSessionToken()
  }
  return userSessionToken ? `Bearer ${userSessionToken}` : ''
}

const apolloClient = new ApolloClient({
  request: async (operation) => {
    operation.setContext({
      headers: {
        Authorization: await token()
      }
    })
  },
  credentials: 'include',
  uri: `${config.apiHost}/graphql`,
  cache: cache
})

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export {
  apolloClient,
  apolloProvider
}
