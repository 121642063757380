<template>
  <div class="pk-input-dropdown">
    <label class="form__label form__label--checkout" v-t="labelName"/>
    <vSelect
      class="v-select"
      :value="value"
      :options="options"
      :searchable="true"
      :clearable="false"
      :placeholder="'--'"
      @input="value => $emit('input', value)">
      <template v-slot:no-options>
        ----
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect
  },
  props: {
    value: String,
    labelName: String,
    options: Array
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

$vs-border-width: 2px;
$vs-border-radius: 8px;
$vs-border-color: map-get($colors-custom, 'purple-lightest');

@import "vue-select/src/scss/vue-select.scss";

.pk-input-dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .v-select {
    width: 100%;
    border-color: map-get($colors-custom, 'purple-lightest');

    &:focus-within {
      border-color: map-get($colors-custom, 'purple');
    }

    .vs__dropdown-toggle {
      @extend %input-text;
      margin-bottom: 0;
      border-color: inherit;

      .vs__selected-options {
        height: 30px;

        .vs__selected {
          position: relative;
          margin: 0;
          padding: 0;
          border: 0;
          color: map-get($colors-custom, 'purple');
          -webkit-text-fill-color: map-get($colors-custom, 'purple');
          font: {
            size: 1.5rem;
          }
          line-height: 30px;
        }

        input {
          border: none;
          margin: 0;
          padding: 0;
          color: map-get($colors-custom, 'purple');
          -webkit-text-fill-color: map-get($colors-custom, 'purple');
          font: {
            family: $font-regular;
            weight: 700;
            size: 1.5rem;
          }
          line-height: 30px;
        }
      }

      .vs__actions {
        padding: 0;
        width: 24px;

        .vs__open-indicator {
          fill: map-get($colors-custom, 'purple');
        }
      }
    }

    .vs__dropdown-menu {
      margin-top: -5px;
      width: 100%;
      min-width: 0;
      max-height: 100px;
      border-color: inherit;
      color: map-get($colors-custom, 'purple');
      -webkit-text-fill-color: map-get($colors-custom, 'purple');
      font: {
        family: $font-regular;
        weight: 700;
        size: 1rem;
      }

      .vs__dropdown-option--highlight {
        background: map-get($colors-util, 'light');
      }
    }
  }
}
</style>
