import client from '@/store/modules/clients/privacyHttpClient'

const actions = new function() {
  this.verify = async (context, token) => {
    const response = await client.post('/v1/privacy/captcha/validate', { application: 'PKXD', token: token })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.statusCode)
  }
}()

export default {
  namespaced: true,
  actions
}
